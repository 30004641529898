import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiTableContainer: {
  defaultProps: ComponentsProps['MuiTableContainer']
  styleOverrides: ComponentsOverrides<Theme>['MuiTableContainer']
} = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 8,
      border: '1px solid',
      borderColor: theme.palette.divider,
    }),
  },
}

export default MuiTableContainer
