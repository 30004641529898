import { IOrganisation } from '@models/organisation.model'
import { IProject } from '@models/projects.model'
import { IOrgUser, IProjectUser } from '@models/user.model'
import { TOrganisationRole, TProjectRole, IUserRoleMapping } from '@models/userRole.model'

export const getPermittedOrgs = (organisations: IOrganisation[], userRoleMappingOrganisation?: IUserRoleMapping['organisationRoles']) => {
  if (!organisations || !userRoleMappingOrganisation) return []

  return organisations.filter(org => {
    return userRoleMappingOrganisation[org.id] === 'member'
  })
}

export const getPermittedProjects = (
  projects: IProject[],
  orgOfProject?: IOrganisation,
  userRoleMappingOrganisation?: IUserRoleMapping['organisationRoles'],
  userRoleMappingProject?: IUserRoleMapping['projectRoles'],
) => {
  if (!projects || !userRoleMappingProject || !userRoleMappingOrganisation || !orgOfProject) return []

  const isMemberOrg = userRoleMappingOrganisation[orgOfProject.id] === 'member'

  const isMemberOfProject = projects.filter(proj => {
    return userRoleMappingProject[proj.id] === 'member'
  })

  return isMemberOfProject || isMemberOrg
}

export const searchOrgUserAlgorithm = (item: IOrgUser, searchTerm: string) =>
  `${item.firstName || ''} ${item.lastName || ''}`.trim().toLowerCase().includes(searchTerm.toLowerCase())

export const searchProjectUserAlgorithm = (item: IProjectUser, searchTerm: string) =>
  `${item.firstName || ''} ${item.lastName || ''}`.trim().toLowerCase().includes(searchTerm.toLowerCase())

export const searchProjectAlgorithm = (item: IProject, searchTerm: string) =>
  `${item.objective || ''}`.toLowerCase().includes(searchTerm.toLowerCase())

export const checkAssignRolePermission = (orgRole: TOrganisationRole, projectRole: TProjectRole | null): string => {
  if (!orgRole) return ''

  const projectRoleOrder: TProjectRole[] = ['member', 'collector']

  const organisationRolesOrder: TOrganisationRole[] = ['member', 'collector']

  const orgHighestRole = organisationRolesOrder[0]
  const projectHighestRole = projectRoleOrder[0]

  if (orgRole === orgHighestRole) {
    return 'HIGHEST'
  }

  if (!!projectRole && projectHighestRole === projectRole) {
    return 'DELETE'
  }

  return 'ASSIGN_HIGHER'
}

export const handleCheckAbleToSeeOrgData = (selectedProject: IProject | undefined, userRoleMappings: IUserRoleMapping | null) => {
  if (!userRoleMappings?.organisationRoles || !selectedProject) return false

  const orgId = selectedProject.organisation_id
  const isMyselfInOrg = userRoleMappings.organisationRoles[orgId] === 'member'

  return isMyselfInOrg
}
