import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from '@mui/material'
import { forwardRef } from 'react'

export type SkeletonProps = {} & MuiSkeletonProps

const Skeleton = forwardRef(({ variant = 'rounded', ...rest }: SkeletonProps) => {
  return <MuiSkeleton variant={variant} {...rest} />
})

export default Skeleton
