import { IBaseResponse } from '@models/common.model'
import { Profile, UpdateProfilePayload } from '@models/profile.models'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'

export class ProfileService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  getProfile = async ({ userId }: { userId: string }): Promise<IBaseResponse<Profile>> => {
    try {
      const response = await this.client.from('profiles').select('*').eq('id', userId).single()

      if (response.error) {
        return { data: null, error: response?.error }
      }

      const profile = this.toCamelCase(response.data)
      return {
        data: profile,
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }
  saveProfile = async (request: UpdateProfilePayload): Promise<IBaseResponse<Profile>> => {
    try {
      const payload = {
        id: request.id,
        first_name: request?.firstName,
        last_name: request?.lastName,
        terms_agreed_at: request?.termsAgreedAt,
      }
      const response = await this.client.from('profiles').update(payload).eq('id', request.id).select('*').single()

      if (response.error) {
        return { data: null, error: response?.error }
      }

      const profile = this.toCamelCase(response.data)
      return {
        data: profile,
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

const profileService = new ProfileService()

export default profileService
