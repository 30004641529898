import { forwardRef, useMemo } from 'react'

import { ButtonGroup, Typography, VStack } from '@components/atom'
import Icons from '@components/atom/Icons'
import { useTheme } from '@mui/material'
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined'
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined'

import { ActionButtonStyled } from './LabelingController.styles'
import { TControllerAction } from './LabelingController.types'

export type LabelingControllerProps = {
  onSelectAction?: (action: TControllerAction) => void
}

const LabelingController = forwardRef(({ onSelectAction }: LabelingControllerProps, _) => {
  const { palette } = useTheme()

  const actions: TControllerAction[] = useMemo(
    () => [
      {
        type: 'zoom_in',
        hotkey: '',
        icon: <Icons Icon={() => <ZoomInOutlinedIcon sx={{ width: 24, height: 24 }} />} color={palette.grey[700]} />,
      },
      {
        type: 'zoom_out',
        hotkey: '',
        icon: <Icons Icon={() => <ZoomOutOutlinedIcon sx={{ width: 24, height: 24 }} />} color={palette.grey[700]} />,
      },
    ],
    [],
  )

  return (
    <ButtonGroup variant="outlined" aria-label="labeling-toolbar">
      {actions.map(action => (
        <ActionButtonStyled key={action.type} size="small" onClick={() => onSelectAction?.(action)}>
          <VStack>
            {action.icon}
            {action.hotkey && <Typography>{action.hotkey}</Typography>}
          </VStack>
        </ActionButtonStyled>
      ))}
    </ButtonGroup>
  )
})

export default LabelingController
