import MuiTableCell, { TableCellProps as MuiTableCellProps, tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import React, { memo } from 'react'

export type TableCellProps = MuiTableCellProps & {
  children?: React.ReactNode
}

const StyledReportTableCell = styled(MuiTableCell)(({ theme }) => ({
  borderColor: theme.palette.teal?.[100],
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.teal?.[100],
    color: theme.palette.teal?.[900],
    padding: theme.spacing(1),
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
    padding: theme.spacing(1),
  },
}))

const StyledTableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: '8px 16px',
  [`&.${tableCellClasses.body}`]: {
    borderBottom: 'none',
    backgroundColor: theme.palette.common.white,
  },
}))

export const TableCell = memo(({ children, ...rest }: TableCellProps) => {
  return <StyledTableCell {...rest}>{children}</StyledTableCell>
})

export const ReportTableCell = memo(({ children, ...rest }: TableCellProps) => {
  return <StyledReportTableCell {...rest}>{children}</StyledReportTableCell>
})

export default TableCell
