import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiInputLabel: {
  defaultProps: ComponentsProps['MuiInputLabel']
  styleOverrides: ComponentsOverrides<Theme>['MuiInputLabel']
} = {
  defaultProps: {},
  styleOverrides: {
    root: () => ({}),
    focused: ({ theme }) => ({
      color: theme.palette.info.main + '!important',
    }),
  },
}

export default MuiInputLabel
