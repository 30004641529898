import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiCard: {
  defaultProps: ComponentsProps['MuiCard']
  styleOverrides: ComponentsOverrides<Theme>['MuiCard']
} = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: ({ theme, variant }) => {
      if (variant === 'outlined') {
        return {
          borderColor: theme.palette.divider,
          borderRadius: theme.spacing(1),
        }
      }
    },
  },
}

export default MuiCard
