import { IconXClose } from '@icons/index'
import { IconButton, InputAdornment, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material'
import { forwardRef } from 'react'
import Box from '../Box'
import { container, xContainer } from './TextAreaField.styles'

export type TextAreaFieldProps = {
  children?: React.ReactNode
  value?: string
  onClear?: () => void
} & MuiTextFieldProps

export const TextAreaField = ({ children, rows = 4, value = '', onClear, ...rest }: TextAreaFieldProps, ref: React.Ref<any>) => {
  return (
    <Box sx={container}>
      <MuiTextField multiline rows={rows} value={value} {...rest} ref={ref}>
        {children}
      </MuiTextField>
      {onClear && (
        <InputAdornment sx={xContainer} position="end">
          <IconButton onClick={onClear} edge="end" disableRipple>
            <IconXClose />
          </IconButton>
        </InputAdornment>
      )}
    </Box>
  )
}

export default forwardRef(TextAreaField)
