import { IBaseResponse } from '@models/common.model'
import { MetricDto } from '@models/metric.model'
import { IProject } from '@models/projects.model'
import { SamplingPeriodDto, SamplingPeriodResponse } from '@models/samplingPeriods.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'

export interface ProjectListResponse {
  data: IProject[] | null
  count: number | null
  error: any
}

export interface ProjectDetail {
  data: IProject | null
  count: number | null
  error: any
}

export class ProjectService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchAllProjects = async (): Promise<ProjectListResponse> => {
    try {
      const response = await this.client
        .from('projects')
        .select(
          `
        *,
        organisation:organisations(id, name),
        observations:observations(count),
        plans:plans(count)
      `,
        )
        .is('deleted_at', null)
      if (response.error) {
        return { data: null, error: response?.error, count: null }
      }
      const data = response.data.map(project => ({
        ...project,
        observations_count: project.observations[0]?.count || 0,
        plans_count: project.plans[0]?.count || 0,
      }))
      return { data: data, error: null, count: response.data.length }
    } catch (error: any) {
      return { data: null, error, count: null }
    }
  }

  fetchProjectDetail = async ({ id, planId }: { id: string; planId?: string | null }): Promise<IBaseResponse<IProject>> => {
    try {
      const projectRes = await this.client.from('projects').select('*, organisation:organisations(id, name)').eq('id', id).single()
      let planIdRequest = planId
      if (!planId) {
        const plansResponse = await this.client
          .from('plans')
          .select('*')
          .eq('project_id', id)
          .is('deleted_at', null)
          .order('created_at', { ascending: false })
          .single()
        planIdRequest = plansResponse.data?.id
      }
      let boundaryQuery = this.client.from('map_features').select(`*`).eq('is_project_boundary', true).eq('project_id', id)
      if (planIdRequest) {
        boundaryQuery = boundaryQuery.eq('plan_id', planIdRequest)
      }
      const boundaryRes = await boundaryQuery.maybeSingle()
      if (boundaryRes.data) {
        projectRes.data.boundary = boundaryRes.data
      }
      if (projectRes.error) {
        return { data: null, error: projectRes?.error }
      }
      return { data: projectRes.data, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchSamplingPeriodsByProjectId = async (projectId: string): Promise<IBaseResponse<SamplingPeriodResponse[]>> => {
    try {
      const response = await this.client.from('sampling_periods').select().eq('project_id', projectId)
      if (response.error) {
        return { data: null, error: response.error }
      }
      const samplingsPeriods = response.data.map(item => new SamplingPeriodDto(item))
      return { data: samplingsPeriods, error: response.error }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchMetricsByProjectId = async (projectId: string): Promise<IBaseResponse<MetricDto[]>> => {
    try {
      const response = await this.client.from('metrics').select().eq('project_id', projectId)
      if (response.error) {
        return { data: null, error: response.error }
      }
      const metrics = response.data.map(item => new MetricDto(item))
      return { data: metrics, error: response.error }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

// Singleton pattern
const projectService = new ProjectService()

export default projectService
