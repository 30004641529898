import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@mui/material'
import { stringFirstNameAvatar } from '@utils/common'

export type AvatarVariant = 'image' | 'first-character'

export type AvatarProps = {
  children?: React.ReactNode
  avatarVariant?: AvatarVariant
  name?: string
} & MuiAvatarProps

const Avatar = ({ children, avatarVariant = 'image', name, ...rest }: AvatarProps) => {
  return avatarVariant === 'first-character' ? <MuiAvatar {...rest} {...stringFirstNameAvatar(name || '')} /> : <MuiAvatar {...rest} />
}

export default Avatar
