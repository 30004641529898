import dayjs from 'dayjs'

const formatDate = (date?: Date | string, format?: string) => {
  if (!date) return ''
  const parsedDate = typeof date === 'string' ? new Date(date) : date
  return dayjs(parsedDate).format(format)
}

export const defaultDateFormat = (date?: Date | string) => formatDate(date, 'DD MMMM YYYY')

export const compactDateFormat = (date?: Date | string) => formatDate(date, 'DD/MM/YY')

export const shortDateFormat = (date?: Date | string) => formatDate(date, 'MMM D, YYYY')

export const defaultTimeFormat = (date?: Date | string) => formatDate(date, 'h:mm A')
