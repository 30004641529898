import type { SVGProps } from 'react'
const SvgIconProjects = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M17.143 2.143c.393 0 .714.321.714.714v14.286a.716.716 0 0 1-.714.714H2.857a.716.716 0 0 1-.714-.714V2.857c0-.393.321-.714.714-.714zM2.857 0A2.86 2.86 0 0 0 0 2.857v14.286A2.86 2.86 0 0 0 2.857 20h14.286A2.86 2.86 0 0 0 20 17.143V2.857A2.86 2.86 0 0 0 17.143 0zm.714 3.571V10H10V3.571zM10 10v6.429h6.429V10z"
    />
  </svg>
)
export default SvgIconProjects
