import Icons from '@components/atom/Icons'
import { v4 as uuidv4 } from 'uuid'
import { MenuSideBarItem, SECTION_TITLE } from '@components/template/Sidebar/Sidebar.types'
import { Divider, List } from '@mui/material'
import { memo, useMemo } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import {
  ItemIcon,
  ItemTitle,
  MenuItemContainer,
  menuWrapperStyle,
  NotificationBoxStyle,
  NotificationDot,
  SectionDivider,
  SectionTitle,
} from './style'
import { Box, Stack, Typography } from '@components/atom'
import ERoutePaths from '@configs/routes/routes.path'
import { useAuthentication } from '@context/AuthenticationContext'
import SvgIconProjects from '@icons/IconProjects'
import SvgIconReport from '@icons/IconReport'
import SvgIconSignOut from '@icons/IconSignOut'
import IconUsers from '@icons/IconUsers'
// import SvgIconFaq from '@icons/IconFaq'
// import SvgIconHome from '@icons/IconHome'
// import SvgIconJobs from '@icons/IconJobs'
// import SvgIconSetting from '@icons/IconSetting'
// import SvgIconSupport from '@icons/IconSupport'

export interface IMenuListProps {
  isSideBarCollapse: boolean
}

const MenuSideBar = memo(({ isSideBarCollapse }: IMenuListProps) => {
  const { logout } = useAuthentication()

  const listSideBar: MenuSideBarItem[] = useMemo(
    () => [
      // {
      //   title: 'My Dashboard',
      //   sectionTitle: SECTION_TITLE.MAIN_MENU,
      //   link: ERoutePaths.Home,
      //   icon: SvgIconHome,
      //   id: uuidv4(),
      // },
      {
        title: 'Project Management',
        sectionTitle: SECTION_TITLE.MAIN_MENU,
        link: ERoutePaths.Projects,
        icon: SvgIconProjects,
        id: uuidv4(),
      },
      {
        title: 'Reporting',
        link: ERoutePaths.Reports,
        sectionTitle: SECTION_TITLE.MAIN_MENU,
        icon: SvgIconReport,
        id: uuidv4(),
      },
      {
        title: 'User Management',
        link: ERoutePaths.UserManagement,
        sectionTitle: SECTION_TITLE.MAIN_MENU,
        icon: IconUsers,
        id: uuidv4(),
      },
      // {
      //   title: 'Jobs',
      //   link: ERoutePaths.Home,
      //   sectionTitle: SECTION_TITLE.MAIN_MENU,
      //   icon: SvgIconJobs,
      //   id: uuidv4(),
      // },
      // {
      //   title: 'Settings',
      //   sectionTitle: SECTION_TITLE.SYSTEM_SUPPORT,
      //   link: ERoutePaths.Home,
      //   icon: SvgIconSetting,
      //   id: uuidv4(),
      // },
      // {
      //   title: 'Support',
      //   sectionTitle: SECTION_TITLE.SYSTEM_SUPPORT,
      //   link: ERoutePaths.Home,
      //   icon: SvgIconSupport,
      //   id: uuidv4(),
      // },
      // {
      //   title: 'FAQ',
      //   link: ERoutePaths.Home,
      //   sectionTitle: SECTION_TITLE.SYSTEM_SUPPORT,
      //   icon: SvgIconFaq,
      //   id: uuidv4(),
      // },
      {
        title: 'Sign Out',
        link: ERoutePaths.Home,
        sectionTitle: SECTION_TITLE.SYSTEM_SUPPORT,
        icon: SvgIconSignOut,
        onClick: logout,
        id: uuidv4(),
      },
    ],
    [],
  )

  const location = useLocation()
  const navigate = useNavigate()
  const navigateItem = (link: string) => {
    navigate(link)
  }

  const groupedMenu: Record<string, MenuSideBarItem[]> = listSideBar.reduce(
    (acc, item) => {
      if (!acc[item.sectionTitle]) {
        acc[item.sectionTitle] = []
      }
      acc[item.sectionTitle].push(item)
      return acc
    },
    {} as Record<string, MenuSideBarItem[]>,
  )

  return (
    <List sx={menuWrapperStyle}>
      {Object.entries(groupedMenu).map(([section, items]) => {
        return (
          <Stack key={section} direction="column" sx={{ width: '100%' }}>
            {isSideBarCollapse ? <Divider sx={SectionDivider} /> : <Typography sx={SectionTitle}>{section}</Typography>}

            <Stack key={section} gap={1} direction="column" sx={{ marginBottom: '24px' }}>
              {items.map(menuItem => {
                const isSelected = menuItem.link === location.pathname
                const matchedPath = matchPath(menuItem.link, location.pathname)
                const hasNotifications = !!menuItem?.notificationAmount && menuItem?.notificationAmount > 0
                return (
                  <MenuItemContainer
                    key={menuItem.id}
                    onClick={!!menuItem.onClick ? menuItem.onClick : () => navigateItem(menuItem?.link)}
                    isSelected={isSelected || !!matchedPath}
                    isSideBarCollapse={isSideBarCollapse}
                  >
                    <Stack gap={2} alignItems="center">
                      <Box sx={ItemIcon}>
                        <Icons Icon={menuItem.icon} color={isSelected ? 'white' : 'currentColor'} width={20} height={20} />
                        {hasNotifications && isSideBarCollapse && <NotificationDot />}
                      </Box>
                      {!isSideBarCollapse && (
                        <ItemTitle hasNotifications={hasNotifications} isSelected={isSelected}>
                          {menuItem.title}
                        </ItemTitle>
                      )}
                    </Stack>
                    {hasNotifications && !isSideBarCollapse && (
                      <Box component="span" sx={NotificationBoxStyle}>
                        {menuItem?.notificationAmount}
                      </Box>
                    )}
                  </MenuItemContainer>
                )
              })}
            </Stack>
          </Stack>
        )
      })}
    </List>
  )
})

export default MenuSideBar
