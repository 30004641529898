import { styled, SxProps } from '@mui/material'

export const item = ({ isSelected, disabled }: any): SxProps => ({
  // backgroundColor: isSelected ? 'blue.100' : 'white',
  cursor: disabled ? 'default' : 'pointer',
  position: 'relative',
  ':hover': {
    backgroundColor: 'blue.100',
  },
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: isSelected ? '4px' : '0px',
    height: '100%',
    backgroundColor: isSelected ? 'blue.200' : 'transparent',
    transition: 'width 0.3s ease-in-out',
  },
})

export const itemType = ({ isSelected, disabled }: any): SxProps => ({
  padding: '16px 24px',
  cursor: disabled ? 'default' : 'pointer',
  position: 'relative',
  ':hover': {
    backgroundColor: 'blue.100',
  },
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: isSelected ? '4px' : '0px',
    height: '100%',
    backgroundColor: isSelected ? 'blue.200' : 'transparent',
    transition: 'width 0.3s ease-in-out',
  },
})

// DEPRECATED
export const selectStyles: SxProps = {
  fontSize: '13px',
  width: '60px',
  paddingRight: '0 !important', // Reclaims space from hidden icon
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'clip',
  '& .MuiSelect-select': {
    paddingRight: '0 !important', // Ensures text can use full width
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
}

export const menuItemStyles: SxProps = {
  '& .MuiMenuItem-root': {
    fontSize: '13px', // Smaller font size for dropdown options
  },
}

export const IconWrapper = styled('div')({
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: 'grey',
  color: 'grey.500',
})
