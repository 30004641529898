import VectorLayer from 'ol/layer/Vector'
import _ from 'lodash'
import { Collection, Feature, Map as OlMap } from 'ol'
import { singleClick } from 'ol/events/condition'
import { Geometry } from 'ol/geom'
import { Select } from 'ol/interaction'
import { SelectEvent } from 'ol/interaction/Select'
import { FeatureProperties } from '../open-layer.styles'

export const setupSelectInteraction = (map: OlMap, onSelect?: (feature?: Feature<Geometry>) => void, layers?: VectorLayer<any, any>[]) => {
  removeSelectInteraction(map) // Always remove it before adding it

  const selectInteraction = new Select({
    condition: singleClick,
    style: null,
    layers: layers,
  })

  const selectListener = (event: any) => {
    const { selected, deselected } = event
    onSelect?.(_.last(selected))

    // Update selected features
    selected.forEach((feature: Feature) => {
      feature.set(FeatureProperties.Selected, true)
    })
    deselected.forEach((feature: Feature) => {
      feature.set(FeatureProperties.Selected, false)
    })
  }

  selectInteraction.on('select', selectListener)

  map.addInteraction(selectInteraction)
}

export const setupSingleSelectInteraction = (
  map: OlMap,
  onSelect?: (feature?: Feature<Geometry>) => void,
  layers?: VectorLayer<any, any>[],
) => {
  removeSelectInteraction(map) // Always remove it before adding it

  const selectInteraction = new Select({
    condition: singleClick,
    style: null,
    multi: false,
    layers: layers,
  })

  const selectListener = (event: SelectEvent) => {
    const { selected, deselected } = event
    deselected.forEach((feature: Feature) => {
      feature?.set(FeatureProperties.Selected, false)
    })

    const feature = _.last(selected)
    feature?.set(FeatureProperties.Hover, false)
    feature?.set(FeatureProperties.Selected, true)

    onSelect?.(feature)
  }

  selectInteraction.on('select', selectListener)

  map.addInteraction(selectInteraction)
}

export const removeSelectInteraction = (map?: OlMap | null) => {
  if (!map) return
  const interactions = map.getInteractions()
  const selectInteractions = interactions.getArray().filter(interaction => interaction instanceof Select)
  selectInteractions.forEach(interaction => map.removeInteraction(interaction))
}

export const getSelectedFeatures = (map?: OlMap): Collection<Feature<Geometry>> => {
  if (!map) return new Collection()

  const selectInteraction = map
    .getInteractions()
    .getArray()
    .find(interaction => interaction instanceof Select) as Select | undefined

  if (!selectInteraction) {
    return new Collection()
  }

  return selectInteraction.getFeatures()
}

export const clearSelectedFeatures = (map?: OlMap | null) => {
  if (!map) return

  const selectInteraction = map
    .getInteractions()
    .getArray()
    .find(interaction => interaction instanceof Select) as Select | undefined

  if (!selectInteraction) {
    return
  }

  selectInteraction.getFeatures().clear()
}

export const addSelectedFeature = (map?: OlMap, feature?: Feature<Geometry>) => {
  if (!map || !feature) return

  const selectInteraction = map
    .getInteractions()
    .getArray()
    .find(interaction => interaction instanceof Select) as Select | undefined

  if (!selectInteraction) {
    return
  }

  const selectedFeatures = selectInteraction.getFeatures()
  selectedFeatures.push(feature)
}
