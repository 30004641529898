import { ThemeOptions } from '@mui/material/styles'
import createTheme from '@mui/material/styles/createTheme'
import { TypographyOptions } from '@mui/material/styles/createTypography'

import components from './components'
import { purple, green, mapPalette, cornflowerBlue, cadetGrey, moonstoneTeal, pumpkinOrange, neutral, gambogeYellow } from './palettes'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariants {
    mono: true
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  mono: React.CSSProperties
}

export const lightThemeDefault: ThemeOptions = {
  palette: {
    mode: 'light',
    green, // TODO: Need provide from designer
    purple, // TODO: Need provide from designer
    blue: cornflowerBlue,
    yellow: gambogeYellow,
    orange: pumpkinOrange,
    teal: moonstoneTeal,
    grey: cadetGrey,
    neutral,
    mapPalette,
    text: {
      primary: '#333333',
      secondary: '#8D959B',
    },
    primary: {
      contrastText: '#fff',
      main: moonstoneTeal[500],
    },
    secondary: {
      contrastText: '#fff',
      main: cadetGrey[500],
    },
    error: {
      contrastText: '#fff',
      light: '#FDE4E4', // Error 1
      main: '#EF4444', // Error 2
      dark: '#BC1010', // Error 3
    },
    success: {
      light: '#DDF9E7', // Success 1
      main: '#22C55E', // Success 2
      dark: '#082F4A', // Success 3
      contrastText: '#fff',
    },
    warning: {
      light: '#FEF3C7', // Success 1
      main: '#F59E0B', // Success 2
      dark: '#694404', // Success 3
      contrastText: '#fff',
    },
    info: {
      light: '#DCEEFB', // Success 1
      main: '#1788D4', // Success 2
      dark: '#082F4A', // Success 3
      contrastText: '#fff',
    },
    btnOrange: {
      light: pumpkinOrange[300],
      main: pumpkinOrange[500],
      dark: pumpkinOrange[800],
      contrastText: '#fff',
    },
    divider: '#DEE1E3',
  },
  shape: {
    borderRadius: 8,
  },
  zIndex: {
    sideBar: 100,
  },
  shadows: [
    'none',
    'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
    'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;',
    'rgba(0, 0, 0, 0.1) 0px 1px 2px;',
    'rgba(0, 0, 0, 0.2) 0px 2px 4px;',
    'rgba(0, 0, 0, 0.2) 0px 3px 6px;',
    'rgba(0, 0, 0, 0.3) 0px 4px 8px;',
    'rgba(0, 0, 0, 0.3) 0px 5px 10px;',
    'rgba(0, 0, 0, 0.4) 0px 6px 12px;',
    'rgba(0, 0, 0, 0.4) 0px 7px 14px;',
    'rgba(0, 0, 0, 0.5) 0px 8px 16px;',
    'rgba(0, 0, 0, 0.5) 0px 9px 18px;',
    'rgba(0, 0, 0, 0.6) 0px 10px 20px;',
    'rgba(0, 0, 0, 0.6) 0px 11px 22px;',
    'rgba(0, 0, 0, 0.7) 0px 12px 24px;',
    'rgba(0, 0, 0, 0.7) 0px 13px 26px;',
    'rgba(0, 0, 0, 0.8) 0px 14px 28px;',
    'rgba(0, 0, 0, 0.8) 0px 15px 30px;',
    'rgba(0, 0, 0, 0.9) 0px 16px 32px;',
    'rgba(0, 0, 0, 0.9) 0px 17px 34px;',
    'rgba(0, 0, 0, 1.0) 0px 18px 36px;',
    'rgba(0, 0, 0, 1.0) 0px 19px 38px;',
    'rgba(0, 0, 0, 1.0) 0px 20px 40px;',
    'rgba(0, 0, 0, 1.0) 0px 21px 42px;',
    'rgba(0, 0, 0, 1.0) 0px 22px 44px;',
  ],
  components,
  typography: {
    fontSize: 16,
    fontFamily: 'SF Pro Display, sans-serif',
  } as ExtendedTypographyOptions,
}

export default createTheme(lightThemeDefault)
