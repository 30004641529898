const neutral = {
  100: '#FFFFFF',
  200: '#F4F7F7',
  300: '#DEE1E3',
  400: '#C4C8CB',
  500: '#8D959B', // Main color
  600: '#768088',
  700: '#5B6267',
  800: '#333333',
  900: '#202020',
}

export default neutral
