import { Box, BoxProps } from '@mui/material'
import React from 'react'

export type ImageProps = BoxProps & {
  src: string
  srcSet?: string
  alt: string
  loading?: 'eager' | 'lazy'
  className?: string
}

const Image: React.FC<ImageProps> = ({ src, srcSet, alt, loading = 'lazy', className, ...rest }) => {
  return <Box component="img" src={src} srcSet={srcSet} alt={alt} loading={loading} className={className} {...rest} />
}

export default Image
