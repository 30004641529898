import { logAnalyticsEvent } from '@configs/firebase'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const PageViewAnalyticsHandler = () => {
  const location = useLocation()

  const stripUUIDs = (str: string) => {
    return str.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi, '[id]')
  }

  useEffect(() => {
    logAnalyticsEvent('page_view', {
      page_path: stripUUIDs(location.pathname),
      page_location: stripUUIDs(window.location.href),
      page_title: document.title,
    })
  }, [location])

  return null
}

export default PageViewAnalyticsHandler
