import CheckIcon from '@mui/icons-material/Check'
import { Menu } from '@mui/material'
import { useState } from 'react'

import { Avatar, Box, Typography } from '@components/atom'
import { ACTIONS } from '@context/GlobalContext/GlobalReducer'
import useGlobalContext from '@context/GlobalContext/hooks/useGlobalContext'
import { MenuOrganisation } from '@icons/index'

import { IOrganisation } from '@models/organisation.model'
import { menuContainer, menuInfo, orgName, paperStyle, popupContainer, popupItem, selectedIcon } from './OrganisationMenu.style'

export type OrganisationProps = {
  isCollapse: boolean
  organisations: IOrganisation[]
  selectedOrgId?: IOrganisation['id']
}

const OrganisationMenu = ({ isCollapse, organisations, selectedOrgId }: OrganisationProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const selectedOrg = organisations.find(org => org.id === selectedOrgId)

  const { dispatch } = useGlobalContext()

  const open = Boolean(anchorEl)
  const onClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box sx={menuContainer} onClick={handleOpen}>
      <>
        <Box sx={menuInfo}>
          {<Avatar avatarVariant="first-character" name={selectedOrg?.name} />}
          {!isCollapse && <Typography sx={orgName}>{selectedOrg?.name}</Typography>}
        </Box>
        {!isCollapse && <MenuOrganisation style={{ marginRight: '8px' }} width={16} height={16} color="common.white" />}
      </>
      <Menu
        id="org-menu"
        anchorEl={anchorEl}
        open={open}
        onClick={() => {
          setTimeout(() => onClose(), 100)
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: paperStyle,
          },
        }}
        MenuListProps={{
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
          },
          'aria-labelledby': 'basic-button',
        }}
      >
        {organisations.map(org => (
          <Box
            sx={popupContainer}
            key={org.id}
            onClick={() => {
              dispatch({
                type: ACTIONS.SET_SELECTED_ORGANISATION,
                payload: { selectedOrganisationId: org.id },
              })
              setTimeout(() => onClose(), 100)
            }}
          >
            <Box sx={popupItem}>
              <Avatar avatarVariant="first-character" name={org?.name || 'Default'} />
              <Typography sx={orgName}>{org?.name}</Typography>
            </Box>
            {selectedOrgId === org.id && <CheckIcon sx={selectedIcon} />}
          </Box>
        ))}
      </Menu>
    </Box>
  )
}

export default OrganisationMenu
