import Table from '@mui/material/Table'
import _get from 'lodash/get'
import React, { memo, useState } from 'react'

import EachComponent from '@components/atom/ForEachComponent/ForEachComponent'
import Render from '@components/atom/Render/Render'
import { TableBody, TableCell, TableCellProps, TableContainer, TableContainerProps, TableHead, TableRow } from '@components/atom/Table'
import { Box, HStack, Icons, LinearProgress, Typography, VStack } from '@components/atom'
import { ArrowLeft, IconEmptySearch } from '@icons/index'

export type TColumn<T> = {
  key: string
  displayName: string | React.ReactNode | number
  renderCell?: ({ row, data }: { row: T | any; data: T[] | any }) => React.ReactNode
  renderHeader?: ({ displayName }: { displayName: any }) => React.ReactNode
  sortable?: boolean
} & TableCellProps

export type SimpleTableProps<T> = {
  data: T[]
  columns: TColumn<T>[]
  onItemClick: (item: T | any) => void
  sx?: TableContainerProps['sx']
  loading?: boolean
  noData?: React.ReactNode
}

export const SimpleTable = memo(<T,>({ data, columns, onItemClick, sx, loading, noData }: SimpleTableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null)

  const sortedData = React.useMemo(() => {
    if (!sortConfig) return data

    const { key, direction } = sortConfig
    return [...data].sort((a, b) => {
      const aValue = _get(a, key, '') || ''
      const bValue = _get(b, key, '') || ''

      if (aValue < bValue) return direction === 'asc' ? -1 : 1
      if (aValue > bValue) return direction === 'asc' ? 1 : -1
      return 0
    })
  }, [data, sortConfig])

  const handleSort = (key: string) => {
    if (sortConfig?.key === key) {
      if (sortConfig.direction === 'asc') {
        setSortConfig({ key, direction: 'desc' })
      } else if (sortConfig.direction === 'desc') {
        setSortConfig(null)
      } else {
        setSortConfig({ key, direction: 'asc' })
      }
    } else {
      setSortConfig({ key, direction: 'asc' })
    }
  }

  return (
    <TableContainer sx={sx}>
      {loading && <LinearProgress color="info" />}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {Object.values(columns).map((column, i) => (
              <React.Fragment key={i}>
                {column.renderHeader ? (
                  <HStack
                    spacing={2}
                    onClick={() => column.sortable && handleSort(column.key)}
                    sx={{ cursor: column.sortable ? 'pointer' : 'default' }}
                  >
                    {column.renderHeader({
                      displayName: column.displayName,
                    })}
                    {column.sortable && sortConfig?.key === column.key && (
                      <span>
                        {sortConfig.direction === 'asc' ? (
                          <Icons Icon={ArrowLeft} sx={{ transform: 'rotate(90deg)' }} width={16} height={16} />
                        ) : sortConfig.direction === 'desc' ? (
                          <Icons Icon={ArrowLeft} sx={{ transform: 'rotate(270deg)' }} width={16} height={16} />
                        ) : (
                          ''
                        )}
                      </span>
                    )}
                  </HStack>
                ) : (
                  <TableCell
                    key={i}
                    sx={{
                      paddingY: '8px',
                      fontWeight: '600',
                      position: 'sticky',
                      top: 0,
                      cursor: column.sortable ? 'pointer' : 'default',
                    }}
                    onClick={() => column.sortable && handleSort(column.key)}
                  >
                    <HStack alignItems="center" spacing={2}>
                      {column.displayName}
                      {column.sortable && sortConfig?.key === column.key && (
                        <Box>
                          {sortConfig.direction === 'asc' ? (
                            <Icons Icon={ArrowLeft} sx={{ transform: 'rotate(90deg)' }} width={16} height={16} />
                          ) : sortConfig.direction === 'desc' ? (
                            <Icons Icon={ArrowLeft} sx={{ transform: 'rotate(270deg)' }} width={16} height={16} />
                          ) : (
                            ''
                          )}
                        </Box>
                      )}
                    </HStack>
                  </TableCell>
                )}
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <Render test={sortedData && sortedData.length > 0}>
            <EachComponent
              of={sortedData}
              render={(row, i) => (
                <TableRow key={i} onClick={() => onItemClick(row)}>
                  {columns.map(column => (
                    <React.Fragment key={column.key}>
                      {column.renderCell ? (
                        column.renderCell({
                          row,
                          data: sortedData,
                        })
                      ) : (
                        <TableCell {...column} key={column.key}>
                          {_get(row, column.key, '') as React.ReactNode}
                        </TableCell>
                      )}
                    </React.Fragment>
                  ))}
                </TableRow>
              )}
            />
          </Render>
          <Render test={!sortedData || sortedData.length === 0}>
            <TableRow>
              <TableCell colSpan={columns.length} sx={{ textAlign: 'center', padding: 3 }}>
                {noData ? (
                  noData
                ) : (
                  <VStack spacing={3} alignItems="center" justifyContent="center">
                    <Icons Icon={IconEmptySearch} color="grey.500" width={64} height={64} />
                    <Typography variant="h6" color="text.secondary">
                      No data found
                    </Typography>
                  </VStack>
                )}
              </TableCell>
            </TableRow>
          </Render>
        </TableBody>
      </Table>
    </TableContainer>
  )
})

export default SimpleTable
