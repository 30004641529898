import type { SVGProps } from 'react'
const SvgMenuOrganisation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="#fff"
      d="M8.09 15.83a.79.79 0 0 1-.979 0L1.894 11.7a.766.766 0 0 1-.121-1.087.79.79 0 0 1 1.099-.122l4.729 3.742 4.728-3.742a.785.785 0 0 1 1.1.122.77.77 0 0 1-.125 1.088l-5.217 4.13zM13.307 4.3a.77.77 0 0 1 .121 1.087.79.79 0 0 1-1.099.119L7.601 1.763 2.872 5.506a.787.787 0 0 1-1.099-.12.77.77 0 0 1 .12-1.087L7.112.17a.79.79 0 0 1 .979 0z"
    />
  </svg>
)
export default SvgMenuOrganisation
