import ERoutePaths from '@configs/routes/routes.path'
import useAppParams from '@hooks/useAppParams'
import { useMemoizedFn } from 'ahooks'
import { useNavigate } from 'react-router-dom'

export const useAppNavigate = () => {
  const navigate = useNavigate()
  const urlParams = useAppParams()

  const browserBack = useMemoizedFn(() => navigate(-1))

  const navigateToSurveyListPage = ({ projectId }: { projectId: string }) => {
    navigate(`${ERoutePaths.ListSurvey.replace(':projectId', projectId)}`)
  }

  const navigateToProjectsPage = () => {
    navigate(ERoutePaths.Projects)
  }

  const navigateToReportsPage = () => {
    navigate(ERoutePaths.Reports)
  }

  const navigateToReportPage = ({ reportId }: { reportId: string }) => {
    const params = new URLSearchParams({
      reportId,
    })
    navigate(`${ERoutePaths.Report}?${params.toString()}`)
  }

  const navigateToSurveyDetailPage = ({
    surveyId,
    projectId,
    planId,
  }: {
    surveyId?: string
    projectId?: string
    planId?: string
  } = {}) => {
    const finalProjectId = projectId ?? urlParams.projectId

    if (!finalProjectId) {
      console.error('Missing required parameters: projectId or surveyId')
      return
    }

    const queryString = new URLSearchParams({
      surveyId: surveyId ?? urlParams.surveyId,
      planId: planId ?? urlParams.planId,
    }).toString()
    const path = `${ERoutePaths.SurveyDetail.replace(':projectId', finalProjectId)}`

    navigate(queryString ? `${path}?${queryString}` : path, { replace: true })
  }

  const navigateToCreateSurveyPage = ({ projectId, planId }: { projectId?: string; planId?: string }) => {
    const projectDetailId = projectId ?? urlParams.projectId
    const queryString = new URLSearchParams({
      planId: planId ?? urlParams.planId,
    }).toString()
    const path = `/projects/${projectDetailId}${ERoutePaths.CreateSurvey.split('/:projectId').pop()}`
    navigate(queryString ? `${path}?${queryString}` : path)
  }

  const navigateToEditSurveyPage = ({ surveyId, projectId, planId }: { surveyId?: string; projectId?: string; planId?: string }) => {
    const finalProjectId = projectId ?? urlParams.projectId

    if (!finalProjectId) {
      console.error('Missing required parameters: projectId or surveyId')
      return
    }

    const queryString = new URLSearchParams({
      surveyId: surveyId ?? urlParams.surveyId,
      planId: planId ?? urlParams.planId,
    }).toString()

    const path = `${ERoutePaths.EditSurvey.replace(':projectId', finalProjectId)}`

    navigate(queryString ? `${path}?${queryString}` : path, { replace: true })
  }

  const navigateToCheckPointOverviewPage = ({
    surveyId,
    projectId,
    mediaId,
    observationId,
    checkpointId,
    planId,
  }: {
    surveyId: string
    projectId: string
    checkpointId: string
    mediaId?: string
    observationId?: string
    planId: string
  }) => {
    const finalProjectId = projectId ?? urlParams.projectId
    const params = new URLSearchParams({
      surveyId: surveyId ?? urlParams.surveyId,
      checkpointId: checkpointId ?? urlParams.checkpointId,
      observationId: observationId ?? urlParams.observationId,
      mediaId: mediaId ?? urlParams.mediaId,
      planId: planId ?? urlParams.planId,
    })
    navigate(`${ERoutePaths.CheckpointOverview.replace(':projectId', finalProjectId)}?${params.toString()}`)
  }

  const navigateToHomepage = useMemoizedFn(() => navigate(ERoutePaths.Home))

  const navigateToLoginPage = useMemoizedFn(() =>
    // Prevent users getting stuck of an infinite loop of navigating back to login even though they already have
    // Was seen when a new user was created and attached to nothing
    navigate(ERoutePaths.Login, {
      state: window.location.href.includes(ERoutePaths.Login) ? {} : { redirectUrl: window.location.href },
    }),
  )
  const navigateToCaptureInfoPage = useMemoizedFn(options => navigate(ERoutePaths.CaptureInfo, options))

  return {
    browserBack,
    navigateToCheckPointOverviewPage,
    navigateToProjectsPage,
    navigateToReportsPage,
    navigateToReportPage,
    /**
     * Survey flow
     */
    navigateToSurveyDetailPage,
    navigateToCreateSurveyPage,
    navigateToSurveyListPage,
    navigateToEditSurveyPage,
    //
    navigateToLoginPage,
    navigateToHomepage,
    navigateToCaptureInfoPage,
  }
}

export default useAppNavigate
