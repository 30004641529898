import { forwardRef, useImperativeHandle, useMemo, useState } from 'react'

import { ButtonGroup, Typography, VStack } from '@components/atom'
import Icons from '@components/atom/Icons'
import { IconAddNote, IconCursor, IconLabelTag, IconSpecies } from '@icons/index'
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined'
import { useTheme } from '@mui/material'

import { ActionButtonStyled } from './LabelingToolbar.styles'
import { TAction, TActionType } from './LabelingToolbar.types'
import { logAnalyticsEvent } from '@configs/firebase'

export type LabelingToolbarProps = {
  onSelectAction?: (action: TAction) => void
  defaultAction?: TActionType
}

const LabelingToolbar = forwardRef(({ onSelectAction, defaultAction = 'species_label' }: LabelingToolbarProps, ref) => {
  const [selected, setSelected] = useState<TActionType | undefined>(defaultAction)
  const { palette } = useTheme()

  const handleClick = (action: TAction) => {
    const { type } = action
    setSelected(type)
    onSelectAction?.(action)
    logAnalyticsEvent('labelling_toolbar_selection', {
      tool_name: type,
    })
  }

  const actions: TAction[] = useMemo(
    () => [
      {
        type: 'cursor',
        hotkey: 'v',
        icon: <Icons width={20} height={20} Icon={IconCursor} color={selected === 'cursor' ? palette.green[700] : palette.grey[700]} />,
      },
      {
        type: 'species_label',
        hotkey: 's',
        icon: (
          <Icons width={20} height={20} Icon={IconSpecies} color={selected === 'species_label' ? palette.green[700] : palette.grey[700]} />
        ),
      },
      {
        type: 'generic_label',
        hotkey: 'd',
        icon: (
          <Icons width={20} height={20} Icon={IconLabelTag} color={selected === 'generic_label' ? palette.green[700] : palette.grey[700]} />
        ),
      },
      // {
      //   type: 'custom_label',
      //   hotkey: 't',
      //   icon: (
      //     <Icons
      //       Icon={() => <LocalOfferOutlinedIcon sx={{ width: 20, height: 20 }} />}
      //       color={selected === 'custom_label' ? palette.green[700] : palette.grey[700]}
      //     />
      //   ),
      // },
      {
        type: 'note',
        hotkey: 'n',
        icon: <Icons width={20} height={20} Icon={IconAddNote} color={selected === 'note' ? palette.green[700] : palette.grey[700]} />,
      },
      {
        type: 'hand_free',
        hotkey: 'h',
        icon: (
          <Icons
            Icon={() => <BackHandOutlinedIcon sx={{ width: 20, height: 20 }} />}
            color={selected === 'hand_free' ? palette.green[700] : palette.grey[700]}
          />
        ),
      },
    ],
    [selected],
  )

  useImperativeHandle(ref, () => ({ setSelected }), [setSelected])

  return (
    <ButtonGroup variant="outlined" aria-label="labeling-toolbar">
      {actions.map(action => (
        <ActionButtonStyled key={action.type} selected={selected === action.type} size="small" onClick={() => handleClick(action)}>
          <VStack>
            {action.icon}
            <Typography variant="body2">{action.hotkey}</Typography>
          </VStack>
        </ActionButtonStyled>
      ))}
    </ButtonGroup>
  )
})

export default LabelingToolbar
