import { Box } from '@components/atom'
import { styled, SxProps } from '@mui/material/styles'

export const NotificationDot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 6,
  height: 6,
  backgroundColor: theme.palette.error.main,
  borderRadius: '50%',
  transform: 'translate(50%, -50%)',
}))

export const ItemIcon: SxProps = {
  position: 'relative',
  display: 'inline-block',
}
