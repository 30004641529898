import { ICheckpointLocation } from '@models/checkpoint.model'
import { IBaseResponse } from '@models/common.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import { uniqBy } from 'lodash'

export interface CheckpointsResponse {
  data: any[] | null
  error: any
}

export class CheckpointService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchCheckpointsAcrossProject = async ({
    projectId,
    startDate,
    endDate,
  }: {
    projectId: string
    startDate?: string
    endDate?: string
  }): Promise<IBaseResponse<ICheckpointLocation[]>> => {
    const params: { project_id_to_filter: string; start_date?: string; end_date?: string } = {
      project_id_to_filter: projectId,
      start_date: startDate,
      end_date: endDate,
    }

    const { data, error } = await this.client.rpc('get_checkpoints_with_observations_by_project', params)
    if (error) {
      return { data: null, error: error }
    }
    const uniqueCheckpoints = uniqBy(
      data.map((item: any) => {
        item.geometry = JSON.parse(item.geom)
        return { ...item }
      }),
      'checkpoint_id',
    )
    return { data: this.toCamelCase(uniqueCheckpoints), error: null }
  }

  fetchCheckpointsByPlanId = async ({
    planId,
    startDate,
    endDate,
  }: {
    planId: string
    startDate?: string
    endDate?: string
  }): Promise<IBaseResponse<ICheckpointLocation[]>> => {
    if (!planId) {
      throw new Error('PlanId are required parameters.')
    }
    const params: { plan_id_to_filter: string; start_date?: string; end_date?: string } = {
      plan_id_to_filter: planId,
      start_date: startDate,
      end_date: endDate,
    }
    const { data, error } = await this.client.rpc('get_checkpoints_with_observations_by_plan', params)
    if (error) {
      return { data: null, error: error }
    }

    const uniqueCheckpoints = uniqBy(
      data.map((item: any) => {
        return {
          ...item,
          geometry: JSON.parse(item.geom),
        }
      }),
      'checkpoint_id',
    )
    return { data: this.toCamelCase(uniqueCheckpoints), error: null }
  }

  fetchCheckpointsBySurveyId = async ({ surveyId }: { surveyId: string }): Promise<IBaseResponse<ICheckpointLocation[]>> => {
    if (!surveyId) {
      throw new Error('SurveyId are required parameters.')
    }

    const params: {
      survey_id_to_filter: string
    } = {
      survey_id_to_filter: surveyId,
    }

    const { data, error } = await this.client.rpc('get_checkpoints_with_observations_by_survey', params)
    if (error) {
      console.error('Error fetching observations:', error)
      return { data: null, error: error }
    }

    const uniqueCheckpoints = uniqBy(
      data.map((item: any) => {
        item.geometry = JSON.parse(item.geom)
        return { ...item }
      }),
      'checkpoint_id',
    )

    return { data: this.toCamelCase(uniqueCheckpoints), error: null }
  }
}

// Singleton pattern
const checkpointService = new CheckpointService()

export default checkpointService
