import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiTypography: {
  defaultProps: ComponentsProps['MuiTypography']
  styleOverrides: ComponentsOverrides<Theme>['MuiTypography']
} = {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontFamily: 'SF Pro Display',
    },
    h1: {
      fontSize: '6rem', // 96px
      lineHeight: '7.2rem', // 115.2px
    },
    h2: {
      fontSize: '5rem', // 80px
      lineHeight: '6rem', // 108px
    },
    h3: {
      fontSize: '4rem', // 64px
      lineHeight: '4.8rem', // 76.8px
    },
    h4: {
      fontSize: '3rem', // 48px
      lineHeight: '3.6rem', // 57.6px
    },
    h5: {
      fontSize: '2rem', // 32px
      lineHeight: '2.4rem', // 38.4px
    },
    h6: {
      fontSize: '1.5rem', // 24px
      lineHeight: '2.25rem', // 36px
    },
    subtitle1: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.875rem', // 30px
    },
    subtitle2: {
      fontSize: '1.125rem', // 18px
      lineHeight: '1.6875rem', // 27px
    },
    body1: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
    },
    body2: {
      fontSize: '0.875rem', // 14px
      lineHeight: '1.3125rem', // 21px
    },
    caption: {
      fontSize: '0.75rem', // 12px
      lineHeight: '1.125rem', // 18px
    },
  },
}

export default MuiTypography
