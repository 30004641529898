import { styled } from '@mui/material/styles'
import { MuiOtpInput } from 'mui-one-time-password-input'

export const CustomOtpInput = styled(MuiOtpInput)<{ invalid?: boolean }>(({ theme, invalid }) => ({
  display: 'flex',
  gap: '12px',
  maxWidth: '550px',
  marginInline: 'auto',

  '& .MuiOutlinedInput-root': {
    input: {
      width: '70px',
      height: '56px',
      borderRadius: '4px',
      fontSize: '24px',
      border: `1px solid`,
      boxSizing: 'border-box',
      fontWeight: '700',
      textAlign: 'center',
      caretColor: theme.palette.secondary.main,
      borderColor: invalid ? theme.palette.error.main : theme.palette.divider,
      '&:hover fieldset': {
        borderColor: invalid ? theme.palette.error.main : theme.palette.divider,
      },
      '&.Mui-focused fieldset': {
        borderColor: invalid ? theme.palette.error.main : theme.palette.divider,
      },
    },
  },

  '& .MuiOutlinedInput-root:focus-within': {
    '& input': {
      border: `2px solid ${theme.palette.info.main}`, // Change to desired border color
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))
