import { ButtonProps, IconButton } from '@components/atom'
import { styled } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

type TToolBarButtons = {
  onClick?: any
  title: string
  Icon?: React.ReactNode
  isActive?: boolean
  disabled?: boolean
} & ButtonProps

const StyledIconButton = styled(IconButton)<{ isActive?: boolean; isDisabled?: boolean }>(({ theme, isActive, isDisabled }) => ({
  width: '48px',
  height: '48px',
  color: 'common.white',
  backgroundColor: isActive ? theme.palette.info.main : 'transparent',
  borderRadius: '50%',
  opacity: isDisabled ? 0.5 : 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: isDisabled ? 'default' : 'pointer',
  '&:hover': {
    backgroundColor: isActive ? theme.palette.info.main : 'transparent',
  },
}))

const MapsToolBarButton = ({ onClick, title, Icon, isActive, ...rest }: TToolBarButtons) => {
  return (
    <Tooltip title={title} placement="top" arrow>
      <span>
        <StyledIconButton disableRipple onClick={onClick} isActive={isActive} {...rest}>
          {Icon}
        </StyledIconButton>
      </span>
    </Tooltip>
  )
}
export default MapsToolBarButton
