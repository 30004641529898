export enum ERoutePaths {
  Home = '/',
  Login = '/login',
  Report = '/report',
  Reports = '/reports',
  Projects = '/projects',
  ProjectDetail = '/projects/:projectId',
  ListSurvey = '/projects/:projectId/list-survey',
  CreateSurvey = '/projects/:projectId/list-survey/create-survey',
  EditSurvey = '/projects/:projectId/list-survey/edit-survey',
  SurveyDetail = '/projects/:projectId/survey-detail',
  CheckpointOverview = '/projects/:projectId/checkpoint-overview',
  CaptureInfo = '/capture-info',
  NotFound = '/404',
  Invite = '/invite',
  UserManagement = '/users',
}

export default ERoutePaths
