import { lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import PrivateRoute from '@components/template/PrivateRoute'
import PublicRoute from '@components/template/PublicRoute'
import RootPage from '@pages/RootPage'

import ProjectDetailLayout from '@components/template/ProjectDetailLayout/ProjectDetailLayout'
import checkpointRoutes from './checkpoint.routes'
import projectsRoute from './projects.routes'
import reportsRoute from './reports.routes'
import ERoutePaths from './routes.path'
import userManagementRoutes from './userManagement.routes'

const InvitationPage = lazy(() => import('@pages/Invitation/Invitation'))
const LoginPage = lazy(() => import('@pages/LoginPage/LoginPage'))
const ReportPage = lazy(() => import('@pages/ReportPage/ReportPage'))
const NotFoundPage = lazy(() => import('@pages/NotfoundPage/NotFoundPage'))
const ListSurveyPage = lazy(() => import('@pages/ListSurveyPage/ListSurveyPage'))
const SurveyDetailPage = lazy(() => import('@pages/SurveyDetailPage/SurveyDetailPage'))
const CaptureInfo = lazy(() => import('@pages/CaptureInfo/CaptureInfo'))

/**
 * Public Routes
 */
const publicRoutes = [
  {
    path: ERoutePaths.Login,
    element: (
      <PublicRoute title="Mozaic Earth Portal | Login">
        <LoginPage />
      </PublicRoute>
    ),
  },
]

/**
 * Private Routes
 */
const privateRoutes = [
  {
    path: ERoutePaths.Report,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | Report">
        <ReportPage />
      </PrivateRoute>
    ),
  },
  {
    path: ERoutePaths.CaptureInfo,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | Capture Info">
        <CaptureInfo />
      </PrivateRoute>
    ),
  },
  {
    path: ERoutePaths.Invite,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | Invitation">
        <InvitationPage />
      </PrivateRoute>
    ),
  },
]

/**
 * Project Routes
 */
const projectRoutes = [
  {
    path: ERoutePaths.ProjectDetail,
    element: (
      <ProjectDetailLayout
        mapDisplayRoutes={[ERoutePaths.ListSurvey, ERoutePaths.SurveyDetail, ERoutePaths.CreateSurvey, ERoutePaths.EditSurvey]}
      />
    ),
    children: [
      {
        path: ERoutePaths.ListSurvey,
        element: (
          <PrivateRoute title="Mozaic Earth Portal | Project Dashboard">
            <ListSurveyPage />
          </PrivateRoute>
        ),
      },
      {
        path: ERoutePaths.SurveyDetail,
        element: (
          <PrivateRoute title="Mozaic Earth Portal | Survey">
            <SurveyDetailPage />
          </PrivateRoute>
        ),
      },
      {
        ...checkpointRoutes.checkpointOverview,
      },
    ],
  },
]

/**
 * Main Route Configuration
 */
const routes = [
  ...publicRoutes,
  ...privateRoutes,
  {
    path: ERoutePaths.Home,
    element: <RootPage />,
    children: [
      {
        index: true,
        element: <Navigate to={ERoutePaths.Projects} replace />,
      },
      projectsRoute.projects,
      reportsRoute.reports,
      userManagementRoutes.users,
    ],
  },
  ...projectRoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

const router = createBrowserRouter(routes, { basename: '/' })

export default router
