import { HStack, IconButton, Stack, Typography } from '@components/atom'
import SurveyObservationActionIcon from '@components/atom/SurveyObservationActionIcon/SurveyObservationActionIcon'
import { EObservationState, IObservation } from '@models/observation.model'
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import { ListItem, ListItemButtonProps, ListItemText } from '@mui/material'
import { SELECT_OPTIONS } from '@utils/MasterData'
import { compactDateFormat } from '@utils/formatter'
import { forwardRef, useState } from 'react'
import StateButton from '../StateButton/StateButton'
import * as styles from './ObservationItem.styles'

export type ObservationItemProps = {
  observation: IObservation
  isSelected?: boolean
  onSelect?: () => void
  status?: any
  allowMaskAsDone?: boolean
  onChangeState?: (state: EObservationState) => Promise<any>
  onClickLinkSurvey?: () => Promise<any>
  onClickUnLinkSurvey?: () => Promise<any>
  renderAction?: () => React.ReactNode
  allowUpdateState?: boolean
  allowLinkOrUnlinkSurvey?: boolean
  showStateIcon?: boolean
} & ListItemButtonProps

export const ObservationItem = forwardRef(
  (
    {
      observation,
      disabled,
      onSelect,
      isSelected,
      onClickLinkSurvey,
      onClickUnLinkSurvey,
      onChangeState,
      allowUpdateState,
      allowLinkOrUnlinkSurvey,
      showStateIcon,
    }: ObservationItemProps,
    ref: any,
  ) => {
    const [loadingLinking, setLoadingLinking] = useState(false)
    const [loadingUpdateState, setLoadingUpdateState] = useState(false)

    const handleSelect = (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      onSelect?.(e)
    }

    const handleChangeState = async (newState: any) => {
      setLoadingUpdateState(true)
      if (onChangeState) {
        onChangeState(newState)
          .then()
          .finally(() => {
            setLoadingUpdateState(false)
          })
      }
    }

    const handleSetDone = async () => {
      setLoadingUpdateState(true)
      if (onChangeState) {
        onChangeState(EObservationState.Done)
          .then()
          .finally(() => {
            setLoadingUpdateState(false)
          })
      }
    }

    const handleLinkOrUnlinkSurvey = async () => {
      const handler = observation.isLinkedToSurvey ? onClickUnLinkSurvey : onClickLinkSurvey
      setLoadingLinking(true)
      if (handler) {
        handler()
          .then()
          .finally(() => {
            setLoadingLinking(false)
          })
      }
    }

    return (
      <ListItem ref={ref} divider sx={styles.item({ isSelected, disabled })} onClick={e => !disabled && handleSelect(e)}>
        <Stack direction={'column'} flex={1} justifyContent={'left'}>
          <ListItemText
            sx={{ opacity: disabled ? 0.5 : 1, flex: 1 }}
            primary={<Typography variant="body2">{observation.observationKey ?? 'Unnamed Guide'}</Typography>}
            secondary={
              <HStack gap={1} alignItems={'center'}>
                <Typography component="span" variant="body2" color={'text.secondary'}>
                  {observation.mediaGuides?.displayName}
                </Typography>
                <Typography component="span" variant="body2" color={'text.secondary'}>
                  -
                </Typography>
                <Typography component="span" variant="body2" color={'text.secondary'}>
                  {compactDateFormat(observation.observationDate)}
                </Typography>
              </HStack>
            }
            secondaryTypographyProps={{ component: 'span' }}
          />
        </Stack>
        <HStack spacing={1} alignItems="center">
          {allowUpdateState && (
            <StateButton<EObservationState>
              state={observation.state!}
              disabledAll={loadingUpdateState}
              onChange={handleChangeState}
              markAsDone={handleSetDone}
              options={SELECT_OPTIONS.OBSERVATION_STATE_OPTIONS}
              disableTickState={EObservationState.Done}
            />
          )}
          {allowLinkOrUnlinkSurvey && (
            <IconButton
              color="default"
              loading={loadingLinking}
              sx={{ padding: 0 }}
              onClick={handleLinkOrUnlinkSurvey}
              tooltipProps={{
                title: observation.isLinkedToSurvey ? 'Unlink observation from survey' : 'Link observation to survey',
              }}
            >
              {observation.isLinkedToSurvey ? <LinkOffOutlinedIcon fontSize="small" /> : <LinkOutlinedIcon fontSize="small" />}
            </IconButton>
          )}
          {observation.state && showStateIcon && <SurveyObservationActionIcon state={observation.state as EObservationState} />}
        </HStack>
      </ListItem>
    )
  },
)
