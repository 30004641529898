import { Typography as MuiTypography, TypographyProps as TypographyLibProps, useTheme } from '@mui/material'
import { forwardRef } from 'react'

export type TypographyProps = {
  children?: React.ReactNode
  disabled?: boolean
  component?: React.ElementType
  href?: string
  target?: string
} & TypographyLibProps

const Typography = forwardRef<HTMLElement, TypographyProps>(({ children, disabled, ...rest }, ref) => {
  const { palette } = useTheme()
  const textColor = disabled ? palette.grey[400] : 'inherit'
  return (
    <MuiTypography ref={ref} color={textColor} {...rest}>
      {children}
    </MuiTypography>
  )
})

export default Typography
