import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Sidebar } from '@components/organism'
import { Typography } from '@components/atom'
import { useAuthentication } from '@context/AuthenticationContext'
import { PageLoading, TopBar } from '@components/molecule'
import { Box } from '@mui/material'
import { COLLAPSE_SIDE_BAR_WIDTH, MainWrapper, PageWrapper } from './MainLayout.style'
import useGlobalSelector from '@context/GlobalContext/hooks/useGlobalSelector'
import { useMount } from 'ahooks'
import useGlobalContext from '@context/GlobalContext/hooks/useGlobalContext'

export type MainLayoutProps = {
  children: React.ReactNode
  isBoxShadowTopBar?: boolean
}

export const MainLayout = forwardRef(({ children, isBoxShadowTopBar = false }: MainLayoutProps, ref) => {
  const { logout, logoutLoading, profile } = useAuthentication()
  const [isSideBarCollapse, setIsSideBarCollapse] = useState<boolean>(localStorage.getItem('isSideBarCollapse') === 'true')
  const isGlobalLoading = useGlobalSelector(state => state.isGlobalLoading)
  const { fetchInitData } = useGlobalContext()

  useMount(() => {
    fetchInitData()
  })

  useEffect(() => {
    localStorage.setItem('isSideBarCollapse', isSideBarCollapse.toString())
  }, [isSideBarCollapse])

  useImperativeHandle(ref, () => ({
    setSideBarCollapse: (state: boolean) => setIsSideBarCollapse(state),
  }))

  return (
    <Box sx={MainWrapper}>
      {isGlobalLoading && <PageLoading name="private-route" transparent />}
      <Sidebar isSideBarCollapse={isSideBarCollapse} />
      <Box
        sx={{
          ...PageWrapper,
          ...(isSideBarCollapse && {
            marginLeft: `${COLLAPSE_SIDE_BAR_WIDTH}px`,
          }),
        }}
      >
        <TopBar
          greetings={
            <Typography sx={{ color: 'text.primary' }}>
              Hi 👋,{' '}
              <Typography component="span" sx={{ color: 'text.primary' }} fontWeight="bold">
                {profile?.firstName}!{' '}
              </Typography>
              <Typography component="span" sx={{ color: 'text.primary' }}>
                We are so happy to see you.
              </Typography>
            </Typography>
          }
          onClickLogout={logout}
          logoutLoading={logoutLoading}
          userProfile={profile}
          isSideBarCollapse={isSideBarCollapse}
          handleToggleSideBar={setIsSideBarCollapse}
          isBoxShadow={isBoxShadowTopBar}
        />
        {children}
      </Box>
    </Box>
  )
})

export default MainLayout
