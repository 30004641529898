import { SxProps } from '@mui/material'

export const brandLogoContainer = (onClick?: () => void): SxProps => ({
  padding: '0 5px',
  gap: '10px',
  alignItems: 'center',
  cursor: onClick ? 'pointer' : 'inherit',
})

export const brandLogoTextContainer = (isFirst: boolean, lightBackground = false): SxProps => ({
  fontSize: '1.125rem',
  lineHeight: '28px',
  fontWeight: isFirst ? 600 : 400,
  color: isFirst ? (lightBackground ? 'common.black' : 'common.white') : '#8FC7D1',
})
