import { IconNote } from '@icons/index'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import Box from '../Box'
import Icons from '../Icons'
import Typography from '../Typography'
import { Anchor, StickyNoteStyled } from './StickyNoteStyled'
import Render from '../Render/Render'

export type StickyNoteProps = {
  id: string
  scale: number
  x: number
  y: number
  note: string
  onClick?: any
  onClickEdit?: any
  onDelete?: any
  allowinteract?: boolean
  collapseLabel?: boolean
}

export const StickyNote = ({
  id,
  scale,
  x,
  y,
  note,
  allowinteract = true,
  onClickEdit,
  onClick,
  onDelete,
  collapseLabel = false,
}: StickyNoteProps) => {
  const adjustedScale = scale == 1 ? scale : 1 / scale

  return (
    <Anchor x={x} y={y}>
      <Box position="relative">
        <StickyNoteStyled scale={adjustedScale} onClick={onClick} {...(allowinteract ? { allowinteract } : {})}>
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              borderColor: 'white',
              borderWidth: 2,
              borderStyle: 'solid',
              backgroundColor: 'purple.700',
              position: 'absolute',
              bottom: -4,
              left: -4,
            }}
          />
          <div className="note-container">
            <Box mt="4px" mb="4px">
              <Icons Icon={IconNote} width={12} height={12} color="purple.700" />
            </Box>
            <Render test={!collapseLabel}>
              <Typography>{note}</Typography>
            </Render>
          </div>
          <Render test={!collapseLabel}>
            <div className="actions">
              <IconButton
                className="icon-button"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!allowinteract) return
                  onClickEdit(id)
                }}
              >
                <EditOutlinedIcon sx={{ width: 16, height: 16 }} />
              </IconButton>
              <IconButton
                className="icon-button"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!allowinteract) return
                  onDelete(id)
                }}
              >
                <DeleteIcon sx={{ width: 16, height: 16, color: 'red' }} />
              </IconButton>
            </div>
          </Render>
        </StickyNoteStyled>
      </Box>
    </Anchor>
  )
}

export default StickyNote
