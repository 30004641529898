import { Typography } from '@components/atom'
import { styled, SxProps } from '@mui/material'

export const CardDescription = styled(Typography)`
  width: 100%;
  max-height: 80px;
  word-break: break-word;
  text-align: left;
  overflow-y: auto;
`
export const cardBase = {
  ':hover': { backgroundColor: 'grey.100' },
  cursor: 'pointer',
  width: '100%',
}

export const card: SxProps = {
  borderColor: 'divider',
  ...cardBase,
}

export const cardActive: SxProps = {
  ...cardBase,
  border: '2px solid',
  borderColor: 'info.main',
  backgroundColor: 'info.light',
  '&:hover': {
    backgroundColor: 'info.light',
  },
}

export const radio: SxProps = {
  width: '24px',
  height: '24px',
  '&.MuiRadio-root': {
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  '&.Mui-checked': {
    color: 'info.main',
  },
}

export const controlLabelItem: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'start',
  borderRadius: '4px',
  gap: 0.5,
  margin: 0,
  marginBottom: 1,
  '.MuiFormControlLabel-label .MuiCardContent-root': {
    paddingBottom: 0,
  },
}

export const title: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}

export const descriptions: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
}
