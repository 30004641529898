import { useTheme } from '@mui/material'
import React from 'react'

import MapThumnail from '@assets/images/map-thumnail.png'
import { Box, HStack, Typography, VStack } from '@components/atom'

import * as S from './ProjectCard.style'

export interface IProjectCardProps {
  title: string
  projectKey: string
  numPlans: number
  numObservations: number
  imageSrc: string
  imageAlt?: string
  onClickCard?: () => void
  showDefaultImage?: boolean // TODO: Feature Flag
}

const ProjectCard: React.FC<IProjectCardProps> = ({
  title,
  projectKey,
  numPlans,
  numObservations,
  imageSrc,
  imageAlt,
  onClickCard,
  showDefaultImage = false,
}) => {
  const { palette } = useTheme()
  return (
    <S.ProjectCardContainer onClick={onClickCard} variant="outlined">
      {showDefaultImage && (
        <S.ImageContainer>
          <S.ProjectImage src={imageSrc || MapThumnail} alt={imageAlt ?? ''} loading="lazy" />
        </S.ImageContainer>
      )}
      <VStack padding={2} gap={4} width={'100%'}>
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'subtitle1'} fontWeight={'700'}>
            {title}
          </Typography>
        </HStack>
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <Box gap={'3px'}>
            <Typography variant="body2" color={palette.text.secondary}>
              Key
            </Typography>
            <Typography variant="body1">{projectKey}</Typography>
          </Box>
          <Box gap={'3px'}>
            <Typography variant="body2" color={palette.text.secondary}>
              Plans
            </Typography>
            <Typography variant="body1">{numPlans}</Typography>
          </Box>
          <Box gap={'3px'}>
            <Typography variant="body2" color={palette.text.secondary}>
              Observations
            </Typography>
            <Typography variant="body1">{numObservations}</Typography>
          </Box>
        </HStack>
      </VStack>
    </S.ProjectCardContainer>
  )
}

export default React.memo(ProjectCard)
