import { IBaseResponse } from '@models/common.model'
import { ESurveyState, ISurvey } from '@models/survey.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import { ICreateNewSurvey, IUpdateSurvey } from './SurveyService.model'

export class SurveyService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchSurveys = async ({ projectId, planId }: { projectId: string; planId: string }): Promise<IBaseResponse<ISurvey[]>> => {
    try {
      let mapFeatureIds: string[] = []

      if (planId) {
        const { data: mapFeatures, error: mapFeaturesError } = await this.client
          .from('map_features')
          .select('id')
          .eq('plan_id', planId)
          .is('deleted_at', null)

        if (mapFeaturesError) {
          return { data: null, error: mapFeaturesError }
        }

        mapFeatureIds = mapFeatures.map((feature: { id: string }) => feature.id)
      }

      let query = this.client
        .from('surveys')
        .select('id, state, map_features(id, plot_key, geometry), start_date, end_date, project_id, created_at, updated_at')
        .eq('project_id', projectId)
        .is('deleted_at', null)

      if (mapFeatureIds.length > 0) {
        query = query.in('map_feature_id', mapFeatureIds)
      }

      const { data, error } = await query

      if (error) {
        return { data: null, error: error }
      }

      return {
        data: this.toCamelCase(data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchSurveyDetail = async ({ surveyId }: { surveyId: string }): Promise<IBaseResponse<ISurvey>> => {
    try {
      let query = this.client
        .from('surveys')
        .select('id, state, map_features(plot_key, geometry, id), start_date, end_date, project_id')
        .eq('id', surveyId)
        .single()

      const { data, error } = await query

      if (error) {
        return { data: null, error: error }
      }

      return {
        data: this.toCamelCase(data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  updateSurveyState = async ({ id, state }: { id: string; state: ESurveyState }): Promise<IBaseResponse<ISurvey>> => {
    try {
      const propsToUpdate: any = { state }
      if (state === ESurveyState.Done) {
        // Also set user id and completed_at date
        const user = await this.client.auth.getUser()
        propsToUpdate.completed_by = user?.data.user?.id
        propsToUpdate.completed_at = new Date().toISOString()
      }
      const query = this.client
        .from('surveys')
        .update(propsToUpdate)
        .eq('id', id)
        .select('id, state, map_features(plot_key, geometry, id), start_date, end_date, project_id')
        .single()

      const { data, error } = await query

      if (error) {
        return { data: null, error: error }
      }

      return {
        data: this.toCamelCase(data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  create = async (request: ICreateNewSurvey): Promise<IBaseResponse<ISurvey>> => {
    try {
      const user = await this.client.auth.getUser()
      const payload = {
        project_id: request.projectId,
        map_feature_id: request.mapFeatureId,
        start_date: request.startDate,
        end_date: request.endDate,
        user_id: user?.data.user?.id,
      }
      const query = this.client
        .from('surveys')
        .insert(payload)
        .select('id, state, map_features(plot_key, geometry, id), start_date, end_date, project_id')
        .single()
      const { data, error } = await query

      if (error) {
        return { data: null, error: error }
      }

      return {
        data: this.toCamelCase(data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  updateSurveyDates = async (request: IUpdateSurvey): Promise<IBaseResponse<ISurvey>> => {
    try {
      const payload: any = {
        start_date: request.startDate,
        end_date: request.endDate,
      }
      const query = this.client
        .from('surveys')
        .update(payload)
        .eq('id', request.id)
        .select('id, state, map_features(plot_key, geometry, id), start_date, end_date, project_id')
        .single()

      const { data, error } = await query

      if (error) {
        return { data: null, error }
      }

      return {
        data: this.toCamelCase(data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  delete = async (id: string): Promise<IBaseResponse<ISurvey>> => {
    try {
      const user = await this.client.auth.getUser()

      const payload: any = {
        deleted_at: new Date().toISOString(),
        user_id: user?.data.user?.id,
      }
      const query = this.client.from('surveys').update(payload).eq('id', id)
      const { error } = await query

      if (error) {
        return { data: null, error }
      }

      return { data: null, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

const surveyService = new SurveyService()

export default surveyService
