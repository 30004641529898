import type { SVGProps } from 'react'
const SvgIconPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="m1.423 14.09-.9 3.054-.484 1.65a.939.939 0 0 0 1.161 1.17l1.654-.49 3.054-.899a3.4 3.4 0 0 0 1.463-.868l11.896-11.89a2.503 2.503 0 0 0 0-3.54L17.726.734a2.5 2.5 0 0 0-3.54 0L2.292 12.628a3.47 3.47 0 0 0-.868 1.462m1.799.528c.066-.219.176-.422.328-.594.024-.023.043-.047.067-.07l8.935-8.931 2.425 2.424-8.932 8.935a1.6 1.6 0 0 1-.664.395l-.916.27-2.142.63.63-2.144z"
    />
  </svg>
)
export default SvgIconPencil
