import { IBreadcrumbItem } from './Breadcrumb.type'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { BreadcrumbTitle } from './Breadcrumb.style'

const AppBreadcrumb = ({ items }: { items: IBreadcrumbItem[] }) => {
  return (
    <Breadcrumbs>
      {items.map((item, index) => {
        return (
          <BreadcrumbTitle key={index} onClick={item.onNavigate} isLastIndex={index === items.length - 1}>
            {item.title}
          </BreadcrumbTitle>
        )
      })}
    </Breadcrumbs>
  )
}
export default AppBreadcrumb
