import _ from 'lodash'
import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'

import { IPlan } from '@models/plan.model'
import logger from '@utils/logger'
import { convertCheckpointToGeoJson, convertFeaturesToGeoJson, convertProjectBoundaryToGeoJson } from '@utils/map.utils'

import { ACTIONS, PROJECTION } from './ProjectDetailContext.const'
import { TProjectDetailState } from './ProjectDetailState'
import { displayAllFeatureInSource } from '@utils/open-layer'

function ProjectDetailReducer(state: TProjectDetailState, action: any): TProjectDetailState {
  switch (action.type) {
    case ACTIONS.SET_PROJECT_DETAIL: {
      const { projectDetail } = action.payload

      if (projectDetail.boundary) {
        const geoJson = convertProjectBoundaryToGeoJson(action.payload.projectDetail)
        if (!geoJson) {
          logger.error('Failed to convert project boundary to GeoJSON')
          return { ...state, projectDetail }
        }
        const projectBoundaryVectorSource = new VectorSource({
          features: new GeoJSON().readFeatures(geoJson, {
            featureProjection: PROJECTION.WEB_MERCATOR,
          }),
        })

        return { ...state, projectDetail, projectBoundaryVectorSource }
      }

      state.projectBoundaryVectorSource?.clear()

      return { ...state, projectDetail }
    }
    case ACTIONS.SET_LIST_SURVEYS: {
      if (_.isEmpty(action.payload.surveys)) return state
      return { ...state, surveys: action.payload.surveys }
    }
    case ACTIONS.SET_FEATURE_SOURCE: {
      if (_.isEmpty(action.payload.features)) {
        return { ...state, surveyListSource: new VectorSource() }
      }
      const geoJson = convertFeaturesToGeoJson(action.payload.features)

      // Update features on map rather than create new source
      if (state.surveyListSource) {
        state.surveyListSource?.clear()
        state.surveyListSource?.addFeatures(new GeoJSON().readFeatures(geoJson, { featureProjection: PROJECTION.WEB_MERCATOR }))
        displayAllFeatureInSource(state.surveyListSource)
        return { ...state, mapFeatures: action.payload.features }
      }

      const surveyListSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson, {
          featureProjection: PROJECTION.WEB_MERCATOR,
        }),
      })

      return { ...state, mapFeatures: action.payload.features, surveyListSource }
    }
    case ACTIONS.SELECT_TILE_LAYER: {
      return { ...state, selectedTileLayer: action.payload.selectedTileLayer }
    }
    case ACTIONS.SET_PROJECT_SURVEY_CHECKPOINTS: {
      const { projectSurveyCheckpoints, surveyId, selectedCheckpointId } = action.payload
      if (_.isEmpty(projectSurveyCheckpoints)) return state

      const geoJson = convertCheckpointToGeoJson(projectSurveyCheckpoints, selectedCheckpointId)
      const vectorSource = state.checkpointsSource?.[surveyId]

      if (vectorSource) {
        vectorSource.clear()
        vectorSource.addFeatures(
          new GeoJSON().readFeatures(geoJson, {
            featureProjection: PROJECTION.WEB_MERCATOR,
          }),
        )
        return {
          ...state,
          projectSurveyCheckpoints: {
            ...state.projectSurveyCheckpoints,
            [surveyId]: projectSurveyCheckpoints,
          },
        }
      }
      const checkpointsSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson, {
          featureProjection: PROJECTION.WEB_MERCATOR,
        }),
      })
      return {
        ...state,
        checkpointsSource: {
          ...state.checkpointsSource,
          [action.payload.surveyId]: checkpointsSource,
        },
        projectSurveyCheckpoints: {
          ...state.projectSurveyCheckpoints,
          [action.payload.surveyId]: projectSurveyCheckpoints,
        },
      }
    }
    case ACTIONS.SET_METRICS: {
      return { ...state, metrics: action.payload.metrics }
    }
    case ACTIONS.SET_OBSERVATIONS: {
      return {
        ...state,
        observations: {
          ...state.observations,
          [action.package.surveyId]: action.package.observations,
        },
      }
    }
    case ACTIONS.SET_MAP_RENDER_COMPLETE: {
      return {
        ...state,
        mapState: {
          ...state.mapState,
          initialized: action.payload.initialized,
        },
      }
    }
    case ACTIONS.SET_SELECTED_TILE: {
      const { selectedTileLayer } = action.payload
      return {
        ...state,
        selectedTileLayer,
      }
    }
    case ACTIONS.SET_TILE_LAYERS: {
      return {
        ...state,
        tileLayers: action.payload.tileLayers,
      }
    }
    case ACTIONS.SET_EVENTS: {
      return {
        ...state,
        events: {
          ...state.events,
          ...action.payload,
        },
      }
    }
    case ACTIONS.SET_PLANS: {
      const { plans } = action.payload
      if (_.isEmpty(plans)) return state
      const latestPlan = _.maxBy(plans, (item: IPlan) => new Date(item.createdAt))?.id
      return {
        ...state,
        plans: action.payload.plans ?? [],
        selectedPlanId: latestPlan,
      }
    }
    case ACTIONS.SELECT_PLAN: {
      return {
        ...state,
        selectedPlanId: action.payload.selectedPlanId,
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

export default ProjectDetailReducer
