import { FormControl, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React from 'react'

export type DatePickerProps = {
  error?: boolean
  label?: string
  helperText?: string | null | undefined
  size?: 'small' | 'medium'
  placeholder: string
} & MuiDatePickerProps<any, any>

export const DatePicker = React.forwardRef<HTMLDivElement, DatePickerProps>(
  ({ error, size, placeholder = 'hello', helperText, ...rest }, ref) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl fullWidth>
          <MuiDatePicker
            {...rest}
            inputFormat="DD/MM/YYYY"
            mask="__/__/____"
            renderInput={params => <TextField {...params} helperText={helperText} error={error} size={size} />}
            ref={ref as React.Ref<any>} // Truyền ref vào component con nếu cần
          />
        </FormControl>
      </LocalizationProvider>
    )
  },
)

DatePicker.displayName = 'DatePicker'

export default DatePicker
