import { useParams, useSearchParams } from 'react-router-dom'

export type URLParamKey = 'projectId' | 'surveyId' | 'checkpointId' | 'observationId' | 'mediaId'

/**
 * This hook use to get params from url, provided function to update params
 */
export const useAppParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { projectId = '' } = useParams<{ projectId?: string }>()

  const reportId = searchParams.get('reportId') ?? ''
  const surveyId = searchParams.get('surveyId') ?? ''
  const checkpointId = searchParams.get('checkpointId') ?? ''
  const observationId = searchParams.get('observationId') ?? ''
  const mediaId = searchParams.get('mediaId') ?? ''
  const planId = searchParams.get('planId') ?? ''

  const updateUrlParam = (param: URLParamKey, value: string) => {
    searchParams.set(param, value)
    setSearchParams(searchParams)
  }

  /**
   * TODO:Tech-debt Sometime the surveyId empty in useQuery if get from state. therefore we use this function instead
   */
  const getParams = (name: 'surveyId' | 'checkpointId' | 'observationId' | 'mediaId' | 'planId' | 'id') => {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get(name)
  }

  return {
    projectId,
    reportId,
    surveyId,
    checkpointId,
    observationId,
    mediaId,
    planId,
    updateUrlParam,
    getParams,
    searchParams,
  }
}

export default useAppParams
