import { useContext, useMemo } from 'react'
import GlobalContext from '../GlobalContext'
import { TGlobalState } from '../GlobalState'
import { IProject } from '@models/projects.model'
import { IOrganisation } from '@models/organisation.model'
import { IUserRoleMapping } from '@models/userRole.model'

export const selectOrganisationList = (projectList: IProject[], userRoleMappings?: IUserRoleMapping | null): IOrganisation[] => {
  if (!userRoleMappings || projectList.length === 0) return []

  // Group projects by organisation ID and map organisations
  const projectGroupByOrgId = new Map<string, IProject[]>()
  const organisationsMap = new Map<string, IOrganisation>()

  for (const project of projectList) {
    const { organisation } = project
    if (!organisation) continue
    if (!organisationsMap.has(organisation.id)) {
      organisationsMap.set(organisation.id, organisation)
      projectGroupByOrgId.set(organisation.id, [project])
    } else {
      projectGroupByOrgId.get(organisation.id)!.push(project)
    }
  }

  // Filter organisations based on user roles
  const organisationList = Array.from(organisationsMap.entries())
    .filter(([orgId]) => {
      const isMemberOfOrg = userRoleMappings.organisationRoles[orgId] === 'member'
      const projectsOfOrg = projectGroupByOrgId.get(orgId) || []
      const isMemberOfSomeProject = projectsOfOrg.some(project => userRoleMappings.projectRoles[project.id] === 'member')
      return isMemberOfOrg || isMemberOfSomeProject
    })
    .map(([_, organisation]) => organisation)

  return organisationList
}

export const selectProjectListOfCurrentOrganisation = (state: TGlobalState) => {
  const { projectList, selectedOrganisationId, userRoleMappings } = state
  if (!userRoleMappings || !selectedOrganisationId || !projectList.length) return []
  const allProjectOfOrg = projectList.filter(project => project.organisation_id === selectedOrganisationId)

  if (userRoleMappings.organisationRoles[selectedOrganisationId] === 'member') {
    return allProjectOfOrg
  } else {
    return allProjectOfOrg.filter(project => userRoleMappings.projectRoles[project.id] === 'member')
  }
}

export const selectCurrentOrganisation = (state: TGlobalState) => {
  const organisations = selectOrganisationList(state.projectList, state.userRoleMappings)
  const { selectedOrganisationId, userRoleMappings } = state

  if (!selectedOrganisationId || !userRoleMappings) return undefined

  const role = userRoleMappings.organisationRoles[selectedOrganisationId]
  if (!role || role === 'collector') return undefined

  return organisations.find(org => org.id === selectedOrganisationId)
}

const useGlobalSelector = <T>(selector: (state: TGlobalState) => T): T => {
  const context = useContext(GlobalContext)

  if (!context) {
    throw new Error('useGlobalSelector must be used within a GlobalProvider')
  }

  const { state } = context

  return useMemo(() => selector(state), [state, selector])
}

export default useGlobalSelector
