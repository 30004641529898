import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiChip: {
  defaultProps: ComponentsProps['MuiChip']
  styleOverrides: ComponentsOverrides<Theme>['MuiChip']
} = {
  defaultProps: {},
  styleOverrides: {
    sizeMedium: () => ({
      height: '24px',
    }),
  },
}

export default MuiChip
