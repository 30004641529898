import { Box, Typography } from '@components/atom'
import { Card, List, ListItem, Skeleton } from '@mui/material'
import { memo, useEffect, useRef } from 'react'

import { ObservationItem } from '@components/molecule/ObservationItem/ObservationItem'
import { EObservationState, IObservation } from '@models/observation.model'
import * as styles from './ObservationList.styles'

export type ObservationListProps = {
  title: string
  maxOptionsDisplay?: number
  observations: IObservation[]
  isLoading: boolean
  selectedObservationId?: string
  onSelect?: (item: IObservation) => void
  onChangeObservationState?: ({ observationId, state }: { observationId: string; state: EObservationState }) => Promise<any>
  onClickLinkSurvey?: (id: string) => Promise<any>
  onClickUnLinkSurvey?: (id: string) => Promise<any>
  onClickSelect?: (id: string) => void
}

export const ObservationList = ({
  title,
  maxOptionsDisplay,
  observations,
  isLoading,
  onChangeObservationState,
  onClickLinkSurvey,
  onClickUnLinkSurvey,
  selectedObservationId,
  onClickSelect,
}: ObservationListProps) => {
  const selectedRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [selectedObservationId])

  return (
    <Card variant="outlined" sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="body1">{title}</Typography>
      </Box>
      <List sx={styles.list(maxOptionsDisplay)}>
        {isLoading &&
          Array.from({ length: maxOptionsDisplay ?? 1 }).map((_, index) => (
            <ListItem key={index} divider sx={styles.skeletonWrapper}>
              <Skeleton height={56} width="100%" />
            </ListItem>
          ))}
        {!isLoading &&
          observations.map(observation => {
            const isSelected = selectedObservationId === observation.id
            return (
              <ObservationItem
                ref={isSelected ? selectedRef : null}
                key={observation.id}
                allowUpdateState={observation.isLinkedToSurvey}
                allowLinkOrUnlinkSurvey
                observation={observation}
                isSelected={isSelected}
                onSelect={() => {
                  if (selectedObservationId != observation.id) {
                    onClickSelect?.(observation.id)
                  }
                }}
                onChangeState={state =>
                  onChangeObservationState ? onChangeObservationState({ observationId: observation.id, state }) : Promise.resolve()
                }
                onClickLinkSurvey={() => (onClickLinkSurvey ? onClickLinkSurvey(observation.id) : Promise.resolve())}
                onClickUnLinkSurvey={() => (onClickUnLinkSurvey ? onClickUnLinkSurvey(observation.id) : Promise.resolve())}
              />
            )
          })}
      </List>
    </Card>
  )
}

export default memo(ObservationList)
