import { ComponentsOverrides, Theme } from '@mui/material'

const MuiFormControl: {
  styleOverrides: ComponentsOverrides<Theme>['MuiFormControl']
} = {
  styleOverrides: {
    root: () => ({}),
  },
}

export default MuiFormControl
