import React, { memo, useMemo, useState } from 'react'
import _get from 'lodash/get'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'

import {
  Avatar,
  Box,
  Button,
  HStack,
  IconButton,
  Icons,
  LinearProgress,
  Render,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
  Typography,
  VStack,
} from '@components/atom'
import EachComponent from '@components/atom/ForEachComponent/ForEachComponent'
import UserRoleChip from '@components/molecule/UserRoleChip'
import useGlobalSelector, { selectProjectListOfCurrentOrganisation } from '@context/GlobalContext/hooks/useGlobalSelector'
import { ArrowLeft, IconBan, IconMore, IconPencil, IconTrash } from '@icons/index'
import { Profile } from '@models/profile.models'
import { checkAssignRolePermission, handleCheckAbleToSeeOrgData } from '@utils/organisation.utils'

import { PaperMenuPopup, sectionTableCell } from './OrganisationAndProjectTable.style'

export type OrganisationTableProps = {
  searchResults: any
  profile?: Profile
}

export type OrganisationAndProjectTableProps<T> = {
  searchResults: any
  profile?: Profile
  onItemClick: (item: T | any) => void
  onChangeRoleClick?: (item: T | any) => void
  onAssignHigherRoleClick?: (item: T | any) => void
  onClickRemoveUserRole?: (item: T | any) => void
  onClickRemoveUser?: (item: T | any) => void
  sx?: TableContainerProps['sx']
  loading?: boolean
  handleInviteUser: () => void
  selectedProjectId: string | null
}

const OrganisationAndProjectTable = memo(
  <T,>({
    searchResults,
    profile,
    onItemClick,
    onChangeRoleClick,
    onAssignHigherRoleClick,
    onClickRemoveUserRole,
    onClickRemoveUser,
    sx,
    loading,
    handleInviteUser,
    selectedProjectId,
  }: OrganisationAndProjectTableProps<T>) => {
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null)
    const projectList = useGlobalSelector(selectProjectListOfCurrentOrganisation)
    const userRoleMappings = useGlobalSelector(state => state.userRoleMappings)

    const selectedProject = useMemo(() => projectList?.find(project => project.id === selectedProjectId), [projectList])
    const isAbleToSeeOrgData = handleCheckAbleToSeeOrgData(selectedProject, userRoleMappings)

    const organisationUsers = React.useMemo(() => {
      const users = searchResults?.filter((user: any) => user.organisationRole !== null)
      if (sortConfig) {
        return [...users].sort((a, b) => {
          const aValue = _get(a, sortConfig.key, '') || ''
          const bValue = _get(b, sortConfig.key, '') || ''
          if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1
          if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1
          return 0
        })
      }
      return [...users].sort((a, b) => (a.firstName || 'dummy').localeCompare(b.firstName || 'dummy'))
    }, [searchResults, sortConfig])

    const projectUsers = React.useMemo(() => {
      const users = searchResults?.filter((user: any) => user.organisationRole === null)
      if (sortConfig) {
        return [...users].sort((a, b) => {
          const aValue = _get(a, sortConfig.key, '') || ''
          const bValue = _get(b, sortConfig.key, '') || ''
          if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1
          if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1
          return 0
        })
      }
      return [...users].sort((a, b) => (a.firstName || 'dummy').localeCompare(b.firstName || 'dummy'))
    }, [searchResults, sortConfig])

    const handleSort = (key: string) => {
      if (sortConfig?.key === key) {
        if (sortConfig.direction === 'asc') {
          setSortConfig({ key, direction: 'desc' })
        } else if (sortConfig.direction === 'desc') {
          setSortConfig(null) // Remove sorting
        } else {
          setSortConfig({ key, direction: 'asc' })
        }
      } else {
        setSortConfig({ key, direction: 'asc' })
      }
    }

    const [menuState, setMenuState] = useState<{ anchorEl: null | HTMLElement; selectedRowId: string | null }>({
      anchorEl: null,
      selectedRowId: null,
    })

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, rowId: string) => {
      setMenuState({ anchorEl: event.currentTarget, selectedRowId: rowId })
    }

    const handleClose = () => {
      setMenuState({ anchorEl: null, selectedRowId: null })
    }

    const handleClickDeleteRole = (id: string) => {
      onClickRemoveUserRole?.(id)
      handleClose()
    }

    const handleRemoveUser = (id: string) => {
      onClickRemoveUser?.(id)
      handleClose()
    }

    const columns = [
      {
        key: 'firstName',
        displayName: 'Full name',
        sortable: true,
        renderCell: ({ row }: any) => {
          const isMyself = row.id === profile?.id
          return (
            <TableCell>
              <HStack spacing={1} alignItems="center">
                <Avatar avatarVariant="first-character" name={row.firstName || 'Default'} />
                <Typography>
                  {row.firstName} {row.lastName}{' '}
                  {isMyself && (
                    <Typography component="span" color="text.secondary">
                      (You)
                    </Typography>
                  )}
                </Typography>
              </HStack>
            </TableCell>
          )
        },
      },
      {
        key: 'email',
        displayName: 'Email address',
        sortable: true,
        renderCell: ({ row }: any) => {
          return (
            <TableCell>
              {isAbleToSeeOrgData || !row.organisationRole ? (
                <Typography>{row.email}</Typography>
              ) : (
                <Tooltip title={'This information is hidden'} placement="top" arrow>
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Icons Icon={IconBan} color="grey.500" />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          )
        },
      },
      {
        key: 'organisationRole',
        displayName: 'Organisation role',
        renderCell: ({ row }: any) => {
          return (
            <TableCell>
              {row.organisationRole ? (
                <UserRoleChip role={row.organisationRole} />
              ) : (
                <Tooltip title={'Organisation role is not available for this project user'} placement="top" arrow>
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Icons Icon={IconBan} color="grey.500" />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          )
        },
      },
      {
        key: 'projectRole',
        displayName: 'Project role',
        renderCell: ({ row }: any) => {
          const permission = checkAssignRolePermission(row.organisationRole, row.projectRole)
          const isMyself = row.id === profile?.id
          if (isMyself) {
            return (
              <TableCell>
                <UserRoleChip role={row.projectRole} showIcon={false} />
              </TableCell>
            )
          }
          switch (permission) {
            case 'HIGHEST':
              return (
                <TableCell>
                  <Tooltip
                    title={
                      'User roles have hierarchy. Higher level roles have all permissions of lower level roles. You cannot assign a project role to this user because this user inherits the highest role from the organisation level.'
                    }
                    placement="top"
                    arrow
                  >
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Icons Icon={IconBan} color="grey.500" />
                    </span>
                  </Tooltip>
                </TableCell>
              )
            case 'ASSIGN_HIGHER':
              return (
                <TableCell>
                  <Button sx={{ textAlign: 'left' }} variant="text" onClick={() => onAssignHigherRoleClick?.(row)}>
                    Assign higher role
                  </Button>
                </TableCell>
              )
            case 'DELETE':
              return (
                <TableCell>
                  <UserRoleChip role={row.projectRole} onDelete={() => handleClickDeleteRole(row.id)} isDelete showIcon={false} />
                </TableCell>
              )
            default:
              return (
                <TableCell>
                  <UserRoleChip role={row.projectRole} showIcon={false} />
                </TableCell>
              )
          }
        },
      },
      {
        key: 'action',
        displayName: '',
        renderCell: ({ row }: any) => {
          const isMyself = row.id === profile?.id
          if (isMyself || row.organisationRole) return <TableCell />
          return (
            <TableCell sx={{ textAlign: 'end' }}>
              <IconButton onClick={event => handleClick(event, row.id)}>
                <Icons Icon={IconMore} width={16} height={16} />
              </IconButton>
              <Menu
                sx={{ boxShadow: 'none' }}
                anchorEl={menuState.anchorEl}
                open={Boolean(menuState.anchorEl) && menuState.selectedRowId === row.id}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                slotProps={{
                  paper: {
                    sx: PaperMenuPopup,
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    onChangeRoleClick?.(row)
                    handleClose()
                  }}
                >
                  <HStack spacing={1} padding="8px 0">
                    <Icons Icon={IconPencil} width={20} height={20} color="common.black" />
                    <Typography>Change role</Typography>
                  </HStack>
                </MenuItem>
                {row.id !== profile?.id && (
                  <MenuItem onClick={() => handleRemoveUser(row.id)}>
                    <HStack spacing={1} padding="8px 0">
                      <Icons Icon={IconTrash} width={20} height={20} color="error.main" />
                      <Typography color="error.main">Remove user</Typography>
                    </HStack>
                  </MenuItem>
                )}
              </Menu>
            </TableCell>
          )
        },
      },
    ]

    return (
      <TableContainer sx={sx}>
        {loading && <LinearProgress color="info" />}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {Object.values(columns).map((column, i) => (
                <React.Fragment key={i}>
                  <TableCell
                    key={i}
                    sx={{
                      paddingY: '8px',
                      fontWeight: '600',
                      position: 'sticky',
                      top: 0,
                      cursor: column.sortable ? 'pointer' : 'default',
                    }}
                    onClick={() => column.sortable && handleSort(column.key)}
                  >
                    <HStack alignItems="center" spacing={2}>
                      {column.displayName}
                      {column.sortable && sortConfig?.key === column.key && (
                        <Box>
                          {sortConfig.direction === 'asc' ? (
                            <Icons Icon={ArrowLeft} sx={{ transform: 'rotate(90deg)' }} width={16} height={16} />
                          ) : sortConfig.direction === 'desc' ? (
                            <Icons Icon={ArrowLeft} sx={{ transform: 'rotate(270deg)' }} width={16} height={16} />
                          ) : (
                            ''
                          )}
                        </Box>
                      )}
                    </HStack>
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length} sx={sectionTableCell(true)}>
                <Box sx={{ padding: 2 }}>
                  <Typography color="text.secondary" fontWeight={700}>
                    Organisation users ({organisationUsers?.length || 0})
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <Render test={organisationUsers && organisationUsers.length > 0}>
              <EachComponent
                of={organisationUsers}
                render={(row, i) => (
                  <TableRow key={i} onClick={() => onItemClick(row)}>
                    {columns.map(column => (
                      <React.Fragment key={column.key}>
                        {column.renderCell ? (
                          column.renderCell({
                            row,
                            data: organisationUsers,
                          })
                        ) : (
                          <TableCell {...column} key={column.key}>
                            {_get(row, column.key, '') as React.ReactNode}
                          </TableCell>
                        )}
                      </React.Fragment>
                    ))}
                  </TableRow>
                )}
              />
            </Render>
            <Render test={!organisationUsers || organisationUsers.length === 0}>
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <VStack alignItems="center" justifyContent="center">
                    <Typography color="text.secondary" sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                      This organisation has no user yet.
                    </Typography>
                  </VStack>
                </TableCell>
              </TableRow>
            </Render>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length} sx={sectionTableCell(false)}>
                <Box sx={{ padding: 2 }}>
                  <Typography color="text.secondary" fontWeight={700}>
                    Project users ({projectUsers?.length || 0})
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <Render test={projectUsers && projectUsers.length > 0}>
              <EachComponent
                of={projectUsers}
                render={(row, i) => (
                  <TableRow key={i} onClick={() => onItemClick(row)}>
                    {columns.map(column => (
                      <React.Fragment key={column.key}>
                        {column.renderCell ? (
                          column.renderCell({
                            row,
                            data: projectUsers,
                          })
                        ) : (
                          <TableCell {...column} key={column.key}>
                            {_get(row, column.key, '') as React.ReactNode}
                          </TableCell>
                        )}
                      </React.Fragment>
                    ))}
                  </TableRow>
                )}
              />
            </Render>
            <Render test={!projectUsers || projectUsers.length === 0}>
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <VStack alignItems="center" justifyContent="center">
                    <Typography color="text.secondary" display="inline" sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                      This project has no user yet.{' '}
                      <Button variant="text" onClick={handleInviteUser}>
                        Invite users
                      </Button>{' '}
                      to contribute to project
                    </Typography>
                  </VStack>
                </TableCell>
              </TableRow>
            </Render>
          </TableBody>
        </Table>
      </TableContainer>
    )
  },
)

export default OrganisationAndProjectTable
