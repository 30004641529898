const ENV = {
  ENV: import.meta.env.VITE_ENV,
  SUPABASE_URL: import.meta.env.VITE_SUPABASE_URL,
  SUPABASE_KEY: import.meta.env.VITE_SUPABASE_KEY,
  MAP_STYLE: import.meta.env.VITE_MAP_STYLE,
  MAP_KEY: import.meta.env.VITE_MAP_KEY,
  USERBACK_KEY: import.meta.env.VITE_USERBACK_KEY,
  LABEL_SET_PLANT_SPECIES_ID: import.meta.env.VITE_LABEL_SET_PLANT_SPECIES_ID,
  APP_VERSION: import.meta.env.VITE_APP_VERSION,
  BUILD_DATETIME: import.meta.env.VITE_BUILD_DATETIME,
  // Analytics environment variables that live in vercel
  FIREBASE_API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

export default ENV
