import logger from '@utils/logger'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import { TVerifyOtpResponseSuccess } from './AuthService.models'
import { Session, User, VerifyEmailOtpParams } from '@supabase/supabase-js'
import { SupabaseAuthClient } from '@supabase/supabase-js/dist/module/lib/SupabaseAuthClient'

export class AuthService extends MozaicSupebaseClient {
  auth: SupabaseAuthClient

  constructor() {
    super()
    this.auth = this.client.auth
  }

  loginWithEmailOtp = async (email: string): Promise<any> => {
    const { error } = await this.client.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: true,
      },
    })
    if (error) {
      throw new Error(`Login failed: ${error.message}`)
    }
    logger.info(`OTP sent to email: ${email}`)
    return { message: 'OTP sent to email' }
  }

  verifyOtp = async ({
    email,
    otp,
    options = {},
  }: {
    email: string
    otp: string
    options?: VerifyEmailOtpParams['options']
  }): Promise<TVerifyOtpResponseSuccess> => {
    const { error, data } = await this.client.auth.verifyOtp({
      email,
      token: otp,
      type: 'email',
      options,
    })
    if (error) {
      throw new Error(`OTP verification failed: ${error.message}`)
    }
    logger.info(`Verify otp success: ${otp}`)
    return data
  }

  getCurrentUser = async (): Promise<User | null> => {
    const {
      data: { user },
    } = await this.client.auth.getUser()
    return user
  }

  isLoggedIn = async (): Promise<Session | null> => {
    const {
      data: { session },
    } = await this.client.auth.getSession()
    return session
  }

  logout = async (): Promise<void> => {
    const { error } = await this.client.auth.signOut()
    if (error) {
      throw new Error(`Logout failed: ${error.message}`)
    }
    logger.info('User logged out successfully')
  }
}

// Singleton pattern
const authService = new AuthService()

export default authService
