import type { SVGProps } from 'react'
const SvgIconOpenSideBar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 18" {...props}>
    <path
      fill="currentColor"
      d="M17.5 0h-15C1.121 0 0 1.153 0 2.571V15.43C0 16.847 1.121 18 2.5 18h15c1.379 0 2.5-1.153 2.5-2.571V2.57C20 1.153 18.879 0 17.5 0M5.313 12.214H3.438a.95.95 0 0 1-.938-.964c0-.534.418-.964.938-.964h1.874c.52 0 .938.43.938.964a.95.95 0 0 1-.937.964m0-3.857H3.438a.95.95 0 0 1-.938-.964c0-.535.418-.964.938-.964h1.874c.52 0 .938.43.938.964a.95.95 0 0 1-.937.964m0-3.857H3.438a.95.95 0 0 1-.938-.964c0-.535.418-.965.938-.965h1.874c.52 0 .938.43.938.965a.95.95 0 0 1-.937.964M17.5 16.071H8.75V1.93h8.75c.344 0 .625.289.625.642v5.467h-3.981l1.644-1.54a.985.985 0 0 0 .063-1.361.92.92 0 0 0-1.324-.064v-.004l-3.438 3.214a.984.984 0 0 0 0 1.43l3.438 3.215a.92.92 0 0 0 1.324-.065.985.985 0 0 0-.063-1.362l-1.644-1.535h3.981v5.463a.636.636 0 0 1-.625.642"
    />
  </svg>
)
export default SvgIconOpenSideBar
