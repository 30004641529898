import type { SVGProps } from 'react'
const SvgIconReport = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M3.875 17.5v-15c0-.344.281-.625.625-.625h6.25V5c0 .691.559 1.25 1.25 1.25h3.125V17.5a.627.627 0 0 1-.625.625h-10a.627.627 0 0 1-.625-.625M4.5 0A2.5 2.5 0 0 0 2 2.5v15C2 18.879 3.121 20 4.5 20h10c1.379 0 2.5-1.121 2.5-2.5V6.035a2.5 2.5 0 0 0-.73-1.77L12.73.73A2.5 2.5 0 0 0 10.965 0zm5.938 10.938A.935.935 0 0 0 9.5 10a.935.935 0 0 0-.937.938v5c0 .519.417.937.937.937s.938-.418.938-.937zm3.437 1.25a.935.935 0 0 0-.937-.938.935.935 0 0 0-.938.938v3.75c0 .519.418.937.938.937.519 0 .937-.418.937-.937zM7 13.438a.935.935 0 0 0-.937-.938.935.935 0 0 0-.938.938v2.5c0 .519.418.937.938.937.519 0 .937-.418.937-.937z"
    />
  </svg>
)
export default SvgIconReport
