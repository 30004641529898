import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from '@mui/material'

export type DrawerProps = {
  children?: React.ReactNode
} & MuiDrawerProps

const Drawer = ({ children, ...rest }: DrawerProps) => {
  return <MuiDrawer {...rest}>{children}</MuiDrawer>
}

export default Drawer
