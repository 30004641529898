import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiTableHead: {
  defaultProps: ComponentsProps['MuiTableHead']
  styleOverrides: ComponentsOverrides<Theme>['MuiTableHead']
} = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      '& th': {
        padding: '14px 16px',
        backgroundColor: `${theme.palette.common.white}`,
        borderBottom: '1px solid',
        borderRight: 'none',
        borderColor: theme.palette.divider,
      },
    }),
  },
}

export default MuiTableHead
