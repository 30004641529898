import { Stack as MuiStack, StackProps as MuiStackProps } from '@mui/material'
import { forwardRef } from 'react'

export type VStackProps = {
  children?: React.ReactNode
  fullHeight?: boolean
  fullWidth?: boolean
} & MuiStackProps

const VStack = forwardRef<HTMLDivElement, VStackProps>(
  ({ children, direction = 'column', sx = {}, fullHeight, fullWidth, ...rest }, ref) => {
    const styles: any = {
      ...sx,
    }

    if (fullHeight) {
      styles.height = '100%'
    }

    if (fullWidth) {
      styles.width = '100%'
    }

    return (
      <MuiStack ref={ref} direction={direction} sx={styles} {...rest}>
        {children}
      </MuiStack>
    )
  },
)

export default VStack
