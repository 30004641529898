import { Switch as MuiSwitch, SwitchProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const Switch = styled((props: SwitchProps) => <MuiSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 52,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      margin: 4,
      padding: '2px',
      transitionDuration: '300ms',
      backgroundColor: theme.palette.grey[500],
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
      },
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        backgroundColor: theme.palette.common.white,
        svg: {
          color: theme.palette.success.main,
        },
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.success.main,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      width: 24,
      height: 24,
      '&:hover': {
        backgroundColor: 'unset',
        color: 'unset',
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 16,
      backgroundColor: theme.palette.grey?.[100],
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }),
)

export default Switch
