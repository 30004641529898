import { ComponentsOverrides, Theme } from '@mui/material'

const MuiRadio: {
  styleOverrides: ComponentsOverrides<Theme>['MuiRadio']
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.Mui-checked': {
        color: theme.palette.info.main,
      },
    }),
  },
}

export default MuiRadio
