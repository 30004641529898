import useQueryProjects from '@hooks/queries/useQueryProjects/useQueryProjects.hook'
import projectService from '@services/ProjectService/ProjectService'
import userRoleService from '@services/UserRoleService/UserRoleService'
import { useQuery } from '@tanstack/react-query'
import React, { ReactNode, useCallback, useReducer } from 'react'
import GlobalContext from './GlobalContext'
import GlobalReducer, { ACTIONS } from './GlobalReducer'
import { defaultState, TGlobalState } from './GlobalState'

interface GlobalProviderProps {
  children: ReactNode
}

export interface GlobalContextType {
  state: TGlobalState
  dispatch: React.Dispatch<any>
  projectListLoading: boolean
  isLoadingUserRoleMappings: boolean
  isLoadingInitData: boolean
  fetchListProject: () => void
  fetchUserRoleMappings: () => void
  fetchInitData: () => void
}

const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, defaultState)

  const { refetch, isFetching, isPending } = useQueryProjects({
    onSuccess: projectList => {
      dispatch({
        type: ACTIONS.SET_PROJECT_LIST,
        payload: { projectList: projectList },
      })
      dispatch({
        type: ACTIONS.SET_EVENTS,
        payload: { shouldRefetchProjectList: false },
      })
    },
  })

  const fetchUserRoleMappingsQuery = useQuery({
    enabled: false,
    queryKey: ['userRoleMappings'],
    refetchOnWindowFocus: false,
    queryFn: () =>
      userRoleService.fetchUserRoleMappings().then(res => {
        dispatch({
          type: ACTIONS.SET_USER_ROLE_MAPPINGS,
          payload: { userRoleMappings: res.data },
        })
        return res
      }),
  })

  const fetchListProject = useCallback(() => {
    if (!state.projectList.length) {
      refetch()
    }
  }, [])

  const initDataQuery = useQuery({
    queryKey: ['fetch-projects'],
    queryFn: async () => {
      const responses = await Promise.all([projectService.fetchAllProjects(), userRoleService.fetchUserRoleMappings()])
      const [projectRes, userRoleMappingsRes] = responses
      dispatch({
        type: ACTIONS.INIT_DATA_SUCCESS,
        payload: { userRoleMappings: userRoleMappingsRes.data, projectList: projectRes.data },
      })
    },
    refetchOnMount: false,
    enabled: false,
  })

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
        /**
         * Loading
         */
        projectListLoading: isPending || isFetching,
        isLoadingUserRoleMappings: fetchUserRoleMappingsQuery.isRefetching,
        isLoadingInitData: initDataQuery.isRefetching,
        /**
         * Methods
         */
        fetchListProject,
        fetchUserRoleMappings: fetchUserRoleMappingsQuery.refetch,
        fetchInitData: initDataQuery.refetch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
