import { IBreadcrumbItem } from '@components/molecule/Breadcrumb/Breadcrumb.type'
import { IProject } from '@models/projects.model'
import { IUserRoleMapping } from '@models/userRole.model'

export type TGlobalState = {
  projectList: IProject[]
  /**
   * Organisation Id.
   */
  selectedOrganisationId?: string
  events: {
    shouldRefetchProjectList: boolean
    shouldRefetchUserRoleMappings: boolean
  }
  breadcrumbItems: IBreadcrumbItem[]
  isGlobalLoading: boolean
  userRoleMappings: IUserRoleMapping | null
}

export const defaultState: TGlobalState = {
  projectList: [],
  selectedOrganisationId: undefined,
  events: {
    shouldRefetchProjectList: false,
    shouldRefetchUserRoleMappings: false,
  },
  breadcrumbItems: [],
  isGlobalLoading: true,
  userRoleMappings: null,
}
