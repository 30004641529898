import { useMutation, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { Feature, ImageTile, Map, View } from 'ol'
import { Geometry } from 'ol/geom'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { OSM, XYZ } from 'ol/source'
import VectorSource from 'ol/source/Vector'
import { apply } from 'ol-mapbox-style'
import { fromLonLat } from 'ol/proj'
import React, { ReactNode, useCallback, useReducer, useRef } from 'react'

import useCreateSurveyMutation from '@hooks/mutation/useCreateSurveyMutation/useCreateSurveyMutation'
import useAppParams from '@hooks/useAppParams'
import { ESurveyState } from '@models/survey.model'
import checkpointService from '@services/CheckpointService/CheckpointService'
import observationService from '@services/ObservationService/ObservationService'
import projectService from '@services/ProjectService/ProjectService'
import surveyService from '@services/SurveyService/SurveyService'
import { getTileUrl, isLayerExist, zoomToSource } from '@utils/open-layer'
import { styleFunctionCheckpoints, styleFunctionMapFeatures, styleProjectBoundary } from '@utils/open-layer/open-layer.styles'
import tileLayerService from '@services/TileLayerService/TileLayerService'
import logger from '@utils/logger'
import useDeleteSurveyMutation from '@hooks/mutation/useCreateSurveyMutation/useDeleteSurveyMutation'
import ENV from '@configs/env'
import { useMozaicSnackbar } from '@hooks/index'
import planService from '@services/PlanService/PlanService'

import ProjectDetailContext from './ProjectDetailContext'
import { ACTIONS, ZIndex } from './ProjectDetailContext.const'
import reducer from './ProjectDetailReducer'
import { defaultState, TProjectDetailState } from './ProjectDetailState'
import { selectSurveyDetailBySurveyId } from './hooks/useProjectDetailSelector'
import mapFeatureService from '@services/MapFeatureService/MapFeatureService'
import useDeleteMapFeaturesMutation from '@hooks/mutation/useMapFeatureMutation/useDeleteMapFeaturesMutation'
import { useCreateMapFeaturesMutation } from '@hooks/mutation/useMapFeatureMutation/useCreateMapFeaturesMutation'

interface ProjectDetailProviderProps {
  children: ReactNode
}

export type VectorLayerName = 'mapFeatures' | 'checkpoints' | 'createSurvey' | 'editSurvey' | 'projectBoundary'
export type TileLayerName = 'tiles'
export type LayerName = VectorLayerName | TileLayerName

interface UpdateOrAddFeaturesLayerProps {
  name: VectorLayerName
  source: VectorSource<Feature<Geometry>>
  shouldZoomToSource?: boolean
}

export interface ProjectDetailContextType {
  /**
   * Maps
   */
  state: TProjectDetailState
  dispatch: React.Dispatch<any>
  mapRef: React.MutableRefObject<HTMLDivElement | null>
  mapInstanceRef: React.MutableRefObject<Map | null>
  vectorLayersRef: React.MutableRefObject<Record<VectorLayerName, VectorLayer<any>>>
  tileLayersRef: React.MutableRefObject<Record<TileLayerName, any>>
  /**
   * Fetching and Update data
   */
  fetchMetrics: () => void
  isLoadingMetrics: boolean
  fetchProjectDetail: () => void
  fetchSurveys: (planId: string) => Promise<any>
  isLoadingSurveys: boolean
  fetchMapFeatures: (planId: string) => Promise<any>
  isLoadingMapFeatures: boolean
  fetchObservations: () => void
  isLoadingObservations: boolean
  updateSurveyState: (state: any) => Promise<any>
  updateSurvey: (state: any) => Promise<any>
  isLoadingUpdateSurvey: boolean
  fetchCheckpointLocations: () => void
  createSurvey: (survey: any) => void
  deleteSurvey: (id: string) => void
  deleteMapFeatures: (ids: string[]) => Promise<any>
  createMapFeatures: (features: any) => Promise<any>
  isLoadingCreateMapFeatures: boolean
  isLoadingCreateNewSurvey: boolean
  refetchSurveyDetail: () => void
  isLoadingSurveyDetail: boolean
  isLoadingCheckpoints: boolean
  /**
   * Map handler
   */
  initMap: () => Map | undefined
  zoomInMap: () => void
  zoomOutMap: () => void
  zoomToCenter: () => void

  /**
   * Plan handler
   */
  fetchPlans: () => Promise<any>
  isLoadingPlans: boolean
  addVectorLayer: (props: UpdateOrAddFeaturesLayerProps) => VectorLayer<any> | null | undefined
  updateVectorLayerSource: (props: UpdateOrAddFeaturesLayerProps) => void
  removeAllLayers: () => void

  /** Tile layers */
  addTilesLayer: (folderPath: string, bucketId: string, name: TileLayerName) => void
  handleUpdateTileLayer: (folderPath: string | null) => void
  hideTilesLayer: () => void
  fetchTiles: () => void
  handleTileLayerChange: () => void
}

const ProjectDetailProvider: React.FC<ProjectDetailProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const mapRef = useRef<HTMLDivElement | null>(null)
  const mapInstanceRef = useRef<Map | null>(null)
  const { projectId, getParams, checkpointId } = useAppParams()
  const { enqueueSuccessSnackbar } = useMozaicSnackbar()

  const zoomInMap = () => {
    const mapInstance = mapInstanceRef.current
    if (mapInstance) {
      const view = mapInstance.getView()
      const currentZoom = view.getZoom()
      if (currentZoom !== undefined && currentZoom < view.getMaxZoom()) {
        const newZoom = currentZoom + 1
        view.setZoom(newZoom)
      }
    }
  }

  const zoomOutMap = () => {
    const mapInstance = mapInstanceRef.current
    if (mapInstance) {
      const view = mapInstance.getView()
      const currentZoom = view.getZoom()
      if (currentZoom !== undefined && currentZoom > 1) {
        const newZoom = currentZoom - 1
        view.setZoom(newZoom)
      }
    }
  }

  const zoomToCenter = () => {}

  const planQuery = useQuery({
    queryKey: ['fetch-plans-by-project-id', projectId],
    queryFn: () =>
      planService.fetchPlans({ projectId: projectId as string }).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_PLANS,
          payload: { plans: data },
        })
        return data
      }),
    refetchOnMount: false,
    enabled: false,
  })

  const tileLayerQuery = useQuery({
    queryKey: ['tile-layers', projectId],
    queryFn: () =>
      tileLayerService.fetchTileLayersByProjectId(projectId).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_TILE_LAYERS,
          payload: { tileLayers: data },
        })
        return data
      }),
    refetchOnMount: false,
    enabled: false,
  })

  const surveyDetailQuery = useQuery({
    queryKey: ['surveyDetail'],
    queryFn: ({ queryKey: [_] }) => {
      const surveyId = getParams('surveyId')
      return surveyService.fetchSurveyDetail({ surveyId: surveyId as string })
    },
    enabled: false,
    refetchOnMount: false,
  })

  // Record<VectorLayerName, VectorLayer<VectorSource<any>>

  // Manage added layers, check existed layer in map.
  const vectorLayersRef = useRef<Record<VectorLayerName, VectorLayer<any>>>({
    mapFeatures: new VectorLayer(),
    checkpoints: new VectorLayer(),
    createSurvey: new VectorLayer(),
    editSurvey: new VectorLayer(),
    projectBoundary: new VectorLayer(),
  })

  // Manage added layers, check existed layer in map.
  const tileLayersRef = useRef<Record<TileLayerName, any | null>>({ tiles: null })

  const MetricQuery = useQuery({
    queryKey: ['fetch-metrics-by-project-id', projectId],
    queryFn: () =>
      projectService.fetchMetricsByProjectId(projectId).then(({ data }) => {
        dispatch({ type: ACTIONS.SET_METRICS, payload: { metrics: data ?? [] } })
        return data
      }),
    enabled: false,
    refetchOnMount: false,
  })

  const projectDetailQuery = useQuery({
    queryKey: ['fetch-project-detail', projectId, state.selectedPlanId],
    queryFn: () => {
      const planId = state.selectedPlanId ?? getParams('planId')
      projectService.fetchProjectDetail({ id: projectId, planId }).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_PROJECT_DETAIL,
          payload: { projectDetail: data },
        })
        return data
      })
    },
    enabled: false,
    refetchOnMount: false,
  })

  const surveysQuery = useMutation({
    mutationKey: ['fetch-surveys-by-plan-id', projectId],
    mutationFn: (planId: string) => {
      return surveyService.fetchSurveys({ projectId: projectId as string, planId: planId }).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_LIST_SURVEYS,
          payload: { surveys: data },
        })
        return data
      })
    },
  })

  const mapFeaturesQuery = useMutation({
    mutationKey: ['fetch-features-by-plan-id', projectId],
    mutationFn: (planId: string) => {
      return mapFeatureService.fetchMapFeatures({ projectId: projectId as string, planId: planId }).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_FEATURE_SOURCE,
          payload: { features: data },
        })
        return data
      })
    },
  })

  const tilesMutation = useMutation({
    mutationKey: ['fetch-image-tiles'],
    mutationFn: ({ src, tile }: { src: string; tile: ImageTile }) =>
      !tile ? Promise.reject('Missing tile in mutation variables') : tileLayerService.fetchTileImage(src),
    onSuccess(imageUrl, variables) {
      try {
        const imageElement = variables.tile.getImage() as HTMLImageElement
        if (imageElement) {
          imageElement.src = imageUrl
        }
      } catch (error) {
        logger.error(`Failed to set tile image src`, error)
      }
    },
  })

  const observationsQuery = useQuery({
    queryKey: ['fetch-observations-by-survey-id', projectId],
    queryFn: ({ queryKey }) => {
      const surveyId = getParams('surveyId')

      if (!surveyId) {
        return Promise.reject()
      }
      return observationService.fetchLinkedObservations({ projectId: queryKey[1] ?? '', surveyId }).then(({ data }) => {
        dispatch({
          type: ACTIONS.SET_OBSERVATIONS,
          package: {
            observations: data,
            surveyId: surveyId,
          },
        })
      })
    },
    enabled: false,
    refetchOnMount: false,
  })

  const checkpointsQuery = useQuery({
    queryKey: ['fetch-checkpoints-by-plan', checkpointId],
    queryFn: async () => {
      const surveyId = getParams('surveyId')
      const planId = getParams('planId')
      const checkpointId = getParams('checkpointId')

      if (!surveyId) {
        return Promise.reject()
      }
      const surveyDetail = selectSurveyDetailBySurveyId(state, surveyId)
      if (!surveyDetail) {
        logger.error(`Survey detail not found for surveyId: ${surveyId}`)
        return Promise.reject()
      }
      const { data: planCheckpoints } = await checkpointService.fetchCheckpointsByPlanId({
        planId: planId ?? '',
        startDate: surveyDetail?.startDate as string,
        endDate: surveyDetail?.endDate as string,
      })

      const { data: surveyCheckpoints } = await checkpointService.fetchCheckpointsBySurveyId({
        surveyId: surveyId ?? '',
      })

      const allCheckpoints = _.uniqBy([...(planCheckpoints ?? []), ...(surveyCheckpoints ?? [])], 'checkpointId')

      const data = allCheckpoints?.map(checkpoint => ({
        ...checkpoint,
        isLinked: _.findIndex(surveyCheckpoints, { checkpointId: checkpoint.checkpointId }) > -1,
      }))

      dispatch({
        type: ACTIONS.SET_PROJECT_SURVEY_CHECKPOINTS,
        payload: { projectSurveyCheckpoints: data, surveyId, selectedCheckpointId: checkpointId },
      })
      return data
    },
    enabled: false,
    refetchOnMount: false,
  })

  const { createSurvey, isLoading: isLoadingCreateNewSurvey } = useCreateSurveyMutation({
    onSuccess: data => {
      enqueueSuccessSnackbar('Survey was created successfully!')
      dispatch({
        type: ACTIONS.SET_LIST_SURVEYS,
        payload: {
          surveys: [...state.surveys, data],
        },
      })
    },
  })

  const updateSurveyStateMutation = useMutation({
    mutationKey: ['update-survey-state'],
    mutationFn: (state: ESurveyState) => {
      const surveyId = getParams('surveyId')
      return surveyService.updateSurveyState({ id: surveyId as string, state })
    },
    onSuccess: ({ data }) => {
      const updatedSurveys = state.surveys.map(survey => (survey.id === data?.id ? { ...survey, ...data } : survey))
      enqueueSuccessSnackbar('Survey was updated successfully!')
      dispatch({
        type: ACTIONS.SET_LIST_SURVEYS,
        payload: {
          surveys: [...updatedSurveys],
        },
      })
    },
  })

  const updateSurveyMutation = useMutation({
    mutationKey: ['update_survey_with_selected_map_feature'],
    mutationFn: surveyService.updateSurveyDates,
    onSuccess: ({ data }) => {
      const updatedSurveys = state.surveys.map(survey => (survey.id === data?.id ? { ...survey, ...data } : survey))
      enqueueSuccessSnackbar('Survey was updated successfully!')
      dispatch({
        type: ACTIONS.SET_LIST_SURVEYS,
        payload: {
          surveys: [...updatedSurveys],
        },
      })
    },
  })

  const { deleteSurvey } = useDeleteSurveyMutation({
    onSuccess: id => {
      const updatedSurveys = state.surveys.filter(survey => survey.id !== id)
      dispatch({
        type: ACTIONS.SET_LIST_SURVEYS,
        payload: {
          surveys: [...updatedSurveys],
        },
      })
    },
  })

  const { deleteMapFeatures } = useDeleteMapFeaturesMutation({
    onSuccess: ids => {
      const updatedMapFeatures = state.mapFeatures.filter(feature => !ids.includes(feature.id))
      dispatch({
        type: ACTIONS.SET_FEATURE_SOURCE,
        payload: {
          features: [...updatedMapFeatures],
        },
      })
    },
  })

  const { createMapFeatures, isLoading: isLoadingCreateMapFeatures } = useCreateMapFeaturesMutation({
    onSuccess: data => {
      enqueueSuccessSnackbar('Map features were created successfully!')

      const updatedMapFeatures = [...state.mapFeatures, ...data]
      dispatch({
        type: ACTIONS.SET_FEATURE_SOURCE,
        payload: {
          features: updatedMapFeatures,
        },
      })
    },
  })

  const initMap = () => {
    if (!mapRef.current || mapInstanceRef.current) return

    const map = new Map({
      target: mapRef.current,
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({
        center: fromLonLat([-2, 24]),
        zoom: 20,
      }),
      controls: [],
    })

    apply(map, `${ENV.MAP_STYLE}?key=${ENV.MAP_KEY}`)

    mapInstanceRef.current = map

    dispatch({
      type: ACTIONS.SET_MAP_RENDER_COMPLETE,
      payload: {
        initialized: true,
      },
    })

    return map
  }

  const addVectorLayer = ({ shouldZoomToSource = false, name, source }: UpdateOrAddFeaturesLayerProps) => {
    if (!mapInstanceRef.current) return

    const exist = isLayerExist(mapInstanceRef.current, vectorLayersRef.current[name])
    const layerStyles: Record<VectorLayerName, any> = {
      mapFeatures: styleFunctionMapFeatures,
      checkpoints: styleFunctionCheckpoints,
      createSurvey: styleFunctionMapFeatures,
      editSurvey: styleFunctionMapFeatures,
      projectBoundary: styleProjectBoundary,
    }
    let layer

    if (exist) {
      vectorLayersRef.current[name]?.setSource(source)
      layer = vectorLayersRef.current[name]
      if (shouldZoomToSource) {
        zoomToSource(mapInstanceRef.current, source)
      }
    } else {
      layer = new VectorLayer({
        source: source,
        style: layerStyles[name],
        zIndex: ZIndex[name],
      })
      mapInstanceRef.current.addLayer(layer)
      vectorLayersRef.current[name] = layer
    }

    if (shouldZoomToSource) {
      zoomToSource(mapInstanceRef.current, source)
    }

    return layer
  }

  const updateVectorLayerSource = ({ name, source }: UpdateOrAddFeaturesLayerProps) => {
    if (!mapInstanceRef.current) return

    const exist = isLayerExist(mapInstanceRef.current, vectorLayersRef.current[name])
    if (exist) {
      vectorLayersRef.current[name]?.setSource(source)
    }
  }

  const addTilesLayer = async (folderPath: string, bucketId: string, name: TileLayerName) => {
    if (!mapRef.current) return
    const exist = isLayerExist(mapInstanceRef.current, tileLayersRef.current[name])
    let tileLayer

    if (exist) {
      tileLayer = tileLayersRef.current[name]
      tileLayer.setVisible(true)
    } else {
      tileLayer = new TileLayer({
        source: new XYZ({
          tileUrlFunction: tileCoord => getTileUrl(tileCoord, bucketId, folderPath),
          tileLoadFunction: (imageTile, src) => {
            const tile = imageTile as ImageTile
            tilesMutation.mutate({ src, tile })
          },
        }),
      })
      mapInstanceRef.current?.addLayer(tileLayer)
      tileLayersRef.current[name] = tileLayer
    }
  }

  const hideTilesLayer = () => {
    if (!mapInstanceRef.current) return

    const tileLayer = tileLayersRef.current['tiles']

    if (tileLayer) {
      tileLayer.setVisible(false)
    } else {
      console.warn(`No Tiles layer found to remove.`)
    }
  }

  const handleUpdateTileLayer = (folderPath: string | null) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_TILE,
      payload: {
        selectedTileLayer: folderPath,
      },
    })
  }

  const removeAllLayers = () => {
    _.forIn(vectorLayersRef.current, layer => {
      if (layer) {
        mapInstanceRef.current?.removeLayer(layer)
      }
    })
    _.forIn(tileLayersRef.current, layer => {
      if (layer) {
        mapInstanceRef.current?.removeLayer(layer)
      }
    })
  }

  const handleTileLayerChange = useCallback(() => {
    const [firstTileLayer] = state.tileLayers || []
    const folderPath = firstTileLayer?.folderPath || ''
    const bucketId = firstTileLayer?.bucketId || ''
    const isSelected = state.selectedTileLayer === folderPath

    hideTilesLayer()

    if (!folderPath || !bucketId) {
      return handleUpdateTileLayer(null)
    }

    if (isSelected) {
      handleUpdateTileLayer(null)
    } else {
      addTilesLayer(folderPath, bucketId, 'tiles')
      handleUpdateTileLayer(folderPath)
    }
  }, [state.tileLayers, state.selectedTileLayer, hideTilesLayer, handleUpdateTileLayer, addTilesLayer])

  return (
    <ProjectDetailContext.Provider
      value={{
        state,
        dispatch,
        mapRef,
        mapInstanceRef,
        vectorLayersRef,
        tileLayersRef,
        /**
         * Fetch & update data
         */
        fetchMetrics: MetricQuery.refetch,
        isLoadingMetrics: MetricQuery.isLoading || MetricQuery.isRefetching,
        fetchProjectDetail: projectDetailQuery.refetch,
        fetchSurveys: surveysQuery.mutateAsync,
        isLoadingSurveys: surveysQuery.isPending,
        fetchMapFeatures: mapFeaturesQuery.mutateAsync,
        isLoadingMapFeatures: mapFeaturesQuery.isPending,
        fetchObservations: observationsQuery.refetch,
        isLoadingObservations: observationsQuery.isPending || observationsQuery.isFetching,
        updateSurveyState: updateSurveyStateMutation.mutateAsync,
        updateSurvey: updateSurveyMutation.mutateAsync,
        isLoadingUpdateSurvey: updateSurveyMutation.isPending,
        fetchCheckpointLocations: checkpointsQuery.refetch,
        createSurvey,
        deleteSurvey,
        createMapFeatures,
        isLoadingCreateMapFeatures,
        deleteMapFeatures,
        isLoadingCreateNewSurvey,
        refetchSurveyDetail: surveyDetailQuery.refetch,
        isLoadingSurveyDetail: surveyDetailQuery.isLoading,
        isLoadingCheckpoints: checkpointsQuery.isLoading,
        /** Plan handler */
        fetchPlans: planQuery.refetch,
        isLoadingPlans: planQuery.isPending || planQuery.isFetching,
        /**
         * Map handler
         */
        initMap,
        addVectorLayer,
        updateVectorLayerSource,
        removeAllLayers,
        zoomInMap,
        zoomOutMap,
        zoomToCenter,
        /**
         * Tile layers
         */
        addTilesLayer,
        hideTilesLayer,
        handleUpdateTileLayer,
        fetchTiles: tileLayerQuery.refetch,
        handleTileLayerChange,
      }}
    >
      {children}
    </ProjectDetailContext.Provider>
  )
}

export default ProjectDetailProvider
