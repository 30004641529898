import { AppBar as MuiAppBar, AppBarProps as MuiAppBarProps } from '@mui/material'

export type AppBarProps = {
  children?: React.ReactNode
} & MuiAppBarProps

const AppBar = ({ children, ...rest }: AppBarProps) => {
  return <MuiAppBar {...rest}>{children}</MuiAppBar>
}

export default AppBar
