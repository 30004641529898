import MuiCssBaseline from './MuiCssBaseline'
import { Theme } from '@mui/material'
import { Components } from '@mui/material/styles/components'
import MuiButton from './MuiButton'
import MuiTypography from './MuiTypography'
import MuiContainer from './MuiContainer'
import MuiTableHead from './MuiTableHead'
import MuiTableContainer from './MuiTableContainer'
import MuiTableRow from './MuiTableRow'
import MuiDivider from './MuiDivider'
import MuiCard from './MuiCard'
import MuiDialog from './MuiDialog'
import MuiInputLabel from './MuiInputLabel'
import MuiFormControl from './MuiFormControl'
import MuiTextField from './MuiTextField'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiTab from './MuiTab'
import MuiTabs from './MuiTabs'
import MuiRadio from './MuiRadio'
import MuiChip from './MuiChip'

const components: Components<Omit<Theme, 'components'>> = {
  MuiButton,
  MuiTypography,
  MuiCssBaseline,
  MuiContainer,
  MuiTableContainer,
  MuiTableHead,
  MuiTableRow,
  MuiDivider,
  MuiCard,
  MuiDialog,
  MuiInputLabel,
  MuiFormControl,
  MuiTextField,
  MuiOutlinedInput,
  MuiTab,
  MuiTabs,
  MuiRadio,
  MuiChip,
}

export default components
