import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material'

const MuiTab: {
  defaultProps: ComponentsProps['MuiTab']
  styleOverrides: ComponentsOverrides<Theme>['MuiTab']
  variants?: ComponentsVariants['MuiTab']
} = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.grey[500],
      '&.Mui-selected': {
        color: theme.palette.blue[500],
      },
    }),
  },
}

export default MuiTab
