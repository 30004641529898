import { Stack, Typography } from '@components/atom'
import Icons from '@components/atom/Icons'
import * as styles from './BrandLogo.styles'
import { LogoContentHorizontal, LogoContentVertical, LogoImageOnly } from '@icons/index'

export type BrandLogoProps = {
  onClick?: () => void
  variant?: 'default' | 'logo-image-only' | 'logo-content-horizontal' | 'logo-content-vertical'
  width?: string
  height?: string
  isSideBarCollapse?: boolean
  lightBackground?: boolean
}

export const BrandLogo = ({ onClick, variant = 'default', width, height, isSideBarCollapse, lightBackground = false }: BrandLogoProps) => {
  const defaultLogo = (
    <Stack sx={styles.brandLogoContainer(onClick)} onClick={onClick}>
      <Icons Icon={LogoImageOnly} width={'44px'} height={'44px'} />
      {!isSideBarCollapse && (
        <Stack spacing={0.5}>
          <Typography sx={styles.brandLogoTextContainer(true, lightBackground)}>MOZAIC</Typography>
          <Typography sx={styles.brandLogoTextContainer(false)}>EARTH</Typography>
        </Stack>
      )}
    </Stack>
  )

  switch (variant) {
    case 'logo-image-only':
      return <Icons Icon={LogoImageOnly} width={width ?? '44px'} height={height ?? 'auto'} />
    case 'logo-content-horizontal':
      return <Icons Icon={LogoContentHorizontal} width={width ?? '223px'} height={height ?? 'auto'} />
    case 'logo-content-vertical':
      return <Icons Icon={LogoContentVertical} width={width ?? '292px'} height={height ?? 'auto'} />
    default:
      return defaultLogo
  }
}

export default BrandLogo
