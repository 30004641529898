import { useContext, useEffect, useMemo, useRef } from 'react'
import { matchPath, Outlet, useLocation } from 'react-router-dom'

import { HStack, OlMap } from '@components/atom'
import useProjectDetailSelector from '@context/ProjectDetailContext/hooks/useProjectDetailSelector'
import ProjectDetailContext from '@context/ProjectDetailContext/ProjectDetailContext'
import withProjectDetailProvider from '@context/ProjectDetailContext/withProjectDetailProvider'
import { useMount, useUnmount, useUpdateEffect } from 'ahooks'
import MainLayout from '../MainLayout'
import useGlobalSelector from '@context/GlobalContext/hooks/useGlobalSelector'
import { useAppNavigate } from '@hooks/index'

const ProjectDetailLayout = ({ mapDisplayRoutes = [] }: { mapDisplayRoutes: string[] }) => {
  const context = useContext(ProjectDetailContext)
  const { pathname } = useLocation()

  const projectDetail = useProjectDetailSelector(state => state.projectDetail)
  const shouldRefetchProjectDetail = useProjectDetailSelector(state => state.events.shouldRefetchProjectDetail)
  const projectBoundaryVectorSource = useProjectDetailSelector(state => state.projectBoundaryVectorSource)
  const selectedPlanId = useProjectDetailSelector(state => state.selectedPlanId)
  const selectedOrgId = useGlobalSelector(state => state.selectedOrganisationId)
  const { navigateToProjectsPage } = useAppNavigate()

  if (!context) {
    return <div />
  }

  const layoutRef = useRef<{ setSideBarCollapse: (state: boolean) => void } | null>(null)

  useMount(() => {
    if (!projectDetail || shouldRefetchProjectDetail) {
      context.fetchProjectDetail()
    }
  })

  useUpdateEffect(() => {
    if (selectedPlanId) {
      context.fetchProjectDetail()
    }
  }, [selectedPlanId])

  useUpdateEffect(() => {
    if (selectedOrgId !== projectDetail?.organisation?.id && !!projectDetail?.organisation?.id && !!selectedOrgId) {
      navigateToProjectsPage()
    }
  }, [selectedOrgId])

  const shouldDisplayMap = useMemo(() => {
    return mapDisplayRoutes.some(route => matchPath(route, pathname))
  }, [pathname])

  /** Setup project boundary | project boundary should display on all maps. */
  useEffect(() => {
    if (projectBoundaryVectorSource && context.mapInstanceRef.current) {
      context.addVectorLayer({ name: 'projectBoundary', shouldZoomToSource: false, source: projectBoundaryVectorSource })
    }
  }, [projectBoundaryVectorSource, projectDetail])

  useEffect(() => {
    layoutRef.current?.setSideBarCollapse(true)
  }, [])

  useUnmount(() => {
    context.removeAllLayers()
  })

  return (
    <MainLayout isBoxShadowTopBar ref={layoutRef}>
      {shouldDisplayMap ? (
        <HStack>
          <Outlet />
          <OlMap ref={context.mapRef} isFullScreen />
        </HStack>
      ) : (
        <Outlet />
      )}
    </MainLayout>
  )
}

export default withProjectDetailProvider(ProjectDetailLayout)
