import PrivateRoute from '@components/template/PrivateRoute'
import { lazy } from 'react'
import ERoutePaths from './routes.path'

const UserManagementPage = lazy(() => import('@pages/UserManagementPage/UserManagementPage'))

const userManagementRoutes = {
  users: {
    path: ERoutePaths.UserManagement,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | User Management">
        <UserManagementPage />
      </PrivateRoute>
    ),
  },
}

export default userManagementRoutes
