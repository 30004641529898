import { Table } from '@components/atom'
import { styled } from '@mui/material'

export const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  borderLeft: `1px solid ${theme.palette.grey['200']}`,
  borderRight: `1px solid ${theme.palette.grey['200']}`,
  tr: {
    ':hover': {
      backgroundColor: theme.palette.grey['100'],
    },
    textAlign: 'left',
    minWidth: '100px',
    borderBottom: `1px solid ${theme.palette.grey['200']}`,
    borderTop: `1px solid ${theme.palette.grey['200']}`,
    td: {
      borderBottom: 'none',
      textAlign: 'left',
      minWidth: '100px',
    },
  },
}))
