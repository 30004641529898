import { ISurvey } from '@models/survey.model'
import surveyService from '@services/SurveyService/SurveyService'
import { useMutation } from '@tanstack/react-query'

export const useCreateSurveyMutation = ({ onSuccess }: { onSuccess?: (data: ISurvey) => void } = {}) => {
  const { mutateAsync: createSurvey, isPending: createSurveyLoading } = useMutation({
    mutationKey: ['create_survey_with_selected_map_feature'],
    mutationFn: surveyService.create,
    onSuccess: data => {
      if (data?.data) {
        onSuccess?.(data?.data)
      }
    },
  })

  return {
    createSurvey,
    isLoading: createSurveyLoading,
  }
}

export default useCreateSurveyMutation
