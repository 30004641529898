import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { useAuthentication } from '@context/AuthenticationContext'
import useActivityTracker from '@hooks/useActivityTracker'
import ERoutePaths from '@configs/routes/routes.path'
import { useAppNavigate } from '@hooks/index'
import useGlobalContext from '@context/GlobalContext/hooks/useGlobalContext'
import useGlobalSelector from '@context/GlobalContext/hooks/useGlobalSelector'
import { ACTIONS } from '@context/GlobalContext/GlobalReducer'
import PageViewAnalyticsHandler from './MainLayout/PageeViewAnalyticsHandler'

export const PrivateRoute = ({ title, children }: { title: string; children: any }) => {
  const { navigateToCaptureInfoPage } = useAppNavigate()
  const { state } = useLocation()
  const isGlobalLoading = useGlobalSelector(state => state.isGlobalLoading)
  const { dispatch } = useGlobalContext()

  const { onLogoutSuccess, logout, updateUserInfo, checkIsProfileComplete } = useAuthentication({
    onAuthStateChange: (_event, session) => {
      if (!session) {
        onLogoutSuccess()
      } else if (session) {
        updateUserInfo({ email: session?.user?.email, id: session?.user?.id })
        checkIsProfileComplete().then(isProfileComplete => {
          if (!isProfileComplete) {
            navigateToCaptureInfoPage({
              state: window.location.href.includes(ERoutePaths.CaptureInfo)
                ? { redirectUrl: state?.redirectUrl }
                : { redirectUrl: window.location.href },
            })
          }
        })
      }
      if (session && isGlobalLoading) {
        dispatch({
          type: ACTIONS.SET_GLOBAL_LOADING,
          payload: { isGlobalLoading: false },
        })
      }
    },
  })

  useActivityTracker({
    onTimeout: () => logout({ type: 'auto' }),
    timeoutInMinutes: 60,
  })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageViewAnalyticsHandler />
      <Suspense fallback={null}>{children}</Suspense>
    </>
  )
}

export default React.memo(PrivateRoute)
