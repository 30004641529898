import { DialogActions, Stack, StackProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DialogActionsProps } from './DialogActions'

export const DialogHeaderBasic = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<StackProps>(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'transparent',
  justifyContent: 'center',
  '.btn-close': {
    color: 'blue.700',
  },
  '> .MuiTypography-root': {
    textAlign: 'center',
    color: 'blue.600',
  },
  alignItems: 'center',
  flexDirection: 'row',
}))

export const DialogHeaderFilled = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<StackProps>(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.blue[800],
  color: 'white',
  justifyContent: 'space-between',
  '.btn-close': {
    color: 'white',
  },
  alignItems: 'center',
  flexDirection: 'row',
}))

export const DialogActionStyled = styled(DialogActions)<Omit<DialogActionsProps, 'buttons'>>(({ theme }) => ({
  padding: theme.spacing(3),
}))
