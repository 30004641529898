import { Box, BoxProps, Typography } from '@components/atom'
import { styled, SxProps } from '@mui/material'

export const menuWrapperStyle: SxProps = {
  display: 'flex',
  justifyItems: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  padding: 0,
}

export const MenuItemContainer = styled(Box)<BoxProps & { isSelected: boolean; isSideBarCollapse: boolean }>(
  ({ theme, isSelected, isSideBarCollapse }) => ({
    padding: isSideBarCollapse ? '12px 18px' : '12px 24px',
    height: '48px',
    fontWeight: isSelected ? '700' : '400',
    borderRadius: '28px',
    width: '100%',
    color: theme.palette.common.white,
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'space-between',
    alignContent: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
    backgroundColor: isSelected ? theme.palette.teal?.[800] : 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.teal?.[800],
    },
  }),
)

export const ItemIcon: SxProps = {
  position: 'relative',
  display: 'inline-block',
}

export const ItemTitle = styled(Typography)<BoxProps & { hasNotifications: boolean; isSelected: boolean }>(
  ({ hasNotifications, isSelected }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: isSelected ? '700' : '400',
    maxWidth: hasNotifications ? '120px' : 'unset',
  }),
)

export const NotificationDot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 6,
  height: 6,
  backgroundColor: theme.palette.error.main,
  borderRadius: '50%',
  transform: 'translate(50%, -50%)',
}))

export const NotificationBoxStyle: SxProps = {
  backgroundColor: 'error.main',
  padding: '2px 8px',
  color: 'common.white',
  borderRadius: '100%',
  fontWeight: '700',
  fontSize: '14px',
  textAlign: 'center',
}

export const SectionTitle: SxProps = {
  color: 'text.secondary',
  fontWeight: 700,
  marginLeft: '24px',
  height: '56px',
  display: 'flex',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '250px',
  alignItems: 'center',
}

export const SectionDivider: SxProps = { borderColor: 'divider', width: '40px', marginX: 'auto', marginBottom: '24px' }
