import { AppBar, Box, Toolbar, ToolbarProps } from '@components/atom'
import { styled, SxProps } from '@mui/material'

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  background: theme.palette.background.paper,
  backdropFilter: 'blur(4px)',
  display: 'flex',
}))

export const topBarGreetings: SxProps = {
  display: 'flex',
  flex: 1,
  gap: 2,
  alignItems: 'center',
}

export const topBarButtons: SxProps = {
  display: 'flex',
  gap: 2,
}

export const StyledToolbar = styled(Toolbar)<ToolbarProps & { isBoxShadow: boolean }>(({ theme, width, isBoxShadow }) => ({
  width: `calc(100vw - ${width})`,
  height: '80px',
  color: theme.palette.text.secondary,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '0 24px',
  boxShadow: isBoxShadow ? '0px 2px 2px rgba(0, 0, 0, 0.25)' : 'unset',
}))

export const Divider = styled(Box)(({ theme }) => ({
  width: '1px',
  borderLeft: `1px solid ${theme.palette.divider}`,
  boxSizing: 'border-box',
  height: '40px',
  margin: 'auto 0',
}))
