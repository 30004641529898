import { Box, styled } from '@mui/material'
import { BoxProps } from '../Box'

interface StyledOlMapProps extends BoxProps {}

export const StyledOlMap = styled(Box)<StyledOlMapProps>(({}) => ({
  '& .map': {
    width: '100%',
    height: '100%',
    // flex: 1,
  },
  width: '100%',
  height: 'calc(100dvh - 80px)',
  position: 'relative',
  bgcolor: 'red',
  display: 'flex',
  justifyContent: 'start',
}))
