import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiOutlinedInput: {
  defaultProps: ComponentsProps['MuiOutlinedInput']
  styleOverrides: ComponentsOverrides<Theme>['MuiOutlinedInput']
} = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.info.main,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.info.main,
      },
    }),
  },
}

export default MuiOutlinedInput
