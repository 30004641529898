import { Box, BoxProps } from '@components/atom'
import { styled, SxProps } from '@mui/material'

export const toolbarContainer: SxProps = {
  position: 'absolute',
  bottom: '24px',
  left: '50%',
  background: 'white',
  paddingX: 2,
  paddingY: 1,
  display: 'flex',
  alignItems: 'center',
  transform: 'translate(-50%)',
  backgroundColor: 'grey.900',
  borderRadius: 1,
}

export const helperTextStyle: SxProps = {
  backgroundColor: 'info.light',
  borderRadius: 2,
  height: '32px',
  position: 'absolute',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  bottom: '76px',
  left: '50%',
  transform: 'translate(-50%)',
  width: 'max-content',
}

export const NavigateBtn = styled(Box)<BoxProps & {}>(({}) => ({
  backgroundColor: '#fff',
  borderRadius: '50%',
  height: '48px',
  width: '48px',
  position: 'absolute',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bottom: '144px',
  cursor: 'pointer',
  left: '24px',
}))

export const ZoomInOut = styled(Box)<BoxProps & {}>(({}) => ({
  backgroundColor: '#fff',
  borderRadius: '24px',
  height: '96px',
  width: '48px',
  position: 'absolute',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  bottom: '24px',
  cursor: 'pointer',
  left: '24px',
}))

export const zoomInIcon: SxProps = {
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'text.primary',
}

export const zoomOutIcon: SxProps = {
  borderTop: '1px solid',
  borderColor: 'grey.100',
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: '24px',
  color: 'text.primary',
}
