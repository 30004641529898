import Stack from '@mui/material/Stack'
import Step from '@mui/material/Step'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'

const QontoConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    border: 'none',
  },
}))

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ ownerState, theme }) => ({
  display: 'flex',
  height: '4px',
  backgroundColor: ownerState.active ? theme.palette.blue[500] : theme.palette.grey[300],
  borderRadius: '16px',
}))

function QontoStepIcon(props: StepIconProps) {
  const { active, className } = props
  return <QontoStepIconRoot ownerState={{ active }} className={className} sx={{ width: '100%' }} />
}

export default function StepperComponent({ steps, activeStep }: { steps: number[]; activeStep: number }) {
  return (
    <Stack sx={{ width: '100%', marginBottom: '16px' }} spacing={4}>
      <Stepper style={{ width: '100%' }} alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map(label => (
          <Step key={label} sx={{ width: '100%' }}>
            <StepLabel
              sx={{ width: '100%', '.MuiStepLabel-iconContainer': { width: '100%' } }}
              style={{ width: '100%' }}
              StepIconComponent={QontoStepIcon}
            />
          </Step>
        ))}
      </Stepper>
    </Stack>
  )
}
