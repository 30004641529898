import type { SVGProps } from 'react'
const SvgIconMore = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M7.674 11.852a2.074 2.074 0 1 0 0 4.148 2.074 2.074 0 0 0 0-4.148m0-5.926a2.074 2.074 0 1 0 0 4.148 2.074 2.074 0 0 0 0-4.148m2.074-3.852a2.074 2.074 0 1 0-4.148 0 2.074 2.074 0 0 0 4.148 0"
    />
  </svg>
)
export default SvgIconMore
