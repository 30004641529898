import { SxProps, Theme } from '@mui/material'

export const PaperMenuPopup: SxProps = {
  boxShadow: 'none',
  border: '1px solid',
  borderColor: 'divider',
}

export const sectionTableCell = (isFirst: boolean): SxProps<Theme> => ({
  backgroundColor: theme => `${theme.palette.grey[100]} !important`,
  borderTop: !isFirst ? '1px solid' : 0,
  borderColor: 'divider',
  padding: 0,
})
