import { IGeomMapFeature } from './boundaries.model'
import { MetricResponse } from './metric.model'

export enum ESurveyState {
  Open = 'open',
  InProgress = 'in_progress',
  Done = 'done',
}

export interface ISurvey {
  id: string
  projectId: string
  metrics: Pick<MetricResponse, 'objective' | 'id'>
  mapFeatures: IGeomMapFeature
  startDate: Date | null | string
  endDate: Date | null | string
  state: ESurveyState
  createdAt: Date | string
  updatedAt: Date | string
}
