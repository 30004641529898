import type { SVGProps } from 'react'
const SvgIconSignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M19.725 10.413a.933.933 0 0 0 0-1.324l-5-5.003a.937.937 0 0 0-1.324 1.324l3.398 3.398-9.612.004a.935.935 0 0 0-.938.937c0 .52.418.938.938.938h9.612l-3.398 3.398a.937.937 0 0 0 1.324 1.324zM6.562 2.875c.52 0 .937-.418.937-.938A.935.935 0 0 0 6.562 1H3.437A3.437 3.437 0 0 0 0 4.437v10.624a3.437 3.437 0 0 0 3.437 3.437h3.125c.52 0 .937-.418.937-.937a.935.935 0 0 0-.937-.937H3.437c-.863 0-1.562-.7-1.562-1.563V4.437c0-.863.699-1.562 1.562-1.562z"
    />
  </svg>
)
export default SvgIconSignOut
