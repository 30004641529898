import { SxProps } from '@mui/material'

export const COLLAPSE_SIDE_BAR_WIDTH = 72
export const SIDE_BAR_WIDTH = 271

export const MainWrapper: SxProps = {
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'row',
  width: '100%',
}

export const PageWrapper: SxProps = {
  display: 'flex',
  flexGlow: 1,
  flexDirection: 'column',
  zIndex: 1,
  width: '100%',
  backgroundColor: 'neutral.200',
}
