import { Chip } from '@components/atom'
import IconKey from '@icons/IconKey'
import { ChipIcon } from './UserRoleChip.style'
import { IconXClose } from '@icons/index'

export type UserRoleChipProps = {
  role: string | 'collector' | 'member'
  isDelete?: boolean
  showIcon?: boolean
  onDelete?: () => void
}

const UserRoleChip = ({ role, isDelete, showIcon = true, onDelete }: UserRoleChipProps) => {
  switch (role) {
    case 'collector': {
      return (
        <Chip
          icon={showIcon ? <IconKey style={{ ...ChipIcon, color: 'black' }} /> : <></>}
          label="Collector"
          sx={{ backgroundColor: 'orange.500', color: 'common.black' }}
          onDelete={isDelete ? onDelete : undefined}
          deleteIcon={<IconXClose style={{ color: '#fff' }} width={16} />}
        />
      )
    }
    case 'member': {
      return (
        <Chip
          icon={showIcon ? <IconKey style={ChipIcon} /> : <></>}
          label="Member"
          sx={{ backgroundColor: 'info.main', color: 'common.white' }}
          onDelete={isDelete ? onDelete : undefined}
          deleteIcon={<IconXClose style={{ color: '#fff' }} width={16} />}
        />
      )
    }

    default: {
      return <></>
    }
  }
}

export default UserRoleChip
