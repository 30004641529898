import type { SVGProps } from 'react'
const SvgIconBan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="M15.043 16.371 3.629 4.957A8.1 8.1 0 0 0 1.875 10 8.124 8.124 0 0 0 10 18.125c1.906 0 3.66-.656 5.043-1.754m1.324-1.324A8.1 8.1 0 0 0 18.125 10 8.124 8.124 0 0 0 10 1.875c-1.906 0-3.66.656-5.043 1.754l11.414 11.414zM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10"
    />
  </svg>
)
export default SvgIconBan
