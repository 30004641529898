import { FeatureLike } from 'ol/Feature'
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style'
import { MultiLineString, LineString, MultiPoint } from 'ol/geom'

import CPMarkerDisabled from '@assets/images/checkpoint/checkpoint-disabled.png'
import CPMarkerOutstanding from '@assets/images/checkpoint/checkpoint-outstanding.png'
import CPMarkerSelected from '@assets/images/checkpoint/checkpoint-selected.png'
import light from '@styles/theme/light'

const { mapPalette } = light.palette

export const createPolygonStyle = ({
  visible = false,
  disabled = false,
  isSelected = false,
  isHovered = false,
  editing = false,
  transparentBg = false,
} = {}) => {
  let fillColor = mapPalette.polygonFillColor.default
  let strokeColor = mapPalette.polygonStrokeColor.default
  let strokeWidth = mapPalette.strokeWidth.default

  if (isHovered) {
    fillColor = mapPalette.polygonFillColor.hover
    strokeColor = mapPalette.polygonStrokeColor.hover
    strokeWidth = mapPalette.strokeWidth.large
  }
  if (isSelected) {
    fillColor = mapPalette.polygonFillColor.selected
    strokeColor = mapPalette.polygonStrokeColor.selected
    strokeWidth = mapPalette.strokeWidth.large
  }
  if (disabled) {
    fillColor = mapPalette.polygonFillColor.disabled
    strokeColor = mapPalette.polygonStrokeColor.disabled
    strokeWidth = mapPalette.strokeWidth.default
  }
  if (editing) {
    fillColor = mapPalette.polygonFillColor.editing
    strokeColor = mapPalette.polygonStrokeColor.editing
    strokeWidth = mapPalette.strokeWidth.large
  }
  if (transparentBg) {
    fillColor = 'rgba(255, 255, 255, 0.0)'
  }
  const polygonStyle = new Style({
    fill: visible ? new Fill({ color: fillColor }) : undefined,
    stroke: visible ? new Stroke({ color: strokeColor, width: strokeWidth }) : undefined,
    zIndex: isSelected ? 1 : 0,
  })

  return polygonStyle
}

export const createLineStyle = ({ visible = false, disabled = false, isSelected = false, isHovered = false, editing = false } = {}) => {
  let lineWidth = mapPalette.lineWidth.large
  let strokeColor = mapPalette.lineStrokeColor.default

  if (isHovered) {
    lineWidth = mapPalette.lineWidth.large
    strokeColor = mapPalette.lineStrokeColor.hover
  }
  if (isSelected) {
    lineWidth = mapPalette.lineWidth.large
    strokeColor = mapPalette.lineStrokeColor.selected
  }
  if (disabled) {
    lineWidth = mapPalette.lineWidth.default
    strokeColor = mapPalette.lineStrokeColor.disabled
  }
  if (editing) {
    lineWidth = mapPalette.lineWidth.large
    strokeColor = mapPalette.lineStrokeColor.editing
  }
  const polygonStyle = new Style({
    stroke: visible
      ? new Stroke({
          color: strokeColor,
          width: lineWidth,
        })
      : undefined,
  })

  return polygonStyle
}

export const createVertexStyle = () => {
  const vertexStyle = new Style({
    image: new Circle({
      radius: 4,
      fill: new Fill({
        color: mapPalette.vertexFillColor.editing,
      }),
    }),
    geometry: (feature: any) => {
      const geometry = feature.getGeometry()

      if (geometry instanceof LineString) {
        return new MultiPoint(geometry.getCoordinates())
      }

      if (geometry instanceof MultiLineString) {
        const coordinates = geometry.getCoordinates().flat()
        return new MultiPoint(coordinates)
      }

      return new MultiPoint(geometry.getCoordinates()[0])
    },
    zIndex: 1, // Always selected when this style applied
  })

  return vertexStyle
}

export enum FeatureProperties {
  Hover = 'mz-hover',
  Selected = 'mz-selected',
  Visible = 'mz-visible',
  Disabled = 'mz-disabled',
  Editing = 'mz-editing',
  HideLabel = 'hide-label',
  TransparentBackGround = 'mz-transparent-bg',
  Linked = 'isLinked',
}

export const styleFunctionMapFeatures = (feature: FeatureLike): Style | Style[] | undefined => {
  const isHovered = feature.get(FeatureProperties.Hover)
  const isSelected = feature.get(FeatureProperties.Selected)
  const visible = feature.get(FeatureProperties.Visible)
  const disabled = feature.get(FeatureProperties.Disabled)
  const editing = feature.get(FeatureProperties.Editing)
  const hideLabel = feature.get(FeatureProperties.HideLabel)
  const transparentBg = feature.get(FeatureProperties.TransparentBackGround)

  const geometryType = feature.getGeometry()?.getType()
  if (!geometryType) return [new Style(), new Style()]

  const id = feature.get('plotKey')

  const labelStyle = new Style({
    text: new Text({
      font: '13px Calibri,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
      overflow: true,
    }),
  })
  if (visible && !editing && !disabled && !hideLabel) {
    labelStyle.getText()!.setText(id)
  }

  const vertexStyle = createVertexStyle()

  switch (geometryType) {
    case 'Polygon':
    case 'MultiPolygon':
      const polygonStyle = createPolygonStyle({ disabled, isHovered, isSelected, visible, editing, transparentBg })
      return editing ? [labelStyle, polygonStyle, vertexStyle] : [labelStyle, polygonStyle]
    case 'LineString':
    case 'MultiLineString':
      const lineStyle = createLineStyle({ disabled, isHovered, isSelected, visible, editing })
      return editing ? [labelStyle, lineStyle, vertexStyle] : [labelStyle, lineStyle]
    default:
      return [new Style(), new Style()]
  }
}

export const styleFunctionCheckpoints = (feature: FeatureLike): Style | Style[] | undefined => {
  const isSelected = feature.get(FeatureProperties.Selected)
  const isLinked = feature.get(FeatureProperties.Linked)
  const visible = feature.get(FeatureProperties.Visible)
  const geometryType = feature.getGeometry()?.getType()

  let src
  if (isSelected) {
    src = CPMarkerSelected
  } else if (isLinked) {
    src = CPMarkerOutstanding
  } else {
    src = CPMarkerDisabled
  }

  if (geometryType === 'Point' || geometryType === 'MultiPoint') {
    return new Style({
      image: new Icon({
        src: src,
        rotateWithView: true,
        scale: visible ? 0.7 : 0,
      }),
      zIndex: isSelected ? 2 : 1,
    })
  }
}

export const styleProjectBoundary = (feature: FeatureLike): Style | Style[] | undefined => {
  const visible = feature.get(FeatureProperties.Visible)
  const geometryType = feature.getGeometry()?.getType()

  switch (geometryType) {
    case 'Polygon':
    case 'MultiPolygon':
      const polygonStyle = new Style({
        fill: visible ? new Fill({ color: 'transparent' }) : undefined,
        stroke: visible
          ? new Stroke({ color: mapPalette.boundaryStrokeColor.default, width: mapPalette.strokeWidth.large, lineDash: [4, 4] })
          : undefined,
      })
      return [new Style({}), polygonStyle]
    case 'LineString':
    case 'MultiLineString':
      const lineStyle = new Style({
        stroke: visible
          ? new Stroke({
              color: mapPalette.boundaryStrokeColor.default,
              width: mapPalette.strokeWidth.large,
            })
          : undefined,
      })
      return [new Style({}), lineStyle]
    default:
      return [new Style(), new Style()]
  }
}

export const styleDrawFeature = (feature: FeatureLike) => {
  const measurement = feature.get('measurement') || ''

  return new Style({
    stroke: new Stroke({
      color: mapPalette.polygonStrokeColor.default,
      width: mapPalette.strokeWidth.large,
    }),
    fill: new Fill({
      color: mapPalette.polygonFillColor.default,
    }),
    text: new Text({
      text: measurement,
      font: '13px Calibri,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
      overflow: true,
    }),
  })
}

export const styleFunctionReports = (feature: FeatureLike): Style | Style[] | undefined => {
  const geometryType = feature.getGeometry()?.getType()
  const isHovered = feature.get(FeatureProperties.Hover)
  const featureColor = feature.get('featureColour')
  const featureText = feature.get('featureValue')

  const labelStyle = new Style({
    text: new Text({
      text: isHovered ? featureText : '',
      font: 'bold 13px Calibri,sans-serif',
      offsetY: -15,
      fill: new Fill({
        color: '#000',
      }),
      overflow: true,
    }),
  })

  switch (geometryType) {
    case 'Polygon':
    case 'MultiPolygon':
      const polygonStyle = new Style({
        fill: new Fill({ color: isHovered ? mapPalette.reportStrokeColor.hover : featureColor }),
        stroke: new Stroke({ color: mapPalette.reportStrokeColor.default, width: mapPalette.reportStrokeWidth.polygon }),
      })
      return [labelStyle, polygonStyle]
    case 'LineString':
    case 'MultiLineString':
      const lineStyle = new Style({
        stroke: new Stroke({
          color: isHovered ? mapPalette.reportStrokeColor.hover : featureColor,
          width: mapPalette.reportStrokeWidth.line,
        }),
      })
      return [labelStyle, lineStyle]

    case 'Point':
      const pointStyle = new Style({
        image: new Circle({
          radius: 6,
          fill: new Fill({ color: isHovered ? mapPalette.reportStrokeColor.hover : featureColor }),
          stroke: new Stroke({ color: mapPalette.reportStrokeColor.default, width: mapPalette.reportStrokeWidth.point }),
        }),
      })
      return [labelStyle, pointStyle]

    default:
      return [new Style(), new Style()]
  }
}
