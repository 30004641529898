import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material'

const MuiTab: {
  defaultProps: ComponentsProps['MuiTabs']
  styleOverrides: ComponentsOverrides<Theme>['MuiTabs']
  variants?: ComponentsVariants['MuiTabs']
} = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.blue['500'],
      },
    }),
  },
}

export default MuiTab
