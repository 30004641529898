import type { SVGProps } from 'react'
const SvgIconTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="m8.327 2.016-.742 1.109h5.664l-.742-1.11a.32.32 0 0 0-.262-.14h-3.66a.31.31 0 0 0-.262.14zM14.07.976l1.434 2.149h2.727c.519 0 .937.418.937.938 0 .519-.418.937-.937.937h-.313v11.875A3.124 3.124 0 0 1 14.792 20h-8.75a3.124 3.124 0 0 1-3.125-3.125V5h-.313a.935.935 0 0 1-.937-.937c0-.52.418-.938.937-.938h2.727L6.765.973A2.2 2.2 0 0 1 8.585 0h3.66c.73 0 1.414.367 1.82.973zM4.792 5v11.875c0 .691.559 1.25 1.25 1.25h8.75c.691 0 1.25-.559 1.25-1.25V5zm3.125 2.5v8.125a.627.627 0 0 1-.625.625.627.627 0 0 1-.625-.625V7.5c0-.344.281-.625.625-.625s.625.281.625.625m3.125 0v8.125a.627.627 0 0 1-.625.625.627.627 0 0 1-.625-.625V7.5c0-.344.281-.625.625-.625s.625.281.625.625m3.125 0v8.125a.627.627 0 0 1-.625.625.627.627 0 0 1-.625-.625V7.5c0-.344.281-.625.625-.625s.625.281.625.625"
    />
  </svg>
)
export default SvgIconTrash
