import { Box, BoxProps } from '@components/atom'
import { styled } from '@mui/material/styles'

export const PageLoadingStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'transparent',
})<BoxProps & { transparent?: boolean }>(({ transparent, theme }) => ({
  backgroundColor: transparent ? 'rgba(0,0,0,0.3)' : theme.palette.blue[800],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  zIndex: 999,
}))
