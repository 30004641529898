const gambogeYellow = {
  100: '#FEF3C7',
  200: '#FDE68A',
  300: '#FCD34D',
  400: '#FBBF24',
  500: '#F59E0B', // Main color
  600: '#D28809',
  700: '#AF7107',
  800: '#694404',
  900: '#462D03',
}

export default gambogeYellow
