import { IBaseResponse } from '@models/common.model'
import { IDeleteRoleUserProject, IUpdateRoleUserOrg, IUpdateRoleUserProject, IUserRoleMapping } from '@models/userRole.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'

export class UserRoleService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchUserRoleMappings = async (): Promise<IBaseResponse<IUserRoleMapping | null>> => {
    try {
      const user = await this.client.auth.getUser()
      const { data: projectUserRows } = await this.client
        .from('projects_users')
        .select('project_id, role')
        .eq('user_id', user.data.user?.id)
      const { data: organisationUserRows } = await this.client
        .from('organisations_users')
        .select('organisation_id, role')
        .eq('user_id', user.data.user?.id)
      const organisationRoles = Object.fromEntries(organisationUserRows?.map(({ organisation_id, role }) => [organisation_id, role]) || [])
      const organisationIds = Object.keys(organisationRoles)
      const { data: organisationProjects } = await this.client
        .from('projects')
        .select('id, organisation_id')
        .in('organisation_id', organisationIds)
        .is('deleted_at', null)
      // Role hierarchy
      const rolePriority: Record<string, number> = { collector: 0, member: 1 }
      // Assign roles from project_users
      const projectRoles = Object.fromEntries(projectUserRows?.map(({ project_id, role }) => [project_id, role]) || [])
      organisationProjects?.forEach(({ id: project_id, organisation_id }) => {
        const orgRole = organisationRoles[organisation_id]
        if (orgRole && (!projectRoles[project_id] || rolePriority[orgRole] > rolePriority[projectRoles[project_id]])) {
          projectRoles[project_id] = orgRole
        }
      })
      return { data: { organisationRoles, projectRoles }, error: null }
    } catch (error) {
      return { data: null, error: error }
    }
  }

  changeRoleUserOrg = async (request: IUpdateRoleUserOrg): Promise<IBaseResponse<any>> => {
    const payload = {
      role: request.role,
    }
    const { data: organisationUser, error } = await this.client
      .from('organisations_users')
      .update(payload)
      .eq('organisation_id', request.organisationId)
      .eq('user_id', request.userId)
      .select()
      .single()
    return { data: organisationUser, error: error }
  }

  changeRoleUserProject = async (request: IUpdateRoleUserProject): Promise<IBaseResponse<any>> => {
    const payload = {
      role: request.role,
    }
    const { data: projectUser, error } = await this.client
      .from('projects_users')
      .update(payload)
      .eq('project_id', request.projectId)
      .eq('user_id', request.userId)
      .select()
      .single()
    return { data: projectUser, error: error }
  }

  assignHigherRole = async (request: IUpdateRoleUserProject): Promise<IBaseResponse<any>> => {
    const payload = {
      project_id: request.projectId,
      role: request.role,
      user_id: request.userId,
    }
    const { data: projectUser, error } = await this.client.from('projects_users').insert(payload).select().single()
    return { data: projectUser, error: error }
  }

  removeUserRole = async (request: IDeleteRoleUserProject): Promise<IBaseResponse<any>> => {
    const { data: projectUser, error } = await this.client
      .from('projects_users')
      .delete()
      .eq('project_id', request.projectId)
      .eq('user_id', request.userId)
    return { data: projectUser, error: error }
  }
}

const userRoleService = new UserRoleService()

export default userRoleService
