import { Helmet } from 'react-helmet-async'
import React, { Suspense } from 'react'
import PageViewAnalyticsHandler from './MainLayout/PageeViewAnalyticsHandler'

export const PublicRoute = ({ title, children }: { title: string; children: any }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageViewAnalyticsHandler />
      <Suspense fallback={null}>{children}</Suspense>
    </>
  )
}

export default React.memo(PublicRoute)
