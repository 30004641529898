import { Box, BoxProps } from '@components/atom'
import { styled, SxProps, Theme } from '@mui/material'

export const subContainer: SxProps = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '24px',
  padding: ' 0 8px',
}
export const subContainerBottom: SxProps = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '4px',
}
export const userEmail: SxProps = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '150px',
}

export const StyledBox = styled(Box)<BoxProps & { isSideBarCollapse: boolean; toggleWidth: number }>(
  ({ theme, isSideBarCollapse, toggleWidth }) => ({
    zIndex: theme.zIndex.sideBar,
    width: toggleWidth,
    flexShrink: 0,
    ...(isSideBarCollapse && {
      position: 'absolute',
    }),
  }),
)

export const getPaperStyle = (toggleWidth: number): SxProps<Theme> => ({
  transition: theme =>
    theme.transitions.create('width', {
      duration: theme.transitions.duration.shortest,
    }),
  width: toggleWidth,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#1C3E44',
  borderRight: '1px solid #E4E7EC',
  padding: '16px 0',
  zIndex: theme => theme.zIndex.sideBar,
})
