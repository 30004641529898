import _ from 'lodash'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import { FeatureCollection } from 'geojson'
import { getArea, getLength } from 'ol/sphere.js'
import { Geometry } from 'geojson'

import { ICheckpointLocationWithState } from '@models/checkpoint.model'
import { IProject } from '@models/projects.model'

import { FeatureProperties } from './open-layer/open-layer.styles'
import { PROJECTION } from '@context/ProjectDetailContext/ProjectDetailContext.const'
import { IMapFeature } from '@models/mapFeature.model'

export const PolygonVariants = {
  yellow: {
    fill: {
      'fill-color': '#F59E0B',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#F59E0B',
      'line-width': 4,
    },
  },
  green: {
    fill: {
      'fill-color': '#10B981',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#10B981',
      'line-width': 4,
    },
  },
  white: {
    fill: {
      'fill-color': '#FFFFFF',
      'fill-opacity': 0.5,
    },
    outlined: {
      'line-color': '#FFFFFF',
      'line-width': 4,
    },
  },
  blue: {
    fill: {
      'fill-color': '#1788D4',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#1788D4',
      'line-width': 4,
    },
  },
  gray: {
    fill: {
      'fill-color': '#8D959B',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#8D959B',
      'line-width': 4,
    },
  },
  red: {
    fill: {
      'fill-color': '#F37272',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#F37272',
      'line-width': 4,
    },
  },
  lightGreen: {
    fill: {
      'fill-color': '#10B981',
      'fill-opacity': 0.25,
    },
    outlined: {
      'line-color': '#10B981',
      'line-width': 4,
    },
  },
}

export const customPolygonPaintTransparent = {
  'fill-color': 'transparent',
  'fill-opacity': 1,
}

export const customPolygonOutlinedPaint = {
  'line-color': ['case', ['boolean', ['get', 'isSelect'], false], '#FFF', PolygonVariants.blue.outlined['line-color']],
  'line-width': 4,
}

export enum DefaultSourceId {
  PlotBoundaries = 'plot-boundaries',
  Checkpoints = 'checkpoints',
}

export enum CheckpointStates {
  UNLINKED = 'unlinked-checkpoint',
  LINKED = 'linked-checkpoint',
  SELECTED = 'selected-checkpoint',
}

export const convertFeaturesToGeoJson = (mapFeatures: IMapFeature[]): GeoJSON.FeatureCollection | null => {
  if (_.isEmpty(mapFeatures)) return null

  const geoJsonFormat = new GeoJSON()

  const features = mapFeatures.map(mapFeature => {
    const geometry = geoJsonFormat.readGeometry(mapFeature.geometry)
    const mode = mapFeature.geometry.type.includes('LineString') ? 'linestring' : 'polygon'

    const feature = new Feature({
      geometry,
      mode,
      properties: mapFeature.properties,
      id: mapFeature.id,
      plotKey: mapFeature.plotKey,
      projectId: mapFeature.projectId,
    })

    return geoJsonFormat.writeFeatureObject(feature)
  })

  return {
    type: 'FeatureCollection',
    features,
  }
}

export const convertCheckpointToGeoJson = (checkpoints?: ICheckpointLocationWithState[], selectedId?: string): FeatureCollection => {
  if (!checkpoints || _.isEmpty(checkpoints)) return { type: 'FeatureCollection', features: [] }
  if (checkpoints.length === 0) return { type: 'FeatureCollection', features: [] }

  const geoJsonFormat = new GeoJSON()

  const features = checkpoints.map(checkpoint => {
    const geometry = geoJsonFormat.readGeometry(checkpoint.geometry)
    const feature = new Feature({
      geometry,
      id: checkpoint.checkpointId,
      mode: 'checkpoint',
      [FeatureProperties.Selected]: selectedId === checkpoint.checkpointId,
      [FeatureProperties.Linked]: !!checkpoint.isLinked,
      [FeatureProperties.Visible]: true,
    })

    return geoJsonFormat.writeFeatureObject(feature)
  })

  return {
    type: 'FeatureCollection',
    features,
  }
}

export const convertProjectBoundaryToGeoJson = (project: IProject) => {
  if (!project) return null

  const geoJsonFormat = new GeoJSON()
  const geometry = geoJsonFormat.readGeometry(project.boundary?.geometry)
  const features = [
    geoJsonFormat.writeFeatureObject(
      new Feature({
        geometry,
        id: project.boundary?.id,
        mode: 'polygon',
        [FeatureProperties.Visible]: true,
        project: {
          id: project.id,
          objective: project.objective,
        },
      }),
    ),
  ]
  return {
    type: 'FeatureCollection',
    features,
  }
}

export const calculateMetadata = (geometry: Geometry) => {
  try {
    const geometryObject = new GeoJSON().readGeometry(geometry, {
      dataProjection: PROJECTION.GEOGRAPHIC_COORDINATES,
      featureProjection: PROJECTION.WEB_MERCATOR,
    })
    const geom = new Feature(geometryObject).getGeometry()!

    const type = geom.getType()
    if (type === 'LineString' || type === 'MultiLineString') {
      return `Length: ${getLength(geom).toFixed(1)} m`
    } else if (type === 'Polygon' || type === 'MultiPolygon') {
      return `Area: ${(getArea(geom) / 10000).toFixed(1)} ha`
    }
  } catch {
    // Do nothing on error
  }
  return ''
}
