import { ComponentsOverrides, Theme } from '@mui/material'

const MuiTextField: {
  styleOverrides: ComponentsOverrides<Theme>['MuiTextField']
} = {
  styleOverrides: {
    root: () => ({
      '.MuiInputBase-root': {
        borderRadius: '4px',
      },
      input: {
        padding: '15px 14px',
      },
    }),
  },
}

export default MuiTextField
