import { IProject } from '@models/projects.model'
import projectService from '@services/ProjectService/ProjectService'
import { useQuery } from '@tanstack/react-query'

const useQueryProjects = ({ onSuccess }: { onSuccess?: (projects: IProject[]) => void }) => {
  const projectQuery = useQuery({
    queryKey: ['fetch-projects'],
    queryFn: () =>
      projectService
        .fetchAllProjects()
        .then(res => {
          onSuccess?.(res.data ?? [])
          return res.data ?? []
        })
        .catch(() => []),
    refetchOnMount: false,
    enabled: false,
  })

  return projectQuery
}

export default useQueryProjects
