import { ButtonProps } from '@components/atom'
import Popover from '@components/atom/Popover/Popover'
import PopoverActions from '@components/atom/Popover/PopoverActions'
import PopoverContent from '@components/atom/Popover/PopoverContent'
import PopoverHeader from '@components/atom/Popover/PopoverHeader'
import { TextAreaFormField } from '@components/molecule'
import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
export type NoteAnnotationPopoverProps = {
  mode: 'create' | 'edit'
  open: boolean
  anchorElRef: any
  onClose: any
  onCreate: (values: FieldValues) => void
  onUpdate: (values: FieldValues) => void
  defaultValues?: FieldValues
}

type FieldValues = {
  id?: string
  note: string
}

export const NoteAnnotationPopover = ({
  mode,
  defaultValues,
  open,
  anchorElRef,
  onClose,
  onUpdate,
  onCreate,
}: NoteAnnotationPopoverProps) => {
  const formContext = useForm<FieldValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues ?? {
      id: uuidv4(),
      note: '',
    },
  })

  const buttons: ButtonProps[] = useMemo(() => {
    if (mode === 'create') {
      return [
        {
          children: 'Create (enter)',
          variant: 'contained',
          size: 'small',
          color: 'secondary',
          fullWidth: true,
          type: 'submit',
        },
      ]
    }
    return [
      {
        children: 'Save (enter)',
        variant: 'contained',
        size: 'small',
        color: 'secondary',
        fullWidth: true,
        type: 'submit',
      },
    ]
  }, [mode])

  const onSubmit = () => {
    const values = formContext.getValues()
    if (mode === 'create') {
      return onCreate(values)
    }
    return onUpdate(values)
  }

  return (
    <Popover contentProps={{ sx: { minWidth: 300 } }} anchorEl={anchorElRef.current} open={open} onClose={onClose}>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <PopoverHeader onClose={onClose}>{mode === 'create' ? 'Add a note' : 'Edit note'}</PopoverHeader>
          <PopoverContent>
            <TextAreaFormField
              name="note"
              onSubmit={formContext.handleSubmit(onSubmit)}
              textFieldProps={{
                placeholder: 'Add your note here',
                minRows: 4,
                maxRows: 6,
                inputProps: {
                  maxLength: 500,
                },
                autoFocus: true,
              }}
            />
          </PopoverContent>
          <PopoverActions buttons={buttons} />
        </form>
      </FormProvider>
    </Popover>
  )
}

export default NoteAnnotationPopover
