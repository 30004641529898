import { SxProps, Theme } from '@mui/material'
import { hiddenScrollBar } from '@styles/theme/sx/common'

export const mapSidebarContainerWidth = 354

export const mapSidebarContainerWidth2 = 400

export const container = (isCollapse: boolean, isOnly?: boolean): SxProps => ({
  height: 'calc(100vh - 64px)',
  width: mapSidebarContainerWidth,
  boxShadow: isOnly ? '0px 0px 12px rgba(0, 0, 0, 0.12)' : 'none',
  borderRadius: 8,
  zIndex: 1,
  position: 'absolute',
  top: 32,
  left: isCollapse ? -(mapSidebarContainerWidth + 32) : 32,
  transition: 'left 0.8s ease',
})

export const container2 = (isCollapse: boolean): SxProps => ({
  height: 'calc(100vh - 80px)',
  width: isCollapse ? 0 : mapSidebarContainerWidth2,
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
  transition: 'width 0.3s ease',
  position: 'relative',
  '> div.MuiStack-root': {
    width: isCollapse ? 0 : mapSidebarContainerWidth2,
    overflow: 'hidden',
    transition: 'opacity 0.1s ease',
    opacity: isCollapse ? 0 : 1,
  },
})

export const sideBarContent: SxProps<Theme> = {
  height: '100%',
  width: '100%',
  flexGrow: 1,
  transition: 'height 0.3s ease',
  borderRight: '1px solid',
  borderColor: 'divider',
  position: 'relative',
}

export const header2: SxProps = {
  position: 'relative',
  padding: '12px 18px',
  width: '100%',
  height: 60,
  backgroundColor: 'white',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const header: SxProps = {
  position: 'relative',
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  padding: '12px 18px',
  color: 'grey.50',
  width: '100%',
  height: 60,
  backgroundColor: 'blue.800',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const itemFirst: SxProps = {
  color: 'grey.800',
  backgroundColor: 'white',
  width: '100%',
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  overflowY: 'auto',
  flex: 1,
  ...hiddenScrollBar,
}

export const openButton = (isCollapse: boolean): SxProps => ({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  color: 'white',
  position: 'absolute',
  right: -86,
  paddingY: '18px',
  backgroundColor: 'blue.800',
  visibility: isCollapse ? 'visible' : 'hidden',
  opacity: 0,
  animation: isCollapse ? 'fadeIn 0.1s ease-out 0.5s forwards' : 'none',
  '&:hover': {
    backgroundColor: 'blue.600',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
})

export const openButton2 = (isCollapse: boolean): SxProps => ({
  position: 'absolute',
  top: 0,
  right: -62,
  backgroundColor: 'white',
  visibility: isCollapse ? 'visible' : 'hidden',
  opacity: 0,
  borderRadius: 0,
  animation: isCollapse ? 'fadeIn 0.1s ease-out 0.5s forwards' : 'none',
  '&:hover': {
    backgroundColor: 'grey.300',
    cursor: 'pointer',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
})
