import { Avatar, Box, IconButton, Icons, TextField } from '@components/atom'
import { IconOpenSideBar } from '@icons/index'
import { useEffect, useState } from 'react'
import { InputAdornment } from '@mui/material'
// import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Divider, StyledAppBar, StyledToolbar, topBarButtons, topBarGreetings } from './TopBar.style'
import { useLocation } from 'react-router-dom'
import useGlobalSelector from '@context/GlobalContext/hooks/useGlobalSelector'
import useGlobalContext from '@context/GlobalContext/hooks/useGlobalContext'
import { ACTIONS } from '@context/GlobalContext/GlobalReducer'
import AppBreadcrumb from '../Breadcrumb/Breadcrumb'
import { COLLAPSE_SIDE_BAR_WIDTH, SIDE_BAR_WIDTH } from '@components/template/MainLayout/MainLayout.style'

export type TopBarProps = {
  greetings: React.ReactNode | string
  userProfile: any
  onClickLogout: () => void
  logoutLoading: boolean
  handleToggleSideBar: (state: boolean) => void
  isSideBarCollapse: boolean
  isBoxShadow: boolean
}

const TopBar = ({ greetings, userProfile, handleToggleSideBar, isSideBarCollapse, isBoxShadow }: TopBarProps) => {
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const location = useLocation()
  const { dispatch } = useGlobalContext()

  const isInSubPage = location.pathname.split('/').length > 2
  const breadcrumbItems = useGlobalSelector(state => state.breadcrumbItems)

  useEffect(() => {
    if (!isInSubPage) {
      dispatch({
        type: ACTIONS.SET_BREADCRUMB,
        payload: { breadcrumbItems: [] },
      })
    }
  }, [isInSubPage])

  const sideBarWidth = isSideBarCollapse ? COLLAPSE_SIDE_BAR_WIDTH : SIDE_BAR_WIDTH
  const handleToggleSearching = () => {
    setIsSearching(prev => !prev)
  }

  return (
    <StyledAppBar position="sticky">
      <StyledToolbar disableGutters width={sideBarWidth} isBoxShadow={isBoxShadow}>
        {isSearching ? (
          <Box sx={topBarGreetings}>
            <TextField
              variant="standard"
              placeholder="Search something..."
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleSearching} edge="end" size="small">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputBase-root': {
                  border: 'none',
                },
              }}
            />
          </Box>
        ) : (
          <Box sx={topBarGreetings}>
            <IconButton
              onClick={() => handleToggleSideBar(!isSideBarCollapse)}
              sx={{
                transform: !isSideBarCollapse ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              disableRipple
            >
              <Icons Icon={IconOpenSideBar} color="grey.500" />
            </IconButton>
            <Divider />
            {isInSubPage && breadcrumbItems?.length > 0 ? <AppBreadcrumb items={breadcrumbItems} /> : <>{greetings}</>}
          </Box>
        )}
        <Box sx={topBarButtons}>
          {/* {!isSearching ? (
            <IconButton disableRipple onClick={handleToggleSearching}>
              <SearchOutlinedIcon />
            </IconButton>
          ) : (
            <Divider />
          )} */}
          {/* <IconButton disableRipple onClick={() => {}}>
            <SmsOutlinedIcon />
          </IconButton>
          <IconButton disableRipple onClick={() => {}}>
            <NotificationsOutlinedIcon />
          </IconButton> */}
          <Avatar avatarVariant="first-character" name={userProfile?.firstName || 'Default'} />
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default TopBar
