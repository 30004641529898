import { TextField } from '@components/atom'
import { styled } from '@mui/material/styles'

export const SearchField = styled(TextField)(({ theme }) => ({
  '&.MuiFormControl-root': {
    background: 'white',
  },
  '.MuiInputAdornment-positionStart': {
    color: theme.palette.text.primary,
  },
}))
