import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiTableRow: {
  defaultProps: ComponentsProps['MuiTableRow']
  styleOverrides: ComponentsOverrides<Theme>['MuiTableRow']
} = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: '1px solid',
      borderColor: theme.palette.divider,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey['50'],
      },
      '&:last-child': {
        borderBottom: 'none',
      },
    }),
  },
}

export default MuiTableRow
