import { SxProps, Theme } from '@mui/material'

export const menuContainer: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '8px',
  '&:hover': {
    backgroundColor: 'teal.800',
  },
}

export const paperStyle: SxProps = {
  minWidth: '300px',
  borderRadius: 0,
  backgroundColor: 'teal.800',
  padding: 1,
}

export const popupContainer: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'teal.800',
  cursor: 'pointer',
}

export const popupItem: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: 1,
}

export const menuInfo: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: 1,
}

export const orgName: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '200px',
  color: 'common.white',
}

export const selectedIcon: SxProps = { width: '20px', height: '20px', color: 'common.white', marginRight: 1 }
