import { Box, Card } from '@components/atom'
import { styled } from '@mui/material'

export const LevelBar = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(180deg, #136d34 0%, #22c55e 49.5%, #ddf9e7 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
`

export const CardLevel = styled(Card)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  border: ${({ selected, theme }) => `2px solid ${selected ? theme.palette.blue['500'] : theme.palette.divider}`};
  background-color: ${({ selected }) => (selected ? '#e3f2fd' : 'white')};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 16px; /* Use padding instead of p for consistency */
`
