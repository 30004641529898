const cornflowerBlue = {
  50: 'rgba(220, 238, 251, 0.5)', // additional color
  100: '#DCEEFB',
  200: '#90CCF3',
  300: '#62B6EE',
  400: '#34A1E9',
  500: '#1788D4', // Main color
  600: '#126BA6',
  700: '#0D4D78',
  800: '#082F4A',
  900: '#03121C',
}

export default cornflowerBlue
