import { SxProps } from '@mui/material'
import light from '@styles/theme/light'
import { hiddenScrollBar } from '@styles/theme/sx/common'

export const labelSearchSidebarContainerWidth = 328

export const header: SxProps = {
  position: 'relative',
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  padding: '12px 18px',
  color: 'grey.50',
  width: '100%',
  height: 60,
  backgroundColor: 'blue.800',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const background: SxProps = {
  color: 'grey.800',
  backgroundColor: 'white',
  width: '100%',
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  overflowY: 'auto',
  flex: 1,
  ...hiddenScrollBar,
}

export const openButton = (expanded: boolean): SxProps => ({
  width: 48,
  height: 48,
  minWidth: 48,
  borderRadius: '50%',
  color: 'white',
  position: 'absolute',
  top: 'calc(80px + 24px)',
  right: '24px',
  paddingY: '18px',
  backgroundColor: 'white',
  visibility: expanded ? 'hidden' : 'visible',
  opacity: 0,
  animation: expanded ? 'none' : 'fadeIn 0.1s ease-out 0.3s forwards',
  zIndex: light.zIndex.sideBar,
  '&:hover': {
    backgroundColor: 'white',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
})

export const title: SxProps = {
  width: '100%',
  padding: '24px',
  alignItems: 'center',
  justifyContent: 'space-between',
}
