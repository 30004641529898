import { Box, Drawer, Render, Typography } from '@components/atom'
import BrandLogo from '@components/molecule/BrandLogo/BrandLogo'
import MenuSideBar from '@components/molecule/MenuList'
import { SectionDivider } from '@components/molecule/MenuList/style'
import OrganisationMenu from '@components/organism/OrganisationMenu/OrganisationMenu'
import useGlobalSelector, { selectOrganisationList } from '@context/GlobalContext/hooks/useGlobalSelector'
import Divider from '@mui/material/Divider'

import { COLLAPSE_SIDE_BAR_WIDTH, SIDE_BAR_WIDTH } from '../MainLayout/MainLayout.style'
import { getPaperStyle, StyledBox, subContainer, subContainerBottom } from './Sidebar.styles'

export type SidebarProps = {
  isSideBarCollapse: boolean
}

const Sidebar = ({ isSideBarCollapse }: SidebarProps) => {
  const toggleWidth = isSideBarCollapse ? COLLAPSE_SIDE_BAR_WIDTH : SIDE_BAR_WIDTH
  const organisations = useGlobalSelector(state => selectOrganisationList(state.projectList, state.userRoleMappings))
  const selectedOrgId = useGlobalSelector(state => state.selectedOrganisationId)

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledBox isSideBarCollapse={isSideBarCollapse} toggleWidth={toggleWidth}>
        <Drawer
          id="side-bar-drawer"
          anchor="left"
          variant="permanent"
          PaperProps={{
            sx: getPaperStyle(toggleWidth),
          }}
        >
          <Box sx={subContainer}>
            <BrandLogo isSideBarCollapse={isSideBarCollapse} />
            <MenuSideBar isSideBarCollapse={isSideBarCollapse} />
          </Box>
          <Render test={organisations?.length !== 0} fallback={null}>
            <Box sx={subContainerBottom}>
              {isSideBarCollapse ? (
                <Divider sx={SectionDivider} />
              ) : (
                <Typography sx={{ color: 'text.secondary', fontWeight: 700, marginLeft: '24px' }}>Organisation</Typography>
              )}
              <OrganisationMenu isCollapse={isSideBarCollapse} organisations={organisations} selectedOrgId={selectedOrgId} />
            </Box>
          </Render>
        </Drawer>
      </StyledBox>
    </Box>
  )
}

export default Sidebar
