import { IBaseResponse } from '@models/common.model'
import { TileLayerDto } from '@models/tileLayer.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'

export class TileLayerService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchTileLayersByProjectId = async (projectId: string): Promise<IBaseResponse<TileLayerDto[] | null>> => {
    try {
      const response = await this.client.from('tile_layers').select('*').eq('project_id', projectId)
      if (response.error) {
        return { data: null, error: response?.error }
      }

      const tileLayers: TileLayerDto[] = response.data.map((tileLayer: any) => new TileLayerDto(tileLayer))
      return { data: tileLayers, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchTileImage = async (src: string): Promise<any> => {
    try {
      const response = await this.axios.get(src, {
        responseType: 'blob',
      })

      if (!response) {
        throw new Error(`Failed to load tile: ${JSON.stringify(response)}`)
      }

      const blob = response.data
      return URL.createObjectURL(blob)
    } catch (error) {
      console.error('Error fetching tile image:', error)
      throw error
    }
  }
}

const tileLayerService = new TileLayerService()

export default tileLayerService
