import useMozaicSnackbar from '@hooks/useMozaicSnackbar/useMozaicSnackbar.hook'
import mapFeatureService from '@services/MapFeatureService/MapFeatureService'
import { useMutation } from '@tanstack/react-query'

export const useDeleteMapFeaturesMutation = ({ onSuccess }: { onSuccess: (ids: string[]) => void }) => {
  const { enqueueSuccessSnackbar } = useMozaicSnackbar()

  const { mutateAsync: deleteMapFeatures } = useMutation({
    mutationKey: ['delete_map_features'],
    mutationFn: mapFeatureService.delete,
    onSuccess: (_, ids) => {
      enqueueSuccessSnackbar('Successfully deleted map features')
      onSuccess?.(ids)
    },
  })

  return {
    deleteMapFeatures,
  }
}

export default useDeleteMapFeaturesMutation
