import { CircularProgress, IconButton as MuIconButton, IconButtonProps as MuiIconButtonProps, Tooltip, TooltipProps } from '@mui/material'

export type IconButtonProps = {
  children?: React.ReactNode
  loading?: boolean
  tooltipProps?: Omit<TooltipProps, 'children'>
} & MuiIconButtonProps

const IconButton = ({ children, loading, tooltipProps, ...rest }: IconButtonProps) => {
  if (tooltipProps && !loading) {
    return (
      <Tooltip {...tooltipProps}>
        <MuIconButton {...rest}>{loading ? <CircularProgress size={20} /> : children}</MuIconButton>
      </Tooltip>
    )
  }

  return (
    <MuIconButton disabled={loading} {...rest}>
      {loading ? <CircularProgress size={20} /> : children}
    </MuIconButton>
  )
}

export default IconButton
