import { IBaseResponse } from '@models/common.model'
import { IPlan } from '@models/plan.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'

export class PlanService extends MozaicSupebaseClient {
  fetchPlans = async ({ projectId }: { projectId: string }): Promise<IBaseResponse<IPlan[]>> => {
    try {
      const response = await this.client.from('plans').select('*').eq('project_id', projectId)

      if (response.error) {
        return { data: null, error: response?.error }
      }

      const plans = response.data.map(plan => this.toCamelCase(plan))
      return {
        data: plans,
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

const planService = new PlanService()

export default planService
