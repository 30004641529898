export const mapPalette = {
  polygonFillColor: {
    default: 'rgba(169, 214, 249, 0.7)', // Light Blue
    hover: 'rgba(120, 200, 240, 0.8)', // Sky Blue
    selected: 'rgba(42, 161, 152, 0.7)', // Teal
    disabled: 'rgba(211, 211, 211, 0.5)', // Light Grey
    editing: 'rgba(255, 217, 102, 0.1)', // Yellow
  },
  polygonStrokeColor: {
    default: 'rgb(96, 151, 193)', // Medium Grey
    hover: 'rgb(41, 128, 185)', // Bright Blue
    selected: 'rgb(0, 109, 111)', // Dark Teal
    disabled: 'rgb(178, 178, 178)', // Light Grey
    editing: 'rgb(230, 126, 34)', // Orange
  },
  vertexFillColor: {
    editing: 'rgb(230, 126, 34)', // Orange
  },
  lineStrokeColor: {
    default: 'rgb(96, 151, 193)', // Medium Grey
    hover: 'rgb(41, 128, 185)', // Bright Blue
    selected: 'rgb(0, 109, 111)', // Dark Teal
    disabled: 'rgb(178, 178, 178)', // Light Grey
    editing: 'rgba(255, 150, 0, 1)', // Orange
  },
  lineWidth: {
    default: 3,
    large: 4,
  },
  strokeWidth: {
    default: 1,
    large: 3,
  },
  boundaryStrokeColor: {
    default: '#f7517c',
  },
  reportStrokeColor: {
    default: 'black',
    hover: 'grey',
  },
  reportStrokeWidth: {
    polygon: 0.5,
    line: 2,
    point: 0.4,
  },
}

export type MapPalette = typeof mapPalette

export default mapPalette
