import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'
import Box from '../Box'
import './loading.css'

export type ButtonProps = {
  children?: React.ReactNode
  loading?: boolean
} & MuiButtonProps

const Button = ({ children, loading, startIcon, variant = 'contained', ...rest }: ButtonProps) => {
  const loadingComponent = <Box component="span" className="loader" />

  return (
    <MuiButton className="mozaic-button" startIcon={loading ? null : startIcon} variant={variant} {...rest}>
      {loading && (
        <>
          {loadingComponent}
          <div className="loading" />
        </>
      )}
      <span style={{ visibility: loading ? 'hidden' : 'unset' }}>{children}</span>
    </MuiButton>
  )
}

export default Button
