import { TGlobalState } from './GlobalState'
import { selectOrganisationList } from './hooks/useGlobalSelector'
import CookieService from '@utils/cookie'
import { COOKIE_KEYS } from '@utils/cookie/constants'

export const ACTIONS = {
  SET_PROJECT_LIST: 'SET_PROJECT_LIST',
  SET_SELECTED_ORGANISATION: 'SET_SELECTED_ORGANISATION',
  SET_EVENTS: 'SET_EVENTS',
  SET_BREADCRUMB: 'SET_BREADCRUMB',
  SET_GLOBAL_LOADING: 'SET_GLOBAL_LOADING',
  SET_USER_ROLE_MAPPINGS: 'SET_USER_ROLE_MAPPINGS',
  INIT_DATA_SUCCESS: 'INIT_DATA_SUCCESS',
}

const initData = (state: TGlobalState, action: any) => {
  const { projectList, userRoleMappings } = action.payload
  const organisationList = selectOrganisationList(projectList, userRoleMappings)
  let defaultOrg = organisationList[0]?.id ?? ''
  const cookieOrgId = CookieService.get(COOKIE_KEYS.DEFAULT_ORG)
  if (!!organisationList.find(o => o.id === cookieOrgId) && cookieOrgId) {
    defaultOrg = cookieOrgId
  }
  CookieService.set(COOKIE_KEYS.DEFAULT_ORG, defaultOrg)
  return {
    ...state,
    projectList: action.payload.projectList,
    userRoleMappings: userRoleMappings,
    selectedOrganisationId: defaultOrg,
  }
}

function GlobalReducer(state: TGlobalState, action: any): TGlobalState {
  switch (action.type) {
    case ACTIONS.INIT_DATA_SUCCESS:
      return initData(state, action)
    case ACTIONS.SET_PROJECT_LIST: {
      const { projectList } = action.payload
      const organisationList = selectOrganisationList(projectList, state.userRoleMappings)
      let defaultOrg = organisationList[0]?.id ?? ''
      const cookieOrgId = CookieService.get(COOKIE_KEYS.DEFAULT_ORG)
      if (!!organisationList.find(o => o.id === cookieOrgId) && cookieOrgId) {
        defaultOrg = cookieOrgId
      }
      CookieService.set(COOKIE_KEYS.DEFAULT_ORG, defaultOrg)
      return {
        ...state,
        projectList: action.payload.projectList,
        selectedOrganisationId: defaultOrg,
      }
    }
    case ACTIONS.SET_SELECTED_ORGANISATION:
      CookieService.set(COOKIE_KEYS.DEFAULT_ORG, action.payload.selectedOrganisationId)
      return { ...state, selectedOrganisationId: action.payload.selectedOrganisationId }
    case ACTIONS.SET_BREADCRUMB:
      return { ...state, breadcrumbItems: action.payload.breadcrumbItems }
    case ACTIONS.SET_GLOBAL_LOADING:
      return { ...state, isGlobalLoading: action.payload.isGlobalLoading }
    case ACTIONS.SET_EVENTS:
      return { ...state, events: { ...state.events, ...action.payload } }
    case ACTIONS.SET_USER_ROLE_MAPPINGS:
      return { ...state, userRoleMappings: action.payload.userRoleMappings }
    default:
      throw new Error('Unknown action: ' + action.type)
  }
}

export default GlobalReducer
