const cadetGrey = {
  50: 'rgba(244, 247, 247, 0.5)', // additional color
  100: '#F4F7F7',
  200: '#DEE1E3',
  300: '#C4C8CB',
  400: '#9DA4AA',
  500: '#8D959B', // Main color
  600: '#768088',
  700: '#5B6267',
  800: '#333333',
  900: '#202020',
}

export default cadetGrey
