import tinycolor from 'tinycolor2'

const baseColor = '#D7EBEF'

/**
 * @deprecated -> replace by moonstone teal
 */
const MozaicEarthTeal = {
  50: tinycolor(baseColor).lighten(40).toHexString(),
  100: '#B0D8DF',
  200: '#95CBD4',
  300: '#7BBDC9',
  400: '#61B0BE',
  500: '#49A1B1',
  600: '#3E8B98',
  700: '#34737F',
  800: '#245159',
  900: '#1C3E44',
  A100: tinycolor(baseColor).lighten(50).toHexString(),
  A200: tinycolor(baseColor).lighten(40).toHexString(),
  A400: tinycolor(baseColor).lighten(30).toHexString(),
  A700: tinycolor(baseColor).lighten(20).toHexString(),
}

export default MozaicEarthTeal
