import { Typography, TypographyProps } from '@components/atom'
import { styled } from '@mui/material/styles'

export const BreadcrumbTitle = styled(Typography)<TypographyProps & { isLastIndex: boolean }>(({ theme, isLastIndex }) => ({
  color: theme.palette.text.primary,
  fontWeight: isLastIndex ? 700 : 400,
  cursor: isLastIndex ? 'auto' : 'pointer',
  '&:hover': {
    textDecoration: isLastIndex ? 'none' : 'underline',
  },
}))
