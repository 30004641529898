import type { SVGProps } from 'react'
const SvgLogoContentVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 20}
    height={props.height || 20}
    fill="none"
    viewBox="0 0 292 104"
    {...props}
  >
    <g clipPath="url(#logo-content-vertical_svg__a)">
      <path
        fill="#EB8830"
        d="m98.815 33.423-.026-.022a3.3 3.3 0 0 0-1.217-.593c-1.026-.29-2.11-.126-2.538-.533-.377-.343-.217-1.075-.117-1.703.1-.594.143-1.2.013-1.773a3.25 3.25 0 0 0-1.507-2.127c-.832-.512-1.889-.507-2.76-.096-1.108.468-1.922 1.495-2.97 2.093-1.174.663-1.923-.806-2.664-1.503-1.32-1.196-1.286-1.716.169-2.713 1.304-.949 2.37-2.197 3.045-3.67 1.546-3.242-.931-5.963-3.643-8.312-.979-.836-4.816-4.281-5.894-3.9-.784.265-.71 1.47-.72 2.202-.038 1.824.62 2.964 2.028 4.069 1.381 1.07 1.819 1.737.524 3.029-2.456 2.543-2.976 3.709-.611 6.621.346.477.446 1.053.065 1.547-.234.33-.511.59-.78.888-.372.395-.671.88-.974 1.33-.81 1.3-1.59.174-2.322-.515-.935-.854-2.014-1.746-3.222-2.028-1.083-.208-2.677-.36-3.543.247-.628.503-.554 1.395-.476 2.128.078.62.177 1.239.216 1.867.065 2.25.17 3.55 2.174 4.888 1.43.871 2.707 1.955 3.916 3.12.654.516 1.45 1.656 2.352 1.517 1.156-.386.905-3.61.874-4.663.014-.411-.285-2.04.325-1.963.304.074.62.434.888.668.338.32.706.615 1.105.849 1.022.611 2.334.702 3.417 1.313 1.819.962 2.469 2.843 2.343 4.78.06.797.312.069.308-.239.103-.814-.156-1.846.697-2.305 1.888-.875 3.56-2.162 5.266-3.35.672-.442 1.39-.901 2.183-1.066 2.023-.307 1.499 2.626 2.088 3.822-.186.07-.33.182-.342.343-.095.026-.225.203-.377.242-.26.065-.541 0-.78.065-.654.191-.675 1.361-.935 1.911.052.074.1.078.138 0 .33-.62.585-1.573 1.222-1.768.585-.177 1.065-.186 1.564-.286.264.104.584.078.87-.052 1.802-.632 2.083-3.167.624-4.35zm-23.92-6.383v-.009s.069.048.095.074c.03.03-.083-.017-.096-.065m11.503 10.569V37.6q.006.002.013.009z"
      />
      <path
        fill="#187287"
        d="M55.119 78.875c-.433-.338-.823-.697-1.235-1.066-.762-.68-1.58-1.378-2.096-2.279-.498-.88-.714-1.885-1.317-2.708-.615-.91-1.498-1.586-2.377-2.223-.65-.546-1.308-1.066-2.136-1.391-.424-.165-.944-.369-1.377-.221-.45.173-.706.632-1.013.988-.304.398-.641.788-.828 1.339-.16.32-.164 1.434-.541 1.408-.386-.134-.823-.897-1.312-1.348-1.534-1.62-4.223-2.006-5.995-.563-1.697 1.374-1.853 4.03-.966 5.898 1.096 2.335 3.214 3.011 5.35 4.056.268.143.532.299.783.472.611.377 1.127 1.062 1.65.949.334-.173.33-.745.364-1.11.065-1.603.122-3.232.226-4.814.09-1.213.19-2.101 1.078-.498.46.776.988 1.469 1.616 2.097 1.502 1.543 4.188 1.842 5.405 3.753.303.46.51.975.64 1.512.118.39.165 1.114.321 1.344.044.026.07-.13.122-.408.073-.381.181-.918.333-1.308.494-1.175 1.854-1.95 2.94-2.47 1.231-.481 2.006-.182.382-1.383l-.026-.021z"
      />
      <path
        fill="#104D5C"
        d="M49.687 88.864a4.4 4.4 0 0 0-1.572-.923c-.771-.247-1.607-.265-2.378-.56-1.273-.48-2.3-1.533-2.824-2.786-.97-2.929-1.022-3.952-4.15-5.139-1.216-.481-2.338-1.053-3.26-1.924-1.43-1.452-2.145-2.301-2.093.572-.013 1.252.091 2.66-.753 3.679-.19.282-.425.602-.347.953.165.616.793.988 1.295 1.365 1.876 1.456 4.808 3.328 6.986 4.156 1.018.373 2.36.871 2.248 2.193-.069.819-.312 1.646-.143 2.474a3.95 3.95 0 0 0 1.776 2.695c2.425 1.526 5.505.286 6.289-2.457.697-1.711.173-3.05-1.053-4.277l-.017-.017z"
      />
      <path
        fill="#E55325"
        d="M52.164 20.18c.026.026.048.052.065.065s.009-.004-.013-.047c-.06-.096-.13-.217-.217-.29-.03 0-.039.064-.004.112.035.052.104.1.152.139l.021.017z"
      />
      <path
        fill="#E55325"
        d="M53.745 15.765c-.442-.265-.62-.789-.849-1.231-.576-1.088-1.117-2.154-1.516-3.306-.286-.754-.403-1.634-.78-2.31-.307-.576-.779-1.027-1.151-1.556-.875-1.356-1.75-2.747-2.989-3.8-3.101-2.739-4.972-2.31-8.636-1.1-1.499.55-4.855 1.317-5.739 2.59-.32.551-.143 1.223 0 1.803.16.655.338 1.417.723 2.024.516.84 1.1 1.456 1.897 2.032.594.451 1.343.356 1.975.035.745-.342 1.59-.624 2.383-.893 1.09-.377 2.36-.641 3.24.247.454.425.723 1.183 1.12 1.734.282.416.672.788.984 1.174.771.945.048 1.495-.9 1.733-1.058.3-2.106.629-3.089 1.11-.715.381-1.59.589-2.291.823-.762.316-1.481.923-2.135 1.452-1.113.983-2.01 1.646-2.21 2.998-.064.637.036 1.287.053 1.929.021.74.026 1.473.06 2.21.065.667-.156 1.776.33 2.04.524.14 1.139-.719 1.728-.974q.307-.151.628-.269c.45-.165.93-.282 1.403-.36.862-.1 1.507-.117 2.183-.663.77-.593 1.477-1.373 1.776-2.31.307-.987.325-2.175.866-3.098 1.096-1.846 2.845-3.224 5.072-2.695 1.2.225 1.858 1.335 2.55 2.227.837 1.006.992 2.301.416 3.48-.151.334-.368.672-.54.98-.118.212-.157.333-.096.324.173-.086.416-.394.58-.61.225-.295.468-.573.641-.898.403-.793-.255-1.989.684-2.613.65-.498 1.525-.117 1.91-.918.256-.434.49-.975.69-1.448.177-.446.333-.853.211-1.209-.151-.411-.8-.437-1.169-.684l-.022-.013z"
      />
      <path
        fill="#EE9042"
        d="M69.519 14.933c-.59.325-2.287 1.1-2.096-.191.195-.94.476-1.954.056-2.86-.788-1.781-2.52-2.535-4.366-1.798a4 4 0 0 0-1.516 1.061c-.35.377-.554 1.058-1.1 1.17-.294.035-.663-.29-.853-.32-.095-.013-.139.065-.117.225.126.646.723 1.265 1.005 1.924.138.282.26.572.411.845.29.559.815 1.036 1.364 1.447 1.326 1.07 2.01.525 3.418.82 1.052.32 1.919 1.091 2.538 1.988.507.759.974 1.543 1.698.434 1.165-1.565 2.876-3.155 4.526-4.186.537-.321 1.659-.733 1.949-1.04.143-.174-.04-.23-.282-.265-2.196-.039-4.82-.186-6.605.733l-.026.013z"
      />
      <path
        fill="#34454D"
        d="M8.36 37.886c-2.08-.065-3.686-1.664-3.34-3.804.096-.577.801-2.466 1.053-3.112 1.806-4.645 6.64-12.458 10.693-15.444.152-.108.789-.593.836-.61.273-.113 1.624-.196 1.988-.174 2.66.139 4.925 2.327 5.232 4.849.394.035.312.741 0 .832a55.4 55.4 0 0 1-.082 8.931c-.043 1.803-1.377 3.02-2.664 4.134q-.09.41-.186.793l-.35.091c-.69-.295-2.15-1.37-2.604-1.959-.663-.866-1.793-2.361-1.369-3.397a2.3 2.3 0 0 1-.277-.97l-.23-.035a24 24 0 0 1-1.312 2.296l-2.97 2.99c2.182.668 3.72 2.518 4.421 4.646l-.19.355c.416 1.647.104 2.323-.979 3.506-.632.697-1.845 1.833-2.646 1.759-1.447-.247-3.075.394-4.024 1.478-.39.442-.39 1.061-.719 1.434a.8.8 0 0 1-.173.234l-.108 1.703c.086.702.238.65.272.793.2.789.174 1.005.706 1.742.1.139.364.039.273.303-2.503 2.735-5.83 3.54-8.363.278-2.534-3.263-1.14-8.034 2.165-9.902 1.53-.862 2.703-.667 4.396-.758.252-.659.334-1.344.55-2.02q.293-.618 0-.97z"
      />
      <path
        fill="#30434C"
        d="M21.708 94.31c-.8-.363-1.658-1.17-2.109-1.832a2.3 2.3 0 0 1-.325-.967c-.095-.866.026-2.01.035-3.007 0-.555-.013-1.166-.36-1.62-.38-.49-1.06-.568-1.61-.79-.793-.298-1.525-.97-1.98-1.69-.256-.407-.386-.87-.442-1.347-.1-.633-.03-1.49-.628-1.859-.77-.381-1.832-.2-2.65-.546-3.188-1.079-5.427-5.196-3.132-8 1.29-1.746 3.79-2.46 5.709-1.386 1.108.602 2.065 1.829 2.58 2.981.209.477.287.984.417 1.482a4.25 4.25 0 0 0 1.009 1.86c.32.342.68.662.953 1.044.61.862 1.026 1.859 1.736 2.665.815 1.092 1.91 1.837 2.872 2.816 1.325 1.361 2.17 2.197.94 4.03-.762 1.244-1.403 2.496-1.815 3.905-.268.615-.16 2.621-1.174 2.279l-.021-.009z"
      />
      <path
        fill="#E66C24"
        d="M72.763 34.961c.732-.242 3.387 2.635 4.037 3.25.974.936 2.018 1.877.8 3.15-2.572 2.809-3.659 1.348-6.193 2.939-1.16.684-1.888 2.132-3.352 1.096-.888-.594-1.784-1.291-2.872-1.356-2.04-.13-3.932 1.464-4.435 3.449-.398 1.993-.731 1.534-1.394-.017a6 6 0 0 0-1.4-2.037c-.302-.312-.748-.689-.813-1.11 0-.632.913-.94 1.299-1.373a6.74 6.74 0 0 0 1.624-2.63c.269-.871.221-1.794.663-2.605.77-1.304 2.196-2.6 3.694-2.626.576-.021 1.148.087 1.724.152.936.117 1.897.07 2.837.026.94-.095 1.867.225 2.798.186.381-.03.697-.407.966-.49h.013z"
      />
      <path
        fill="#196F85"
        d="M25.52 86.532c2.343 2.553 5.83 4.628 8.818 6.383.407.239 1.507 1.066 1.819 1.196.294.126.723-.047.94.317q-.13.41-.832.13c-.46.225-2.049 1.989-2.092 2.647 0 0 0 1.387-.009 4.156-4.569-1.738-8.658-3.935-12.556-7.068.906.217.477-.715.572-1.304.347-2.245 1.516-4.26 2.815-6.045.13-.178.174-.347.243-.55.043-.208.139-.16.277.138z"
      />
      <path
        fill="#1E9289"
        d="M74.205 88.89c.022-.013.139.03.239-.035.207-.13.199-.737.887-.182.576.464 2.032 2.834 2.183 3.51.104.45-.047.945.04 1.322.025.112.25.039.285.294.043.338.043 1.344 0 1.682-.035.26-.264.182-.286.294-.052.247.052.55-.008.802-.074.29-.949 1.616.008 1.183-1.892 1.092-3.924 2.188-5.825 2.899-1.512.563-3.214.802-4.487-.368-1.395-1.279-.983-3.367-.979-5.105 0-.178-.143-.316-.143-.338-.593-.524.442-.85.923-.958l.775-.866c1.802-1.335 3.62-2.657 5.505-3.879.121-.078.268-.125.42-.169.151-.043.307-.069.468-.086z"
      />
      <path
        fill="#E97728"
        d="M51.173 10.14c.377.277-.559-1.395-.294-2.288.177-.594.047-3.05.034-3.857-.017-1.031-.29-2.249-.151-3.328.112-.87 1.173-.65 1.897-.624 2.066.078 5.95.226 7.8.707.429.112 1.763.676 2.074.957.256.23 1.01 1.517 1.062 1.885.463 3.081-2.127 4.615-4.812 5.04-.52.082-1.598-.264-1.109.559.074.126.463.299.628.416q.279.285 0 .277c-.208.195-1.014-.034-1.221-.23q-.038-.95-1.434-.762a16 16 0 0 0-4.348-.676c-.438.507-.802.598-1.083.286.545.871 1.04 2.128.961 1.634z"
      />
      <path
        fill="#2E414A"
        d="M25.614 58.145c.511.407.884 1.044 1.334 1.508.42.468.932.832 1.404 1.239 1.46 1.209-1.854 1.59-2.66 4.606-.784 2.362 2.105 3.315.927 4.78-.325.377-.953.576-1.3.932-.211.203-.359.48-.497.736-.295.659-.78.377-1.265.113-.51-.295-1.061-.555-1.646-.702-.818-.208-1.672-.247-2.416-.611-1.2-.585-2.24-1.716-2.677-2.994-.282-.837-.437-1.768-.178-2.622.507-1.465 2.131-2.587 3.46-3.397 1.032-.616 2.197-1.136 3.11-1.95.425-.369.82-.802 1.187-1.214.317-.355.767-.728 1.2-.433l.017.013z"
      />
      <path
        fill="#2A847B"
        d="M78.112 67.825c.169.018.264.135.277.351v3.822c.693-.632 1.17-1.048 2.127-1.17 1.49-.186 3.95-.104 5.47.048l.212.282c.182-.26.386-.269.559 0l.286.346a13 13 0 0 0-.005 1.595c.312.342.078 1.37-.298 1.651l.021 1.122c-.377-.056-.403.078-.584.317-1.213 1.568-1.547 2.847-3.6 3.839l-.207.399c-1.95.788-4.132 1.243-6.211.988-.096-.04-.182-.104-.278-.139a5.5 5.5 0 0 0-1.602-.966q-1.3-.68-.658-1.205a20 20 0 0 0 2.148-1.37c1.104-.823 2.14-2.053 1.78-3.505l.286-.156c-.173-.156-.165-.308.021-.446.005-1.864.048-3.727.117-5.586l.139-.204z"
      />
      <path
        fill="#135565"
        d="M26.879 69.437c1.278-.36 3.006.997 4.093 1.608 1.364.741 2.824.303 3.296 2.232.13.901.087 1.863-.022 2.756-.073.528-.208 1.005-.225 1.612-.017.918 0 2.002-.087 2.877-.073.806-.377 1.807-1.16 2.102-.646.151-1.226-.36-1.711-.763-1.239-1.127-2.646-2.045-3.959-3.081-.563-.46-1.07-1.114-1.468-1.625a3.4 3.4 0 0 1-.602-1.122c-.238-.81-.182-1.703-.433-2.51-.797-2.69-.204-3.106 2.252-4.068l.03-.009z"
      />
      <path
        fill="#E86D23"
        d="M95.71 32.665c.857.515.97 1.785 1.151 2.725.273 1.63.39 1.946-.974 2.813-.763.615-1.01 1.66-1.533 2.47-.758 1.326-1.954 2.223-3.348 2.838-1.07.524-2.21 1.018-3.11 1.794-.455.386-1.204 1.222-1.533.264-.243-1.057-.273-2.244-.269-3.328.082-.944.173-1.837.355-2.79.278-1.448 1.317-2.535 2.495-3.363.814-.585 1.741-1.096 2.512-1.668 1.221-.837 2.65-2.306 4.227-1.764l.022.009z"
      />
      <path
        fill="#1B4946"
        d="m68.905 84.873.346-.278c.88.659 1.69 1.539 2.517 2.215.697.572 1.498 1.04 2.178 1.577.212.169.342.108.256.503-2.062 1.36-4.24 2.855-6.215 4.35-.239.178-.715.824-.797.88-.239.152-1.083.147-1.079.732-1.156-.026-2.109.152-3.21-.277-.77.043-1.052-.221-1.515-.802-.325-.407-1.278-1.937-.715-2.249-.034-.013-.065-.026-.065-.047-.039-.234-.143-.897-.112-1.066.09-.516 1.646-1.864 2.113-2.245.234-.628.892-.715 1.971-.251l.134-.295c1.85-.07 3.132-1.057 3.88-2.717l.313-.035z"
      />
      <path
        fill="#1A2829"
        d="M37.097 94.432c1.122.308 2.4.958 3.456 1.374 1.157.455 2.785.888 3.508 2.062.572.919.615 3.233.091 4.204-.199.368-1.1 1.005-1.494 1.148-2.244.806-6.319-1.027-8.493-1.855a1.02 1.02 0 0 1-.286-.615c-.074-.654-.091-3.146.043-3.714.29-1.2 1.468-1.863 2.226-2.708.083-.035.81.07.945.108z"
      />
      <path
        fill="#459C90"
        d="M98.62 52.715c-.156-.412 1.546-.334 1.624-.29l.191.29c.697.048.411.147.671.26 1.551.672 2.694 1.911 2.69 3.692 0 .962-.541 2.115-1.13 2.869-.897 1.144-3.27 2.964-4.66 3.302-.94.23-1.932.169-2.868.407l-.173-.633c.147-1.742.151-3.588.034-5.187-.372-.247-.06-1.46.42-1.386.178-.43.455-1.179.758-1.608.373-.529 1.81-1.62 2.452-1.716z"
      />
      <path
        fill="#63B1AB"
        d="M56.348 87.95c1.273.142 2.685.636 3.534 1.59.48.589.702 1.417.988 2.071.299.685.619 1.378 1.104 1.959.342.455.992.797.637 1.183-.247.247-1.031.615-1.477.888-1.04.59-2.127 1.144-3.33 1.226-2.53.191-5.545-.892-6.48-3.21-.572-1.283-.624-2.566.29-3.745a5.4 5.4 0 0 1 2.283-1.798c.745-.312 1.641-.256 2.43-.165h.03z"
      />
      <path
        fill="#21726B"
        d="M95.134 63.25c-.052 2.257.13 4.74-1.81 6.309-.581.472-1.261.68-1.815 1.174l-.247.2q-1.176-.163-2.261.65l-.282-.157a13.5 13.5 0 0 0-1.775 0l-.178-.277h-.559l-.147-.29c.23-.603.303-2.31.169-2.878-.017-.082-.078-.125-.16-.156q-.247-.47.104-1.243c-.026-.455.082-.78.311-.975-.038-.555.633-1.257 1.053-1.656q1.625-1.063 2.646-2.478c.347.039.646-.018.91-.16-.065-.404.043-.473.277-.14 1.156.113 2.04.356 3.14.846q.35-2.02.208-4.035c-.32-.251-.108-.637.277-.554.23.143.139.524.143.758a151 151 0 0 1 0 5.061z"
      />
      <path
        fill="#2B513C"
        d="M65.535 52.013c1.866.433 3.426 2.24 3.594 4.169.217 1.872-1.06 3.497-2.624 4.394-1.048.624-2.265 1.035-3.465.78-1.607-.325-2.889-1.695-3.118-3.324-.135-1.001-.13-2.028.138-2.999.355-1.17 1.308-2.231 2.421-2.764.914-.412 2.036-.455 3.023-.269h.03z"
      />
      <path
        fill="#1F625A"
        d="M91.543 70.729c1.221.381 2.482 1.174 3.166 2.197.983 1.473.763 3.856-.42 5.213-1.997 2.513-5.583 1.91-6.995-.858-.446-.767-.866-1.621-.784-2.527.122-.97.381-2.023 1.066-2.76.94-.884 2.655-1.616 3.937-1.274z"
      />
      <path
        fill="#7FBCB9"
        d="M83.677 61.191c.152.16-.515.364-.701.433-.472.139-.026.252.169.33 1.412.442 2.84 1.542 3.356 2.985.364 1.11-.043 3.389-.723 4.355-1.447 1.864-3.638 2.444-5.644 1.162a4.8 4.8 0 0 1-1.277-1.153c-.204-.273-.61-.784-.75-.59-.168.187-.186.581-.22.85-.1 1.265-.065 2.613-.109 3.896-.013.316-.065.71-.164.975-.044.112-.087.151-.117.108-.143-.46-.004-.997.008-1.482.07-1.686.135-3.384.187-5.083-.052-1.192-.143-2.34.528-3.41.312-.672.927-1.227 1.23-1.894.247-.55.139-1.209.35-1.443.174-.186.482-.13.737-.104.36.056.74.13 1.113.134.676.065 1.408-.255 2.019-.069z"
      />
      <path
        fill="#61ADA3"
        d="M86.094 58.816c-.095-.507.143-1.083.239-1.681.164-.867.281-1.785.701-2.548.559-1.036 1.672-1.725 2.794-2.045 1.511-.399 3.296.511 4.413 1.46.693.693 1.304 1.781 1.152 2.73-.225 1.11-.593 2.206-1.455 3.012-1.078 1.079-2.551 1.854-4.11 1.698-.828-.112-1.668-.589-2.313-1.14-.286-.25-.585-.532-.832-.727-.247-.204-.506-.42-.58-.741v-.022z"
      />
      <path
        fill="#E76C24"
        d="M100.665 44.56c1.221.333 2.434 1.196 2.975 2.379.628 1.59.282 3.527-.909 4.797-.893.914-2.179 1.655-3.487 1.46-1.438-.26-2.737-1.248-3.413-2.505-.424-1-.645-2.712-.108-3.709.914-1.63 3.014-2.977 4.916-2.422l.03.008z"
      />
      <path
        fill="#524D49"
        d="M1.382 65.607c-.22-1.495-.64-3.112-.428-4.65.108-.771 1.472-2.076 2.209-2.24 2.425-.55 3.685.78 4.508 2.764.04.091.217.096.256.213.251.75.268 4.753.138 5.633-.177 1.226-1.204 2.47-2.36 2.851q-.462-.433.546-.814c.823-.676 1.524-2.106 1.355-3.155l.19-.256c-.107-.03-.268-.073-.276-.16-.109-1.062-.078-2.665.277-3.653-.264 1.174-.983 2.006-1.923 2.786l-2.023 1.72c-.238.642-.615.828-1.221 1.071q.117.793-.273 1.079c-.416-.624-.572-1.5-.697-2.219a7.4 7.4 0 0 1-.278-.97"
      />
      <path
        fill="#339085"
        d="M87.182 84.734c1.59-1.378 3.638-3.09 5.933-2.773.182.026.252-.074.208.208-.069.455-.935 2.05-1.217 2.526-1.542 2.613-3.707 4.702-5.899 6.69l-.879-1.07c-.641-.23-1.035-.836-1.312-1.434l-1.295-.962s.039-.178.138-.204c1.096-.312 1.837-.546 2.655-1.386.693-.711.91-.94 1.672-1.6z"
      />
      <path
        fill="#1E958B"
        d="M61.87 97.816c.789.55 1.872 2.08 1.729 3.051-.017.113-.282 1.07-.316 1.148-.026.061-.689.945-.763 1.01-.38.338-1.45.862-1.949.936-1.59.238-3.495-1.122-3.772-2.704-.507-2.869 2.599-5.174 5.067-3.445z"
      />
      <path
        fill="#1B6F83"
        d="M51.71 97.522c.073.03 1.147.875 1.242.979.888.988 1.04 2.691.386 3.822-.59 1.018-2.123 1.868-3.3 1.642-1.023-.195-2.508-1.629-2.616-2.669-.126-1.218-.035-2.024.835-2.916.485-.499.473-.386 1.005-.637.13-.061.121-.234.212-.256.234-.056 2.062-.043 2.24.035z"
      />
      <path
        fill="#1D2932"
        d="M2.517 68.844c-.151-.94.507-1.569 1.16-2.18.278-.277.516-.563.815-.836 1.07-.945 2.252-1.89 3.002-3.129.147-.204.437-.698.52-.251.077.45.025 1.75.043 2.478 0 .685.108 1.227.039 1.881-.065.633-.312 1.278-.641 1.855-.52.84-1.287 1.534-2.188 1.91-1.42.525-2.633-.134-2.746-1.702v-.03z"
      />
      <path
        fill="#4AA096"
        d="M86.064 58.955c.026-.022.195.056.26-.022.078-.1-.022-.702.394-.208.533.642.498.988 1.321 1.526.884.58 1.971 1.204 3.05 1.061-.022.026.047.226-.01.265-.081.056-.42-.018-.653.056-.16.052-.316.568-.36.607-.615.585-1.65 1.304-2.334 1.91l-1.248 1.461c-.026-.156-.311-.914-.394-1.066-.316-.611-1.81-2.045-2.365-2.249-.662-.243-2.091-.542-2.706-.962.757-.052.68-.009 1.433-.126.334-.052 1.304-.238 1.52-.307 1.174-.382 1.317-1.309 2.092-1.942z"
      />
      <path
        fill="#97CCC9"
        d="M77.796 55.445c.112.078.151.607.19.84.2 2.401 1.174 3.337 3.119 4.546.485.33-.035.663-.221.997-.247.368-.355.914-.645 1.4-.191.329-.43.628-.663.931-.55.616-.953 1.404-1.707 1.547-.498.03-.966-.416-1.334-.75-.385-.355-.77-.732-1.173-1.048-.516-.416-1.066-.767-1.703-1.001-.285-.143-1.294-.351-.883-.763.373-.32.914-.515 1.343-.78 1.624-.931 3.118-2.11 3.421-3.986.082-.455.104-1.006.143-1.443.026-.29.065-.481.108-.49z"
      />
      <path
        fill="#EA9A52"
        d="m78.251 39.966.342.087c.672 1.33 1.278 2.487 2.894 2.782l-.026.32q-.018.261-.278.417c-.047 0-.896.29-.935.316-.1.065-.13.295-.238.381-.464.373-1.638 1.357-1.72 1.903.295 1.304-.23 3.167.663 4.264.112.138.688.788.784.884 1.148 1.109 2.945.866 4.379.429l-1.079.52c-2.14.372-3.937-.447-4.708-2.466-.42-1.1-.065-2.128-.247-3.22-.1-.62-.766-1.378-1.221-1.768q-.865-.935-2.127-1.486l.035-.178c.905-.706 1.763-1.412 2.586-2.214.147-.143.415-.633.48-.698.04-.303.126-.377.42-.277z"
      />
      <path
        fill="#E87525"
        d="M72.486 8.597c-.416.499-1.876.893-2.504.711-.294-.087-1.273-.953-1.377-1.27-.49-1.464.056-3.276 1.789-3.401 2.062-.152 3.383 2.422 2.096 3.965z"
      />
      <path
        fill="#3B9488"
        d="M99.681 66.655c.931.65 1.386 1.946.78 2.964-1.144 2.11-4.44.958-4.123-1.365.117-1.737 1.936-2.504 3.322-1.62l.026.017z"
      />
      <path
        fill="#8D4F31"
        d="m17.01 38.302.324.152c.216 1.517.585 2.912 1.962 3.813.689.451 1.512.754 2.33.598l.13.29c.875.321-.801.573-1.213.56-1.368-.044-2.464-.919-3.183-2.011-.373-.542-.658-.338-.866.611-.888.425-1.27.819-1.144 1.174q.974.812.55 1.33c.148.304-.069.2-.277 0-.273-.238-1.975-1.204-2.23-1.247.428.112.212-.334.294-.399.091-.07.32.022.429-.03.173-.078.896-.767 1.122-.967 1.269-1.13 2.096-2.01 1.784-3.874z"
      />
      <path
        fill="#AF6134"
        d="M15.891 44.815c.364-.424.892.338 1.118.685.311.481.575.984.277 1.53l.307.329-.026 4.615q.136 1.209.42 2.262c-.312.091-.61.039-.636.065-.122.121-.122 2.895-.204 3.332-.052.286-.268.321-.277.343.238-2.717.446-5.66.286-8.394-.013-.26.03-.52-.286-.615.108-.945.013-2.271-.407-3.133-.035-.07-.797-.98-.845-1.023.113.134.264-.004.277 0z"
      />
      <path
        fill="#DBA985"
        d="M86.762 46.48c-.048.545-.221.714-.273 1.044-.07.45-.143 2.223-.442 2.543-.056.061-.204-.008-.26.013.342-.901.303-1.66.45-2.53.044-.243.403-.867.386-1.07q.06-.183.139 0"
      />
      <path
        fill="#1D2932"
        d="M5.71 70.178c-.023.057.047.195-.018.256-.247.243-1.906.394-2.261.299-.147-.039-.082-.269-.091-.277.875.065 1.55-.005 2.373-.278z"
      />
      <path
        fill="#E86D23"
        d="M92.063 42.874c-.9.637-1.239.607-2.218 1.057-.571.265-1.043.82-1.689.884.944-.797 1.248-.849 2.374-1.313.498-.203.987-.567 1.533-.628"
      />
      <path fill="#E0A482" d="M85.787 50.085c-.247.645-.875 1.447-1.533 1.664l1.286-1.634c.074-.07.19-.009.247-.03" />
      <path fill="#E86D23" d="M94.852 39.828c-.442.836-.46.975-1.118 1.664.148-.512.477-.958.72-1.439.06-.126.242-.195.255-.225h.143" />
      <path fill="#E55325" d="M48.397 6.01c.567.269.88 1.006.974 1.664-.026-.021-.199.052-.255-.017-.056-.07-.554-1.339-.723-1.642z" />
      <path fill="#7A8489" d="m8.637 46.48-.278 1.94c-.051-.437-.116-1.403 0-1.85.04-.156.269-.073.282-.09z" />
      <path
        fill="#417D90"
        d="M32.661 82.74c-.615-1.139-1.676-1.832-2.741-2.643-1.066-.767-2.144-1.352-3.045-2.314a18 18 0 0 1-1.382-1.737c-.16-.213-.234-.351-.398-.382-.178.013-.247.174-.399.42-.164.308-.35.694-.554.989-.788 1.191-2.308 1.685-3.703 1.638-.455-.013-1-.195-1.416-.239-.568.035.342 1.63.541 1.916 1.156 1.672 2.707 2.955 4.145 4.385.524.46.944 1.166 1.485 1.573.226.152.473.169.854.238.441.074.91.269 1.368.364a4.5 4.5 0 0 0 1.893.018c1.529-.195 4.054-2.54 3.365-4.186l-.013-.026z"
      />
      <path fill="#E55325" d="M47.7 5.04c.376.407.363.36.697.97-.04-.017-.152.04-.247-.03-.113-.078-.43-.741-.45-.94" />
      <path fill="#34454D" d="M8.36 37.886c.09 0 .186-.004.277 0 .181.455-.213.763-.278.971z" />
      <path
        fill="#A2411B"
        d="M38.834 13.251c-.438-.84-1.503-1.408-2.404-1.499-.819-.104-1.815.247-2.62.572-.408.152-.923.364-.594-.286 1.087-2.041.35-2.522-.364-4.476a22 22 0 0 1-.42-1.638c-.169-.841-.455-1.365-1.369-.897-1.333.62-3.421 1.885-4.543 2.777-.515.408-.91.858-1.195 1.465-.672 1.504-.888 3.497-.19 4.983.341.741.982 1.309 1.597 1.812.81.654.46 1.222-.138 1.807-.637.684-1.4 1.326-1.655 2.257-.311 1.47.104 3.185 1.035 4.39 1.776 2.57 4.955 1.248 6.623-.685 1.065-1.265.987-.442 1 .72.035 1.165-.065 2.413.087 3.562.247 1.027.628.277.576-.312-.108-.897-.165-2.158-.169-3.155 0-1.053-.113-2.093.052-3.094.307-1.465 1.317-2.266 2.516-3.302.637-.572 1.482-1.005 2.08-1.556.229-.247.302-.559.337-.892.065-.785.104-1.842-.238-2.527l-.013-.026z"
      />
      <path fill="#34454D" d="M17.567 29.016c-.398-.139-.273-.624-.277-.97.242.463.195.502.277.97" />
      <path
        fill="#E86D23"
        d="M78.337 13.732c.767-.039 1.204.55 1.836 1.07.23.2.46.404.693.594.416.43 1.755.993 1.638 1.617-.126.533-.85 1.005-1.421 1.542-.494.442-.957.915-1.364 1.435-.776.931-1.092 2.188-1.863 3.133-.979 1.191-2.135 2.14-3.577 2.66-.559.182-1.018.455-1.581.52-.767.057-1.724-.021-2.551.026-1.845.113-2.018-1.26-1.954-2.747.03-1.205.087-2.388.633-3.506 1.447-2.409 3.837-4.528 6.41-5.62.65-.277 1.364-.368 2.049-.52.39-.091.727-.19 1.026-.208h.022z"
      />
      <path
        fill="#CA5A21"
        d="M78.476 21.87c.494.247.62.837 1.057 1.422.338.494.74 1.01 1.143 1.347.468.442 2.096 1.08.728 1.274-1.083.104-2.17-.316-3.253-.329-.996-.017-2.01.009-3.001.087-.594.056-1.157.169-1.733.203-.22.013-.368 0-.407-.039-.087-.186.957-.485 1.317-.723.853-.468 1.628-1.075 2.347-1.738q.3-.273.572-.572c.459-.468.758-1.001 1.217-.945h.017zM72.594 35.148c-.896-.906-2.53-1.625-2.94-2.938-.1-.23-.174-.46-.36-.611-.433-.321-1.096.74-1.438 1.018a5.8 5.8 0 0 1-1.317 1.044c-.974.507-2.16.577-3.23.43-.759-.118-1.356-.642-1.872-1.18-1.017-1.026-1.412-1.928-1.355-3.405-.009-.767.056-1.504-.351-2.176-.364-.598-.949-1.113-1.577-1.447-1.083-.62-2.326-.559-3.452-.295-1.152.217-2.113.928-2.828 1.855-.81 1.023-1.178 2.4-.961 3.631.156.668.658 1.196 1.143 1.669 1.568 1.503 3.578 2.535 5.44 3.644.498.273 1.04.485 1.507.793.407.269.763.624 1.1.901.789.763 1.382-.407 1.906-.914.702-.689 1.486-1.322 2.37-1.374.796-.034 1.558.252 2.36.286.844.061 1.767-.026 2.585-.065.949-.095 1.902.325 2.841.226.602-.048.91-.616.442-1.062l-.017-.017z"
      />
      <path
        fill="#EF994E"
        d="M68.982 25.658c.009.75.048 1.473.169 2.184.134 1.053.576 2.444.082 3.419-.45.49-.498-1.248-.563-1.482-.078-.52-.268-1.019-.45-1.5-.282-.75-.633-1.412-1.265-1.902-.463-.36-1.33-.806-.948-1.495.199-.355.58-.654.874-.945.52-.472.888-1.083 1.443-1.486.615-.407.67.52.658.932 0 .736-.013 1.477 0 2.236v.039"
      />
      <path
        fill="#C15624"
        d="M88.121 36.924c.026.122-.113.317-.195.451-.338.529-.78 1.088-.979 1.62-.09.17-.238.876-.468.772-.246-.29-.216-.823-.272-1.2-.044-.434-.087-.785.138-1.023.256-.264.637-.368.98-.494.242-.07.636-.269.792-.134v.008z"
      />
      <path
        fill="#CE5F20"
        d="M81.508 34.203c-.256-.264-.802-.533-1.187-.875-.689-.568-1.334-1.27-1.962-1.903-.559-.528-.676-.008-.667.455.017.928.247 2.544.338 3.81.134 1.061-.117 1.984-.269 3.007-.06.52-.078 1.144.06 1.382.278.433.408-.403.442-.624.07-.72.343-1.378.698-1.998.32-.654.663-1.386 1.29-1.828.408-.395 1.815-.815 1.278-1.409l-.017-.017z"
      />
      <path
        fill="#E56723"
        d="M69.372 20.497c-.234-1.647-1.629-3.081-3.179-3.666-.736-.278-1.46-.321-2.183-.642-.637-.286-1.256-.684-1.745-1.178-.481-.464-.676-1.088-.975-1.664-.398-.759-.91-1.452-1.338-2.219-.412-.81-1.031-1.339-1.889-1.63-.65-.303-1.23-.749-1.944-1-.377-.135-.788-.2-1.183-.269-.719-.134-1.498-.325-2.26-.425-3.097-.394-2.573 1.409-1.915 3.584.48 1.941 1.95 3.597 2.283 5.59.09.836-.511 1.993-1.412 2.102-.416.008-.685-.33-1.087-.65-.43-.334-.789-.59-1.031-.568-.273.152.398.832.533 1.049 1.29 2.002 1.78.48 3.036-.464 1.75-1.447 4.305-1.057 5.686.611 1.798 2.071 1.278 4.693 1.365 7.193.073 1.092.61 1.552 1.572.828.762-.607 1.615-1.057 2.594-1.222.74-.126 1.42-.165 2.023-.45.897-.443 1.585-1.227 2.174-2.029.641-.827 1.066-1.859.88-2.855v-.03z"
      />
      <path
        fill="#7F3017"
        d="M33.904 9.967c.46-.087 1.196.667 1.663.888 1.438.84.733 1.421-.524 1.616-.628.148-1.221.486-1.814.75-.312.121-.546.256-.828.182-.368-.234.373-1.135.503-1.443.169-.299.299-.62.411-.945.182-.485.247-.914.572-1.04h.022z"
      />
      <path
        fill="#F0984C"
        d="M41.84 23.196c.13 1.188.276 2.795.138 4.022-.005.052-.299.052-.446.043-.814-.676-1.802-1.083-2.763-1.43-.105-.312-.04-.364.277-.416.039-.026.22.043.303-.013.931-.633 1.732-1.374 2.486-2.206z"
      />
      <path
        fill="#672616"
        d="M34.212 28.587c-.264-1.993-.104-4.047-.1-6.058-.02-.269.057-.914-.22-.962-.139 0-.364.217-.624.524-.273.33-.58.711-.857 1.023-1.031 1.196-2.6 2.331-4.232 1.959-1.29-.247-2.23-1.274-2.824-2.392-.325-.525-.463-1.292-.524-1.846-.087-.832-.108.403-.108.628 0 2.058-.208 4.065-.32 6.123-.053.823-.04 1.681-.217 2.466-.217.944-.767 1.72-1.468 2.478-.477.525-1.204 1.192-.858 1.933.195.468.758.88 1.239 1.3.51.45.94 1.014 1.243 1.625.173.347.307.789.472 1.11.264.515.584.489 1.048.043.303-.295.637-.655.935-.993a17.5 17.5 0 0 1 3.864-3.406c.888-.684 1.845-1.226 2.793-1.798.641-.407.78-1.11.758-1.811.018-.668.052-1.326 0-1.92v-.03z"
      />
      <path
        fill="#F9C69F"
        d="M51.047 31.235c.758.138 1.156 1.126 1.724 1.607 1.776 1.5 4.474 2.683 6.505 4.078l.697.693c.117.109.685.646.698.694.052.16-.013.598 0 .832-.559 2.296-1.88 3.401-4.045 4.173l-.278-.152c-.22.269-.831.33-.974 0-.083.39-.282.355-.42 0-.637.277-1.594-.464-2.123-.897-1.377-1.135-1.87-2.483-2.252-4.134-.528-1.317-1.247-1.976-2.356-2.8l.04-.342-.044-.36a11.6 11.6 0 0 0 2.499-3.262l.333-.122z"
      />
      <path
        fill="#C25B1E"
        d="M60.372 48.585c.19.325.355.763.58 1.092.339.516.815.993 1.296 1.487.294.294.606.585.979.767.615.32 1.494.216 2.13.35.758.204-1.243.291-1.373.33-.913.13-1.862.45-2.529 1.127-.264.26-.49.58-.767.819-.606.502-.584-.62-.597-.993.013-1.369.052-2.786.03-4.151 0-.169-.047-1.274.238-.854l.009.013z"
      />
      <path
        fill="#E66C24"
        d="M57.046 43.845c-1.022-.104-1.906-.217-2.937.039-.268-.338.018-.611.845-.733.043-.026.303.009.42 0 .403.282.576.23.975 0 1.026-.684 2.923-.914 3.36-2.522l.966-1.499c.057 1.018-.567 2.67-1.256 3.532-.186.234-1.429 1.239-1.637 1.295-.286.078-.498-.09-.736-.117z"
      />
      <path
        fill="#80BEBB"
        d="M61.013 76.839c.407-.091.805.35 1.295.689.294.212.615.39.953.524.407.165.844.247 1.277.312.866.126 1.447.45 2.153.84 1.957.841 2.533 2.107 2.56 4.152-.027 3.025-2.898 5.508-5.89 4.459a4.77 4.77 0 0 1-2.846-2.834c-.39-.85-.533-1.746-.369-2.66.269-1.101-.1-5.019.85-5.478h.017z"
      />
      <path
        fill="#1F706A"
        d="M71.836 70.178c.654.403 1.581.529 2.612.455.966.122 3.309 2.085 3.23 2.969-.004.06-.055.121-.125.177.06.152-.156.347.278.278-.04.524.095 1.222-.035 1.698-.139.516-1.4 1.799-1.854 2.176-.732.61-1.516.97-2.3 1.46-.108.55.226.234.52.386.043.021.052.164.152.22.489.278 1.572.525 1.563 1.262-.034-.013-.35.177-.628-.143l-.07.277c-.9.173-1.03-.858-1.459-1.252-1.074-.98-3.283-2.652-3.989-3.94-.238-.433.009-.979-.55-1.182l-.281.416-.278-.165c.286-1.5-.337-2.414-1.416-3.38l.022-.338c-.052-.095-.117-.247-.06-.295.948-.814 2.446-1.937 3.672-1.235.013.009-.216.109.013-.134.143-.394.996-.139.975.277z"
      />
      <path
        fill="#EAEAEA"
        d="M64.478 70.742c1.282-.204 2.3.16 3.114 1.2.463.555.987 1.131 1.195 1.803.26.888.19 1.906-.013 2.795-.216.84-.758 1.586-1.446 2.114-.771.611-1.711.715-2.642.659-1.711-.004-3.331-.94-4.27-2.34-.581-.91-.434-2.158-.26-3.185.42-2.006 2.472-2.778 4.287-3.042h.03z"
      />
      <path
        fill="#418F8F"
        d="M69.095 78.295c.039.784.043 1.59-.013 2.37-.03.247-.022.693-.36.611-.307-.078-.84-.654-1.32-.992a3.7 3.7 0 0 0-1.287-.616c-.407-.121-.962-.186-1.352-.268-.216-.052-.346-.1-.346-.152.16-.221.832-.334 1.165-.477.446-.16.858-.385 1.247-.654a5 5 0 0 0 1.14-1.092c.346-.407.857-1.543 1.022-.975.125.706.034 1.49.095 2.214v.035z"
      />
      <path fill="#1F706A" d="M68.905 80.297c-.022-.646.008-1.296 0-1.942.233.464.238 1.448 0 1.942" />
      <path
        fill="#67A3A2"
        d="M54.595 87.884c-.113-.394-.602-.606-.944-.801-.585-.317-.962-.568-1.278-1.058-1.053-1.698-1.204-1.343-2.46.152-.286.32-.607.62-.962.862-.303.23-.766.438-.836.828-.008.425.516.728.806 1.001.385.325.758.758 1.065 1.213.36.525.58 1.01.663 1.552.065.26.082.814.346.88.07-.005.144-.092.217-.235.19-.377.29-.849.507-1.239a6.5 6.5 0 0 1 1.892-2.132c.33-.277.97-.537.98-1.001v-.017z"
      />
      <path
        fill="#1DA597"
        d="M56.942 52.624c1.746.511 2.763 1.642 2.945 3.475.109.798-.013 1.582-.043 2.345-.026.624.009 1.252.03 1.885.022 1.49.308 3.328-1.74 2.435-.512-.217-.997-.537-1.512-.72-.789-.285-1.724-.216-2.517-.532-1.165-.451-2.118-1.435-2.629-2.57-.706-1.725-.333-4.325 1.239-5.451 1.187-.828 2.79-1.335 4.192-.876l.03.009z"
      />
      <path
        fill="#FEFEFE"
        d="M20.266 70.69c2.105-.42 4.582.788 5.184 2.912.186.628.204 1.308.208 1.971.09 1.218-.2 2.223-1.165 2.969-.966.858-2.244 1.087-3.491 1.061-1.014.018-2.083-.29-2.802-1.027-.667-.75-1.39-1.512-1.594-2.526-.221-1.365.325-2.882 1.165-3.948.602-.732 1.524-1.213 2.456-1.404h.03zM11.803 43.502c2.105-.42 4.582.789 5.184 2.912.186.629.204 1.309.208 1.972.091 1.218-.2 2.223-1.165 2.968-.966.858-2.244 1.088-3.49 1.062-1.014.017-2.084-.29-2.803-1.027-.667-.75-1.39-1.512-1.594-2.526-.22-1.365.325-2.882 1.165-3.948.602-.732 1.525-1.213 2.456-1.404h.03z"
      />
      <path
        fill="#217C7B"
        d="M60.116 67.843c.464.004.68 1.018 1.022 1.503a5.3 5.3 0 0 0 .788 1.04c1.196 1.04.607 1.305-.225 2.423a7 7 0 0 0-.654 1.079c-.186.32-.363.845-.749.884-.433-.07-.706-.65-.966-.988-.628-.932-1.438-1.72-2.438-2.12-.23-.099-.503-.203-.702-.316-.715-.36.152-.719.52-.892.992-.434 1.97-1.049 2.624-1.894.208-.247.464-.65.763-.71h.017z"
      />
      <path
        fill="#309786"
        d="M77.84 57.586c-.382-.057-.763.524-1.036.823-.407.507-.844.988-1.334 1.413-1 .94-2.352 1.568-3.594 2.244-.62.373-1.244.69-1.733 1.092-.247.178-.52.499-.74.62-.057.013-.057-.082-.04-.277.083-.629.109-1.573 0-2.262-.225-1.513.373-3.03.399-4.55.009-.395.065-1.088-.303-1.27-.49-.126-.555.65-.58 1.031 0 1.803-1.348 3.203-2.968 4.009-.792.437-1.628.923-2.55.788-1.092-.203-2.2-.936-2.855-1.833-.571-.88-.524-2.11-.407-3.15.048-.325-.017-1.027-.303-.992-.082 0-.156.065-.2.125-.32.668-.13 1.495-.19 2.219-.078 1.413-.034 2.834-.173 4.234-.121.658-.554.949-.355 1.542.095.3.385.694.524 1.075.16.416.195.858.19 1.304a7 7 0 0 0 .676 3.68c.75 1.707 1.914 2.842 3.803 1.967 1.252-.486 2.56-.793 3.56-1.725.286-.234.559-.533.845-.754.372-.299.758-.438 1.017-.672.499-.463.382-1.304.373-1.945-.095-1.677 1.91-3.515 3.4-4.035.75-.26 1.507-.403 2.183-.875.9-.62 1.775-1.209 2.226-2.197.22-.533.554-1.343.178-1.625h-.013z"
      />
      <path
        fill="#195B58"
        d="M69.853 68.029c.1.221.212.433.346.637.256.446 1.005 1.187.663 1.421-.429.174-.944.07-1.386.265-.732.26-1.36.957-2.122 1.183-.485.143-.897-.013-1.4-.174-.471-.138-1.008-.281-1.194-.42-.395-.273.688-.572.874-.676.663-.269 1.222-.663 1.767-1.157.438-.407.91-.893 1.23-1.33.68-.962.776-.715 1.213.216l.013.03z"
      />
      <path
        fill="#79B5AD"
        d="M57.046 62.001c.446.1 1.32.533 1.676.832.07.057.485.42.515.451.048.043.641 1.023.663 1.083.117.321.052.529.078.824l-.754-1.326c-1.035-1.279-2.854-2.18-4.547-1.864.606-.394 1.706-.385 2.373 0z"
      />
      <path
        fill="#97CCC9"
        d="M72.81 62.279c.711-.148.928-.135 1.634-.018.26.044.541-.117.463.295-1.59-.186-2.3.13-3.625.693-.1-.164.62-.65.676-.676.311-.156.52-.22.862-.29z"
      />
      <path
        fill="#EC8A32"
        d="M60.454 55.44c.125-1.403.346-2.894.23-4.35-.13-1.655-.373-3.22-1.062-4.719-.338-.724-.862-1.27-1.46-1.79-.645-.589-1.36-1.174-2.256-1.26-1.94-.118-3.746.615-4.517 2.513-.251.58-.295 1.239-.45 1.82-.191.784-.928 1.625-.858 2.379.112.424.61-.152.914-.187.338 0 .48.29.732.55.342.39.753.742 1.143 1.075.949.863 1.273 1.621 2.633 1.556 1.304.017 2.374.46 3.366 1.196q.148.137.277.295c.385.394 1.126 2.21 1.303.94v-.022z"
      />
      <path fill="#2B513C" d="M60.256 58.4c-.234-.589-.234-1.655 0-2.218.021.736 0 1.482 0 2.218" />
      <path
        fill="#265664"
        d="M28.876 61.43c1.152-.113 2.113.87 2.893 1.611.81.837 1.659 1.695 2.01 2.696.277.823.324 1.733.402 2.595.07.954.135 1.881.044 2.813-.083 1.018-.516 1.672-1.616 1.373-1.265-.273-2.278-1.053-3.391-1.694-.966-.533-2.023-.962-2.768-1.807-.979-1.062-1.585-2.461-1.147-3.852.467-1.517 1.966-3.441 3.551-3.736h.026z"
      />
      <path
        fill="#729798"
        d="M34.22 58.153c.386.048.694.438.958.72q.566.649 1.269 1.174c.589.498 1.45.832 1.988 1.4.537.693-.806.853-1.248 1.035-1.91.568-2.97 2.483-2.88 4.446-.008.36-.021 1.482-.325.598-.117-.355-.246-.905-.467-1.3-.3-.572-.745-1.04-1.165-1.52-.884-1.045-1.776-1.886-2.972-2.627-2.737-1.711 1.157-.073 3.444-2.743.402-.403.758-1.161 1.377-1.183z"
      />
      <path
        fill="#9A866E"
        d="M29.193 52.017c-.975-.29-2.253-.125-3.275-.065-.654.04-1.2-.017-1.892-.03-.615-.017-1.248.078-1.858.13-.75.07-1.395.104-2.127.381-.62.226-1.17.633-1.715.975-.35.213-.654.377-.866.598-.39.382-.481.988-.52 1.56-.056.659-.108 1.322-.334 1.933-.247.715-.68 1.326-1.16 1.933-.832 1.118-2.092 1.798-3.43 2.036-.953.243-.109.46.303.594.489.139.935.29 1.386.537 1.074.598 2.143 1.643 2.447 2.821.13.416.19 1.118.337 1.456.048.044.083-.117.126-.385.048-.26.108-.629.251-.958 1.2-2.5 4.14-3.324 6.241-4.784.732-.503 1.339-1.209 1.932-1.85.524-.542.654-1.066.684-1.894.143-4.078 4.726-4.468 3.487-4.975l-.017-.008z"
      />
      <path fill="#2E414A" d="M28.586 61.17v.277c-.663-.343-.81-.325-1.256-.832.412.104.875.433 1.256.554" />
      <path
        fill="#1C5B56"
        d="M77.9 67.431c.156-.477.966 1.092 1.156 1.257.693.918 1.646 1.551 2.776 1.733.75.113 1.451-.165 2.101-.555a5.6 5.6 0 0 0 1.455-1.313c.247-.255.758-1.165 1-.663.157.347.157 1.105.152 1.565-.017.502-.039 1.048-.363 1.451-.477.555-1.304.373-2.196.364-1.035-.034-2.135-.069-3.01-.008a3.3 3.3 0 0 0-1.94.719c-1.136.936-1.179.828-1.187-.559.034-1.265-.078-2.843.052-3.978v-.013z"
      />
      <path
        fill="#8FD1D2"
        d="M86.762 71.149c.316 0 1.875-.056 1.953 0 .022.017-.013.2 0 .277-.45.269-1.433 1.153-1.676 1.664-.381.247-.264.135-.277-.208-.026-.576.022-1.161 0-1.733"
      />
      <path
        fill="#AF6134"
        d="M29.695 42.818c.979 0 1.819.55.415.949-2.265.459-3.837 2.227-4.681 4.424-.919 2.674-1.122 4.004-4.158 4.602-.56.121-1.066.282-1.555.572-.533.286-1.027.767-1.62.845-1.347.121-.962-3.627-.992-4.533.056-1.564-.169-3.185.9-4.407 1.647-2.132 4.21-1.997 6.615-2.205.97-.156 1.975.251 2.914.195.754-.022 1.395-.477 2.131-.442z"
      />
      <path
        fill="#0F3948"
        d="M34.182 67.786c.372.061.598.568.857.837.455.671 2.11 1.417 1.599 2.348-.243.377-.983.646-1.369 1.023-.32.277-.606.659-.914.932-.112.09-.177.143-.286.151-.329-.082-.247-.866-.268-1.161 0-.815.008-1.647.004-2.474.03-.425-.078-1.478.364-1.651h.013z"
      />
      <path
        fill="#C15624"
        d="M86.45 41.123c.23.282.169.793.216 1.157.087.81.135 1.604.187 2.366-.013.789.472 2.9-.784 1.235-.867-1.17-2.08-2.019-3.548-2.106-.355-.047-.874-.078-1.156-.234-.234-.234.97-.316 1.126-.342 1.17-.117 1.98-.407 2.798-1.287.264-.234.784-1.001 1.14-.802l.012.009z"
      />
      <path
        fill="#C15624"
        d="M86.484 39.828c.017.459-.009.927 0 1.386-.433-.021-.45.616-.693.876-.108.112-.303.086-.364.151-.32.338-.671.889-1.074 1.084-.905.437-2.187.177-3.17.247-.009-.343-.091-.477.277-.416l.342-.247c1.585.138 2.954-.62 3.86-1.885l.402-.083c.048-.069.225-.034.277-.342.108-.633-.073-1.486 0-2.15.07.503.117.833.139 1.387z"
      />
      <path
        fill="#1A615B"
        d="M60.25 84.032c.222.017.4.286.533.494.204.334.403.685.654.997.555.689 1.27 1.36 2.032 1.737.273.16 1.091.607 1.277.802.01.039-.472.009-.597.004-.338-.008-.425-.065-.672.04-.493.199-.857.632-1.307 1.087-.408.412-.871.871-1.153 1.36-.147.248-.268.542-.402.78-.282.551-.507-.368-.602-.615-.26-.84-.29-1.755-.26-2.643.008-.784.03-1.582.013-2.34.013-.46-.052-1.59.472-1.707h.017z"
      />
      <path
        fill="#1A4845"
        d="M56.327 79.543c2.061.19 3.876 1.915 4.05 4.004.051.84-.13 1.685-.473 2.452-.667 1.478-1.931 2.284-3.603 2.228-.637.017-1.29.013-1.893-.182-.948-.338-1.949-.997-2.56-1.846-.593-.733-.857-1.621-.797-2.561.013-.936.256-1.825.958-2.488.671-.663 1.585-1.213 2.503-1.534.606-.195 1.195-.151 1.789-.078h.03z"
      />
      <path
        fill="#408E89"
        d="M60.126 85.21c.333-.021.303.668.32.924 0 1.3-.147 2.704-.022 3.973.044.39.148.837.19 1.205.014.134-.142.243-.294.169-.502-.243-.368-1.148-.84-1.517-.818-.697-1.81-1.39-2.85-1.729-.199-.086-.342-.151-.073-.273.25-.108.623-.23.896-.35.819-.343 1.486-1.01 1.997-1.73.186-.23.403-.576.662-.671z"
      />
      <path
        fill="#175A55"
        d="M94.835 57.984c.437-.082.281.221.29.503.021.715.082 3.644-.355 4.03-.252.225-1.633-.663-2.04-.737-.33-.06-.68.018-1.014-.008-.446-.03-.368.06-.355-.438 1.438-.945 2.65-1.725 3.47-3.354z"
      />
      <path
        fill="#FCFCFC"
        d="M77.843 66.218a3.5 3.5 0 0 0-.68-1.911c-.879-1.3-2.152-2.353-3.82-2.124-2.174.278-3.828 2.288-3.867 4.468-.113 3.068.831 3.302 3.49 4.156 1.94.286 2.99.923 4.189 2.491.662.798.701-.134.723-.684.04-2.102.043-4.299-.03-6.366v-.03z"
      />
      <path
        fill="#8D4F31"
        d="M24.822 37.609s.242-.065.281.091c.065.243-.047.611 0 .88.256.043.074-.252.173-.382.057-.078.239-.021.247-.034.104.047.256.117.252.203-.04.533-.321 2.535-.481 2.947-.23.585-1.287 2.002-1.863 2.258-2.07.104-3.434.19-4.954 1.794l-1.183 1.672c-.073-.736-.645-1.91-1.394-2.218-.018-.685-1.014-.997-.91-1.682.056-.364 1.04-.684 1.286-.988.187-.238.17-.632.312-.836.555-.806 1.603 1.105 1.95 1.374.047.034.766.45.81.463.06.022 1.134.286 1.225.295.065.009 1.035 0 1.091-.009.156-.039.083-.268.096-.281.194-.13.87-.234 1.082-.317.057-.021.845-.723.927-.81 1.17-1.265 1.33-2.778 1.061-4.42z"
      />
      <path
        fill="#97401C"
        d="M51.004 23.274c.468.022.373 1.08.355 1.716.026.863.052 1.842 0 2.665-.021.832.247 1.638.17 2.475-.196 2.162-2.184 4.311-3.95 5.507-1.742 1.287-3.106 3.025-4.54 4.611-.49.442-.836.087-1.108-.377-.546-.98-1.144-1.946-1.885-2.795-.489-.607-.94-1.118-1.585-1.439-.316-.147-.667-.16-1.013-.121-1.68.334-1.962.559-3.084-.975-.35-.455-.702-.949-1.234-1.192-.486-.225-1.122-.247-1.326-.403-.311-.268.524-.693.72-.832 1.36-.68.96-1.759 1.151-2.873.135-.502.165.863.174 1.027-.013 1.664 1.126 3.29 2.672 3.883 2.794.997 5.25-1.343 5.903-3.939.355-1.4 1.074-2.691 2.21-3.61 1.281-1.213 3.273-.962 4.646-2.023.42-.312.784-.754 1.178-1.075.195-.152.382-.238.529-.23z"
      />
      <path
        fill="#F8C39C"
        d="M51.619 19.6c.554-.858 1.2-1.673 2.14-2.124 2.468-1.14 5.067.052 6.093 2.5.252.499.477 1.006.568 1.556.09.603.069 1.175.073 1.842.009 1.127-.09 2.188-.043 3.254.22 2.171-.36 1.487-1.45.46a4.6 4.6 0 0 0-1.824-.98c-1.027-.299-2.166.009-3.132-.537-.805-.468-1.576-1.153-2.178-1.872-.858-1.166-1.057-2.847-.26-4.065l.017-.026z"
      />
      <path
        fill="#BE5222"
        d="M54.72 25.203c-.85-.399-1.802-1.222-2.443-1.98-.312-.378-.537-.807-.758-1.006-.762-.607-.793 1.27-.81 1.69-.009.585-.078 1.1-.082 1.829 0 .979-.035 2.201.086 3.124.1.624.16 1.4.269 2.05.173.801.437-.113.62-.46.224-.624.536-2.197.978-2.73.576-.671 1.278-1.248 2.014-1.508 1.096-.368 1.945-.299.156-.992l-.03-.013z"
      />
      <path
        fill="#FCFCFB"
        d="M81.928 34.65c-1.641.199-2.482 1-3.144 2.46-.919 1.669-.75 2.93.372 4.43 1.732 2.543 5.817 1.988 7.112-.69 1.048-2.7-1.36-6.404-4.31-6.205h-.03z"
      />
      <path
        fill="#AC481C"
        d="M36.815 35.603c.151.268-.295.728-.524.975a384 384 0 0 1-5.163 5.091c-.624.568-1.239 1.179-1.966 1.604-1.252.806-2.17.762-3.578.468a7 7 0 0 0-.987-.065c-.52 0-.984.026-1.105-.152-.195-.403.52-1.127.793-1.612.255-.386.442-.806.532-1.261.234-1.07.256-2.392 1.027-3.246 1.767-1.98 4.223-4.446 6.934-4.762 1.412-.152 2.218 1.27 3.006 2.21.333.377.77.425 1.018.741l.008.013z"
      />
      <path
        fill="#87381A"
        d="M48.258 34.974c.866.828 1.481.988 2.187 2.193.624 1.062.572 1.89 1.014 2.877.684 1.517 1.962 2.505 3.495 3.112.399.06.498.208.373.277-.247.134-.919.325-1.209.416-1.282.32-2.698 1.863-2.79 3.19l-.285.09c-1.13-1.971-2.59-3.115-4.881-3.618l-.134-.351c-.858.234-2.11-.923-2.716-1.573q-.728-.559-.632-1.196c-.035-.308-.009-.464.208-.698 1.425-1.534 2.598-3.453 4.456-4.58.2-.121.442-.078.602-.169.087-.052-.047-.295.174-.234 0 .1.073.212.143.277z"
      />
      <path fill="#87381A" d="M56.348 43.156c-.07.03.178.507-1.278.49-.541-.01-.329-.109-.35-.49.537-.03 1.091.021 1.628 0" />
      <path
        fill="#3194A3"
        d="M55.932 70.677c2.062.277 3.396 1.586 4.236 3.41.494 1.187.498 2.53.425 3.8a230 230 0 0 0-.208 3.861c-.018.473-.07 1.43-.641.685-1.555-2.245-2.603-2.028-4.734-3.133-.91-.529-1.789-1.291-2.534-2.024-.91-.897-1.906-2.11-1.498-3.47.35-1.223 1.286-2.423 2.451-2.956.775-.368 1.615-.325 2.473-.182h.03z"
      />
      <path
        fill="#19757A"
        d="M51.338 58.04c.303.048.619.65.978 1.17.32.469.71.902 1.152 1.249.33.268.728.502 1.07.728.2.143.416.281.43.524-.11.516-.98.646-1.487 1.001-.788.42-1.256 1.257-1.884 1.842-.654.576-1.35-.061-1.858-.507-.602-.503-1.195-.984-1.936-1.378-.502-.321-1.29-.538-1.624-1.019-.169-.42 1.46-.606 1.78-.823.72-.308 1.482-.854 2.084-1.508.537-.52.766-1.274 1.282-1.283h.017z"
      />
      <path
        fill="#5EA7A0"
        d="M57.046 62.001c-.715-.156-1.672-.286-2.373 0-.109.022-1.85.889-1.954.971q-.33.066 0-.277c.754-.468 1.477-.815 2.374-.971.65-.113 1.481-.312 1.953.277"
      />
      <path
        fill="#67B7B4"
        d="M51.745 68.514q.235.6.697.971c.663.42 1.516 1.131 2.309 1.24.628.086 1.368-.048 2.014.008-.226.1-.42.407-.836.277-.745-.073-1.408-.138-2.123.148-.043.017-.692.411-.758.459-.1.07-1.247 1.218-1.299 1.296-.264.416-.316 1.17-.84 1.421-.48-.758-1.382-1.924-2.066-2.487-.381-.317-1.3-.724-1.56-.98l-.025-.433a33 33 0 0 1 1.936-.68c.632-.69 1.195-1.46 1.59-2.31-.53-1.179-.113-2.674.96-3.228-.519 1.594-1.13 2.795 0 4.294z"
      />
      <path fill="#B0CDCD" d="M51.744 68.514c.242.325.715.494.697.971-.043-.026-.22.043-.307-.013-.373-.23-.468-.524-.39-.958" />
      <path
        fill="#F6C8A2"
        d="M42.68 40.382c.831 1.179 2.053 2.24 3.348 2.774-.017.294.342.333-.07.411-.913.178-2.334.993-2.858 1.773s-.546 2.73-.386 3.674c.239 1.396 1.798 3.02 3.249 3.15.654.061 1.143-.112 1.74-.134.148-.138.334-.19.421 0-.026.013-.065.204-.23.252-.619.177-2.096.177-2.728-.013-1.17-.356-2.49-1.976-2.698-3.138-.156-.862.125-2.418-.135-3.055-.411-1.005-1.888-1.802-2.676-2.496-.87.135-1.962.022-2.785.247-.529.148-1.256.672-1.72.993.797-1.803 3.262-1.062 4.561-1.708.312-.156 1.667-1.343 1.958-1.655s.467-.953.879-1.209c.035.056.108.091.139.139z"
      />
      <path
        fill="#1E7D90"
        d="M52.572 63.466c-.226.03-.646.342-1.135.342-.888-.03-1.525-.801-2.24-1.243-.467-.325-1.017-.611-1.424-.94-.29-.222-.559-.46-.862-.655-.628-.42-1.438-.568-2.118-.919-2.482-1.183-2.205-2.712-2.954-4.918-.117-.277-.164-.316-.125-.091.814 3.029.437 6.348.394 9.473-.195 2.96.476 4.784 3.335 5.997.58.264 1.178.507 1.78.59 1.767.303 3.43-1.357 3.92-2.887.294-.875.142-1.594.489-2.6.195-.602.589-1.248.866-1.79.117-.233.152-.355.082-.363h-.008z"
      />
      <path
        fill="#1E2C37"
        d="M41.649 54.57c-.48-1.027-1.412-1.872-2.504-2.154-1.476-.364-3.278-.126-4.279 1.131-.584.706-.853 1.625-.97 2.535-.173 1.183.117 2.336.875 3.259.68.918 1.793 1.594 2.763 2.192.598.382 1.187.81 1.802 1.231.45.308.936.607 1.317 1.005.428.434.827.95 1.269 1.348.286.247.502.26.563-.043.1-.382.056-1.391.07-2.232-.005-.78.016-1.538.077-2.305.139-1.963-.182-4.121-.974-5.937l-.013-.03z"
      />
      <path
        fill="#E65227"
        d="M49.375 51.055c-.472.546-.524.616-1.256.971-.138 0-.281-.004-.42 0 .087-.342.46-.204.654-.325.238-.152.529-.758 1.022-.646"
      />
      <path
        fill="#FCFCFC"
        d="M42.588 66.833c-.082-1.512-.65-2.66-1.736-3.735-1.161-1.24-2.885-1.911-4.444-1.153-3.01 1.309-3.357 6.422-.494 8.212.723.502 1.563.728 2.4.953a5.6 5.6 0 0 1 2.486 1.443c.818.836 1.546 1.946 1.827.273.295-1.933.165-4.021-.039-5.967v-.03z"
      />
      <path
        fill="#1F2B37"
        d="M34.056 46.458c-.355-1.794-2.512-2.99-4.219-2.77-.866.07-1.559.525-2.248 1.036-.502.373-1.043.785-1.424 1.24-.503.598-.854 1.282-1.1 2.019-.338 1.005-.516 2.145-1.274 2.92-.45.486-1.13.937-1.698 1.236-.182.104-.286.177-.264.23.095.108.598.095.81.099.775 0 1.507 0 2.235-.009 2.304-.121 5.765.503 7.636-.918 1.46-1.153 1.832-3.315 1.554-5.053v-.03z"
      />
      <path
        fill="#AC481C"
        d="M37.378 35.252c-.177-.408.836-.455.698 0-.221-.005-.485-.022-.698 0M39.188 35.529c-.303.009-.719.082-.836-.277.2.013.403-.04.603.026.186.06.19.22.233.251"
      />
      <path
        fill="#246B76"
        d="M42.592 66.863c.156.182.13.074.243.312.35.741.87 1.508 1.516 2.033.026.42.013 1.096-.273 1.425-.234.278-.62.148-.845.308-.29.204-.671 2.245-.558 2.7-.022-.009-.07.294-.277-.139.064-2.136.272-4.511.194-6.643z"
      />
      <path
        fill="#FEFEFE"
        d="M37.24 25.311c2.104-.42 4.582.789 5.188 2.912.186.628.2 1.309.208 1.972.09 1.243-.2 2.231-1.191 2.99-.953.84-2.205 1.057-3.435 1.04-1.035.026-2.135-.29-2.858-1.049-.645-.745-1.36-1.482-1.564-2.474-.234-1.374.316-2.904 1.161-3.978.602-.733 1.525-1.214 2.46-1.404h.03z"
      />
      <path
        fill="#EC8A32"
        d="M64.07 43.576c2.162-.429 4.695.797 5.328 2.973.195.645.212 1.352.22 2.036.091 1.274-.199 2.293-1.212 3.073-.983.866-2.27 1.1-3.539 1.079-1.057.026-2.178-.29-2.923-1.062-.784-.893-1.65-1.79-1.68-3.05-.044-1.283.48-2.648 1.277-3.632.624-.737 1.555-1.218 2.499-1.413h.03z"
      />
      <path
        fill="#FEFEFE"
        d="M19.859 34.441c2.104-.42 4.582.789 5.188 2.912.186.629.2 1.309.208 1.972.091 1.244-.2 2.232-1.19 2.99-.954.84-2.206 1.057-3.436 1.04-1.035.026-2.135-.29-2.858-1.049-.645-.745-1.36-1.482-1.564-2.474-.234-1.374.317-2.903 1.161-3.978.602-.732 1.525-1.213 2.46-1.404h.03z"
      />
      <path
        fill="#E65227"
        d="M45.967 43.247c1.1-.04 2.027.429 2.953.953 1.161.767 2.196 1.963 2.578 3.324.498 2.08-1.438 3.683-2.933 4.792-2.187 1.578-5.362-.095-6.011-2.565-.32-1.2-.368-3.085.09-4.216.42-1.11 1.941-2.15 3.288-2.284h.035z"
      />
      <path
        fill="#E85C25"
        d="M37.434 34.966c2.265-.447 3.907 2.392 4.864 4.086.19.39.325.802.307 1.235-.06 1.144-1.169 2.47-2.182 3.159-.633.394-1.443.381-2.175.429-.667.035-1.312.165-1.888.45-.572.265-1.091.564-1.529 1.045-.403.39-.892 1.391-1.468.698-.46-.546-.953-1.127-1.577-1.487a4.6 4.6 0 0 0-1.477-.62c-.528-.112-1.381-.225-1-.961.139-.304.368-.585.593-.841.992-1.11 2.08-2.08 3.123-3.133 1.01-1.014 1.893-2.132 2.928-3.081.424-.39.905-.823 1.46-.97l.025-.01z"
      />
      <path
        fill="#1E6660"
        d="M68.805 75.11c.567-1.036 1.351 1.248 1.78 1.577.702.823 1.637 1.434 2.516 2.05.754.572 1.534 1.109 2.27 1.69.55.377.927.88 1.368 1.356.43.472.958 1.036 1.347 1.716.29.503.511 1.066.819 1.556.767 1.352 2.036 2.188 3.495 2.63.368.13.732.269 1.066.472.701.416 1.269 1.144 1.884 1.768.532.52 1.126 1.253.558 1.985-2.217 2.28-4.608 3.891-7.34 5.603-1.906 1.118-1.517-.117-1.149-1.538.286-1.244 0-2.457-.307-3.653a6.6 6.6 0 0 0-1.546-2.726c-.594-.568-1.248-.602-1.993-1.04-1.113-.741-2.165-1.599-3.183-2.483-.637-.58-1.481-1.075-1.676-1.967-.121-.507-.07-1.162-.1-1.734-.1-1.022-.034-1.889.087-2.916.108-1.426-.559-3.003.082-4.329l.009-.017z"
      />
      <path
        fill="#FEFEFE"
        d="M54.668 61.902c2.178-.438 4.746.814 5.366 3.02.186.646.203 1.348.208 2.032.017.741.026 1.378-.295 1.994-.675 1.204-2.057 2.015-3.413 2.11-1.412.156-3.032.048-4.045-1.057-.645-.767-1.369-1.491-1.585-2.496-.256-1.435.32-3.03 1.204-4.152.615-.745 1.576-1.243 2.53-1.443h.03z"
      />
      <path
        fill="#1F2C35"
        d="M17.022 58.036c-.268.655-.506 1.279-.987 1.829-.381.438-2.07 1.694-2.577 1.768-.542.082-1.178-.039-1.737.009-4.392-1.829-5.098-6.461-.836-8.87l.225-.36 2.984-.195c1.1-.434 2.79-1.985 2.928-3.19.238-.299.33-.212.347.156q-.045 2.517.208 5.062l-.243.377a15.4 15.4 0 0 1-.312 3.414"
      />
      <path
        fill="#1EA398"
        d="M75.548 52.75c2.356 1.096 3.408 4.294 2.191 6.595-.844 1.716-2.698 2.44-4.344 3.194-1.018.49-1.98 1.109-2.923 1.729-.494.281-1.122.957-1.672.78-.368-.226-.316-.906-.35-1.313-.057-1.131-.243-2.258-.018-3.376.104-.611.238-1.213.294-1.829.1-1.157.083-2.361.611-3.427.974-2.275 3.933-3.424 6.18-2.366z"
      />
      <path
        fill="#AAD2D1"
        d="M79.797 86.632c1.369 1.096 2.972 1.963 4.639 1.14 2.703-1.149 3.439-3.731 2.295-6.318-.567-1.088-1.966-2.665-3.326-2.41-.784.148-1.494.477-2.226.776-.606.269-1.143.685-1.763.932-.836.355-1.819.442-2.724.325-.385-.035-.936-.191-1.23-.048-.256.225.255.763.416.992 1.286 1.534 2.412 3.237 3.893 4.59z"
      />
      <path
        fill="#E86D24"
        d="M81.287 43.225c.788.043 1.602.048 2.43.195 1.35.256 2.585.988 3.027 2.28.273.81.095 1.663-.065 2.491-.177 1.313-.663 2.431-1.72 3.285-.783.68-1.948 1.014-2.996 1.005-2.196 0-3.747-1.82-3.937-3.965-.057-1.148-.265-2.414.298-3.371.693-.954 1.742-1.95 2.933-1.92h.03"
      />
      <path
        fill="#8FC7D1"
        d="M124.182 73.814h14.353v-2.5h-14.353v-9.88h16.098v-2.54h-19.035v27.773h19.629v-2.54h-16.692zM157.015 58.894l-12.69 27.773h3.054l3.317-7.419h15.493l3.317 7.419h3.093l-12.69-27.773zm-5.253 17.975 6.678-14.937 6.679 14.937h-13.362zM194.645 76.726q2.538-1.13 3.906-3.254c.914-1.413 1.369-3.103 1.369-5.057s-.455-3.723-1.369-5.14c-.909-1.412-2.213-2.5-3.906-3.254q-2.54-1.131-6.068-1.131h-10.39v27.772h2.936v-8.81h7.454c.784 0 1.524-.047 2.235-.13l6.332 8.936h3.214l-6.787-9.529a12 12 0 0 0 1.078-.407zm-6.146-1.365h-7.376V61.434h7.376q4.165-.001 6.323 1.824 2.163 1.828 2.162 5.157c0 2.218-.719 3.9-2.162 5.117-1.442 1.218-3.547 1.825-6.323 1.825zM203.095 61.434h9.753v25.233h2.933V61.434h9.758v-2.54h-22.444zM251.512 58.894V71.27h-17.368V58.894h-2.936v27.773h2.936V73.853h17.368v12.814h2.894V58.894z"
      />
      <path
        fill="#D7EBEF"
        d="m147.18 17.81-10.222 17.242-10.399-17.242h-5.314v27.772h6.029v-16.31l8.13 13.372h2.893l8.173-13.732.039 16.67h6.024l-.078-27.772zM184.398 21.42c-1.36-1.296-2.963-2.301-4.799-3.016s-3.855-1.07-6.046-1.07-4.18.355-6.029 1.07q-2.777 1.072-4.838 3.016a13.6 13.6 0 0 0-3.192 4.563q-1.131 2.618-1.131 5.711c0 2.063.377 3.97 1.131 5.711a13.8 13.8 0 0 0 3.17 4.563c1.36 1.296 2.976 2.301 4.838 3.016s3.894 1.07 6.085 1.07c2.192 0 4.171-.355 6.007-1.07q2.754-1.071 4.799-2.994a13.7 13.7 0 0 0 3.192-4.563q1.151-2.64 1.152-5.733c0-2.063-.385-4-1.152-5.733a14.1 14.1 0 0 0-3.192-4.541zm-2.816 13.888a8.4 8.4 0 0 1-1.845 2.817 8.2 8.2 0 0 1-2.754 1.824q-1.566.637-3.43.637c-1.244 0-2.391-.212-3.452-.637a8 8 0 0 1-2.755-1.824 8.4 8.4 0 0 1-1.823-2.839q-.656-1.643-.654-3.592c0-1.3.216-2.526.654-3.61a8.4 8.4 0 0 1 1.845-2.816 8.2 8.2 0 0 1 2.754-1.825q1.566-.636 3.431-.637c1.243 0 2.39.213 3.451.637a8 8 0 0 1 2.755 1.825 8.5 8.5 0 0 1 1.823 2.816q.655 1.627.654 3.61.001 1.983-.654 3.61zM216.179 21.974V17.81h-23.358v5.235h14.912l-15.228 18.373v4.164h24.267v-5.239h-15.787zM230.614 17.81 218.24 45.582h6.584l2.473-5.95h12.867l2.465 5.95h6.743L236.959 17.81zm-1.29 16.939 4.417-10.63 4.401 10.63zM259.681 17.81h-6.423v27.772h6.423zM284.701 39.771q-1.748.814-3.807.815-1.943 0-3.569-.637a8.1 8.1 0 0 1-2.815-1.824 8.4 8.4 0 0 1-1.845-2.817q-.655-1.626-.654-3.61 0-1.983.654-3.61a8.4 8.4 0 0 1 1.845-2.816 8.1 8.1 0 0 1 2.815-1.824q1.626-.637 3.569-.637 2.06.001 3.807.814c1.161.542 2.222 1.357 3.171 2.44l4.123-3.809a12.7 12.7 0 0 0-4.898-3.67q-2.917-1.248-6.523-1.248c-2.17 0-4.162.35-5.99 1.053q-2.737 1.052-4.777 2.994a13.7 13.7 0 0 0-3.171 4.563q-1.13 2.62-1.13 5.755t1.13 5.754a13.9 13.9 0 0 0 3.153 4.563c1.347 1.296 2.941 2.297 4.778 2.995q2.756 1.053 5.968 1.053 3.65-.001 6.562-1.27a12.9 12.9 0 0 0 4.898-3.692l-4.123-3.809q-1.43 1.665-3.171 2.479z"
      />
    </g>
    <defs>
      <clipPath id="logo-content-vertical_svg__a">
        <path fill="#fff" d="M0 0h292v104H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgLogoContentVertical
