import { IBaseResponse } from '@models/common.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import { v4 as uuidv4 } from 'uuid'
import { ICreateMapFeature, IUpdateMapFeature } from './MapFeatureService.model'
import { IMapFeature } from '@models/mapFeature.model'

export class MapFeatureService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchMapFeature = async (id: string): Promise<IBaseResponse<IMapFeature>> => {
    try {
      const response = await this.client.from('map_features').select('*').eq('id', id).single()
      if (response.error) {
        return { data: null, error: response?.error }
      }
      return {
        data: this.toCamelCase(response.data),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchMapFeaturesByIds = async (ids: string[]): Promise<IBaseResponse<IMapFeature[]>> => {
    try {
      const response = await this.client.from('map_features').select('*').in('id', ids)
      if (response.error) {
        return { data: [], error: response?.error }
      }
      const mapFeatures = response.data.map(feature => this.toCamelCase(feature))
      return {
        data: mapFeatures,
        error: null,
      }
    } catch (error: any) {
      return { data: [], error }
    }
  }

  fetchMapFeatures = async ({ projectId, planId }: { projectId: string; planId: string }): Promise<IBaseResponse<IMapFeature[]>> => {
    try {
      const { data: mapFeatures, error: mapFeaturesError } = await this.client
        .from('map_features')
        .select('id, plot_key, geometry, properties, project_id')
        .eq('plan_id', planId)
        .eq('project_id', projectId)
        .eq('is_mission_feature', false)
        .eq('is_project_boundary', false)
        .is('deleted_at', null)

      if (mapFeaturesError) {
        return { data: null, error: mapFeaturesError }
      }

      return {
        data: this.toCamelCase(mapFeatures),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  create = async (requests: ICreateMapFeature[]): Promise<IBaseResponse<IMapFeature[]>> => {
    try {
      const features = requests.map(request => ({
        id: request?.id ?? uuidv4(),
        geometry: request.geometry,
        project_id: request.projectId,
        is_mission_feature: false,
        is_project_boundary: false,
        layer_key: 'metric',
        plan_id: request.planId,
      }))

      const query = this.client.from('map_features').insert(features).select('id, plot_key, geometry, properties, project_id')

      const { data, error } = await query

      if (error) {
        return { data: null, error }
      }

      return {
        data: data.map(this.toCamelCase),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  update = async (requests: IUpdateMapFeature[]): Promise<IBaseResponse<IMapFeature[]>> => {
    try {
      const updates = requests.map(({ id, ...updates }) => this.client.from('map_features').update(updates).eq('id', id).select('*'))

      const results = await Promise.all(updates)
      const errors = results.filter(({ error }) => error)
      const data = results.filter(({ data }) => data).map(({ data }) => data)

      if (errors.length > 0) {
        return { data: null, error: errors.map(({ error }) => error) }
      }

      return { data: data.flat().map(this.toCamelCase), error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  delete = async (ids: string[]): Promise<IBaseResponse<null>> => {
    try {
      const payload = {
        deleted_at: new Date().toISOString(),
      }

      const { error } = await this.client.from('map_features').update(payload).in('id', ids)

      if (error) {
        return { data: null, error }
      }

      return { data: null, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

const mapFeatureService = new MapFeatureService()

export default mapFeatureService
