import { SxProps } from '@mui/material/styles'

export const container: SxProps = {
  position: 'relative',
}

export const xContainer: SxProps = {
  position: 'absolute',
  bottom: 16,
  right: 8,
}
