const moonstoneTeal = {
  100: '#B0D8DF',
  200: '#95CBD4',
  300: '#7BBDC9',
  400: '#61B0BE',
  500: '#49A1B1', // Main color
  600: '#3E8B98',
  700: '#34737F',
  800: '#245159',
  900: '#1C3E44',
}

export default moonstoneTeal
