import { useContext, useEffect, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { AuthChangeEvent, Session } from '@supabase/supabase-js'

import { useAppNavigate } from '@hooks/index'
import useMozaicSnackbar from '@hooks/useMozaicSnackbar/useMozaicSnackbar.hook'
import { UpdateProfilePayload } from '@models/profile.models'
import authService from '@services/AuthService/AuthService'
import { TVerifyOtpResponseSuccess } from '@services/AuthService/AuthService.models'
import profileService from '@services/ProfileService/ProfileService'
import supabaseClient from '@utils/supabase/MozaicSupebaseClient'

import AuthenticationContext from './AuthenticationContext'
import { ACTIONS } from './AuthenticationContext.const'
import { IUserInfo } from './AuthenticationState'
import useAuthenticationSelector from './useAuthenticationSelector'
import { logAnalyticsEvent } from '@configs/firebase'

interface UseAuthenticationProps {
  onSendMailSuccess?: (data: any) => void
  onVerifyOtpSuccess?: () => void
  onSendMailFailed?: (error: Error) => void
  onVerifyOtpFailed?: (error: Error) => void
  onAuthStateChange?: (event: AuthChangeEvent, session: Session | null) => void | Promise<void>
}

export const useAuthentication = ({
  onSendMailSuccess,
  onVerifyOtpSuccess,
  onSendMailFailed,
  onVerifyOtpFailed,
  onAuthStateChange,
}: UseAuthenticationProps = {}) => {
  const context = useContext(AuthenticationContext)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  if (!context) {
    throw new Error('AuthenticationContext must be used within an AuthenticationProvider')
  }

  const { dispatch } = context
  const { navigateToLoginPage } = useAppNavigate()
  const { enqueueErrorSnackbar } = useMozaicSnackbar()
  const mail = useRef('')

  const user = useAuthenticationSelector(state => state.user)
  const profile = useAuthenticationSelector(state => state.profile)

  useEffect(() => {
    if (!!onAuthStateChange) {
      const {
        data: { subscription },
      } = supabaseClient.auth.onAuthStateChange(onAuthStateChange)

      return () => {
        subscription.unsubscribe()
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [])

  const updateUserInfo = (user?: IUserInfo) => {
    dispatch({
      type: ACTIONS.SET_USER,
      payload: { user },
    })
  }

  const handleLoginSuccess = (res: TVerifyOtpResponseSuccess) => {
    updateUserInfo({ email: res.user?.email, id: res?.user?.id })
    onVerifyOtpSuccess?.()
  }

  const signInMutation = useMutation({
    mutationFn: authService.loginWithEmailOtp,
    onSuccess: (data, email) => {
      mail.current = email
      updateUserInfo(data)
      onSendMailSuccess?.(data)
    },
    onError: onSendMailFailed,
  })

  const verifyOtpMutation = useMutation({
    mutationFn: (otp: string) => authService.verifyOtp({ email: mail.current, otp }),
    onSuccess: data => {
      logAnalyticsEvent('login')
      handleLoginSuccess?.(data)
    },
    onError: onVerifyOtpFailed,
  })

  const onLogoutSuccess = () => {
    dispatch({
      type: ACTIONS.CLEAR,
    })
    navigateToLoginPage()
  }

  const logoutMutation = useMutation({
    mutationFn: authService.logout,
    onSuccess: onLogoutSuccess,
  })

  const logout = async ({ type = 'manual' }: { type?: 'auto' | 'manual' } = {}) => {
    await logoutMutation.mutateAsync()
    updateUserInfo(undefined)
    navigateToLoginPage()
    if (type === 'auto') {
      enqueueErrorSnackbar("You've been logged out!")
    }
  }

  const resendOtpMutation = useMutation({
    mutationFn: () => authService.loginWithEmailOtp(mail.current),
  })

  const userProfileMutation = useMutation({
    mutationKey: ['profile'],
    mutationFn: async () => {
      const { data: userData } = await authService.auth.getUser()
      if (userData) {
        return profileService.getProfile({ userId: userData?.user?.id || '' }).then(({ data }) => {
          dispatch({
            type: ACTIONS.SET_PROFILE,
            payload: { profile: data },
          })
          return data
        })
      }
      return Promise.resolve(null)
    },
  })

  const saveProfileMutation = useMutation({
    mutationFn: (profile: UpdateProfilePayload) => profileService.saveProfile(profile),
  })

  const checkIsProfileComplete = () => {
    return new Promise(resolve => {
      let isCompleteProfile = !!profile?.firstName && !!profile?.lastName && !!profile?.termsAgreedAt
      if (!isCompleteProfile) {
        return userProfileMutation.mutateAsync().then(res => {
          return resolve(!!res?.firstName && !!res?.lastName && !!res?.termsAgreedAt)
        })
      }
      return resolve(isCompleteProfile)
    })
  }

  return {
    user,
    /**
     * Verify Otp
     */
    verifyOtpMutation,
    verifyOtpLoading: verifyOtpMutation.isPending,
    /**
     * Send mail, sign in
     */
    signInMutation,
    signInLoading: signInMutation.isPending,
    resendOtpMutation,
    /**
     * Logout
     */
    logoutMutation,
    logout,
    logoutLoading: logoutMutation.isPending,
    onLogoutSuccess,
    /**
     * Init logged in user
     */
    updateUserInfo,
    profile,
    saveProfileMutation: saveProfileMutation.mutateAsync,
    saveProfileLoading: saveProfileMutation.isPending,
    fetchProfile: userProfileMutation.mutate,
    fetchProfileAsync: userProfileMutation.mutateAsync,
    isLoadingFetchProfile: userProfileMutation.isPending,
    checkIsProfileComplete,
  }
}

export default useAuthentication
