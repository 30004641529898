import { initializeApp } from 'firebase/app'
import { logEvent, Analytics, initializeAnalytics } from 'firebase/analytics'
import ENV from '@configs/env'

let app
let analytics: Analytics | null = null

// Initialise analytics when not running locally
if (window.location.hostname !== 'localhost') {
  const firebaseConfig = {
    apiKey: ENV.FIREBASE_API_KEY,
    authDomain: ENV.FIREBASE_AUTH_DOMAIN,
    databaseURL: ENV.FIREBASE_DATABASE_URL,
    projectId: ENV.FIREBASE_PROJECT_ID,
    storageBucket: ENV.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: ENV.FIREBASE_MESSAGING_SENDER_ID,
    appId: ENV.FIREBASE_APP_ID,
    measurementId: ENV.FIREBASE_MEASUREMENT_ID,
  }

  app = initializeApp(firebaseConfig)
  analytics = typeof window !== 'undefined' ? initializeAnalytics(app, { config: { send_page_view: false } }) : null
}

export const logAnalyticsEvent = (eventName: string, eventParams?: Record<string, any>) => {
  if (analytics) {
    logEvent(analytics, eventName, eventParams)
  }
}

export { app, analytics }
