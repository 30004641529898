import type { SVGProps } from 'react'
const SvgIconUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M3.25 6.25a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0m5 0a3.25 3.25 0 1 0-6.5 0 3.25 3.25 0 0 0 6.5 0M15 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6M4 12.5h2A2.5 2.5 0 0 1 8.5 15v.5h-7V15A2.5 2.5 0 0 1 4 12.5M4 11a4 4 0 0 0-4 4v.5A1.5 1.5 0 0 0 1.5 17h7a1.5 1.5 0 0 0 1.5-1.5V15a4 4 0 0 0-4-4zm10 6h2a4 4 0 0 0 4-4v-.5a1.5 1.5 0 0 0-1.5-1.5h-7c-.681 0-1.26.456-1.44 1.081.59.822.94 1.828.94 2.919v.5q0 .07-.003.14A3.98 3.98 0 0 0 14 17"
    />
  </svg>
)
export default SvgIconUsers
