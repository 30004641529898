import type { SVGProps } from 'react'
const SvgIconKey = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 12 12" {...props}>
    <path
      fill="currentColor"
      d="M7.875 8.25a4.125 4.125 0 1 0-3.93-2.866l-3.78 3.78A.56.56 0 0 0 0 9.563v1.874c0 .312.25.563.563.563h1.875c.311 0 .562-.25.562-.562V10.5h.938c.311 0 .562-.25.562-.562V9h.938c.15 0 .292-.059.398-.164l.78-.78c.397.126.82.194 1.259.194m.938-6a.938.938 0 1 1 0 1.875.938.938 0 0 1 0-1.875"
    />
  </svg>
)
export default SvgIconKey
