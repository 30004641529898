import { IMapFeature } from '@models/mapFeature.model'
import mapFeatureService from '@services/MapFeatureService/MapFeatureService'
import { ICreateMapFeature } from '@services/MapFeatureService/MapFeatureService.model'
import { useMutation } from '@tanstack/react-query'

export const useCreateMapFeaturesMutation = ({ onSuccess }: { onSuccess?: (data: IMapFeature[]) => void } = {}) => {
  const { mutateAsync: createMapFeatures, isPending: createMapFeaturesLoading } = useMutation({
    mutationKey: ['create_map_feature'],
    mutationFn: async (payload: ICreateMapFeature[]) => {
      return mapFeatureService.create(payload).then(res => res.data)
    },
    onSuccess: data => {
      if (data) {
        onSuccess?.(data)
      }
    },
  })

  return {
    createMapFeatures,
    isLoading: createMapFeaturesLoading,
  }
}
