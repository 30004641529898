import { Toolbar as MuiToolbar, ToolbarProps as MuiToolBarProps } from '@mui/material'

export type ToolbarProps = {
  children?: React.ReactNode
  width?: number
} & MuiToolBarProps

const Toolbar = ({ children, ...rest }: ToolbarProps) => {
  return <MuiToolbar {...rest}>{children}</MuiToolbar>
}

export default Toolbar
