import ERoutePaths from '@configs/routes/routes.path'

export const SECTION_TITLE = {
  MAIN_MENU: 'Main menu',
  SYSTEM_SUPPORT: 'System & Support',
}

export interface MenuSideBarItem {
  title: string
  sectionTitle: string
  link: ERoutePaths | string
  onClick?: () => void
  notificationAmount?: number
  icon: any
  id: string
}
