import { Box, Card, Image } from '@components/atom'
import { styled } from '@mui/material'

export const ImageContainer = styled(Box)`
  width: 100%;
  height: 100%;
  max-width: 256px;
`

export const ProjectImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`

export const ProjectCardContainer = styled(Card)(() => ({
  width: '100%',
  backgroundColor: 'white',
  display: 'flex',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  overflow: 'hidden',
}))
