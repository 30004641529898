import { Feature } from 'ol'
import { Geometry } from 'ol/geom'
import VectorSource from 'ol/source/Vector'

import { ICheckpointLocation } from '@models/checkpoint.model'
import { MetricDto } from '@models/metric.model'
import { IObservation } from '@models/observation.model'
import { IProject } from '@models/projects.model'
import { ISurvey } from '@models/survey.model'
import { TileLayerDto } from '@models/tileLayer.model'
import { IPlan } from '@models/plan.model'
import { IMapFeature } from '@models/mapFeature.model'

export type MapState = {
  initialized: boolean
}

export type TProjectDetailState = {
  projectDetail?: IProject
  surveys: ISurvey[]
  selectedTileLayer: string
  projectSurveyCheckpoints: Record<ISurvey['id'], ICheckpointLocation[]>
  metrics: MetricDto[]
  mapFeatures: IMapFeature[]
  surveyListSource?: VectorSource<Feature<Geometry>>
  projectBoundaryVectorSource?: VectorSource<Feature<Geometry>>
  checkpointsSource?: Record<ISurvey['id'], VectorSource<Feature<Geometry>>>
  observations: Record<ISurvey['id'], IObservation[]>
  mapState: MapState
  tileLayers: TileLayerDto[] | null
  events: { shouldRefetchObservations: boolean; shouldRefetchProjectDetail: boolean } & Record<string, boolean>
  plans: IPlan[]
  selectedPlanId?: string
}

export const defaultState: TProjectDetailState = {
  projectDetail: undefined,
  surveys: [],
  selectedTileLayer: '',
  projectSurveyCheckpoints: {},
  metrics: [],
  mapFeatures: [],
  surveyListSource: undefined,
  checkpointsSource: undefined,
  projectBoundaryVectorSource: undefined,
  observations: {},
  mapState: {
    initialized: false,
  },
  events: {
    shouldRefetchObservations: false,
    shouldRefetchSurveys: false,
    shouldRefetchProjectDetail: false,
  },
  tileLayers: null,
  plans: [],
  selectedPlanId: undefined,
}
