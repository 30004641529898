import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiButton: {
  defaultProps: ComponentsProps['MuiButton']
  styleOverrides: ComponentsOverrides<Theme>['MuiButton']
} = {
  defaultProps: {
    variant: 'contained',
    color: 'primary',
    size: 'medium',
  },
  styleOverrides: {
    sizeSmall: () => ({
      lineHeight: '14px',
      fontSize: '14px',
      padding: '8px 16px',
      '.loading': {
        height: '14px',
      },
    }),
    sizeMedium: () => ({
      fontSize: '16px',
      lineHeight: '24px',
      padding: '12px 24px',
      maxHeight: '48px',
      '.loading': {
        height: '24px',
      },
    }),
    sizeLarge: () => ({
      fontSize: '16px',
      lineHeight: '24px',
      padding: '16px 24px',
      '.loading': {
        height: '24px',
      },
    }),
    root: ({ theme }) => ({
      textTransform: 'none',
      boxShadow: 'none',
      fontWeight: 700,
      borderRadius: theme.shape.borderRadius * 4, // 8 * 4 = 24px
    }),
    outlined: () => ({
      backgroundColor: 'rgba(250, 250, 250, 0)',
    }),
    contained: () => ({}),
    textPrimary: ({ theme }) => ({
      color: theme.palette.info.main,
    }),
    text: ({ theme }) => ({
      padding: '0 !important',
      fontWeight: 400,
      justifyContent: 'start',
      width: 'fit-content',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'underline',
        background: 'transparent',
      },
    }),
  },
}

export default MuiButton
